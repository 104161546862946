import React, {useEffect, useMemo, useState} from 'react'
import {useIsMounted} from 'react-tidy'
import {callable, orderBy, timestamp, useCallable, useQuery, where} from '../../services/firebase'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import Button from '../Button'
import Dialog from '../Dialog'
import Grid from '../Grid'
import Input from '../Input'
import LoadingIndicator from '../LoadingIndicator'
import {useLoading} from '../LoadingWrapper'
import ReviewEditor from '../ReviewEditor'
import ReviewItem from '../ReviewItem'
import Text from '../Text'
import './styles.scss'

export default function ReviewList({
  className, columns, productId, reviewTotal, reviewCount, theoristHandle, editorProps,
}) {
  const theoristHandleParams = useMemo(
    () => ({handle: theoristHandle}), [theoristHandle])
  const {data: theoristId, loading: theoristLoading} =
    useCallable('fetchTheoristIdByHandleV2', theoristHandleParams, !theoristHandle)
  const {
    data: reviewData,
    loading: reviewLoading,
  } = useQuery('reviews', [
    orderBy('createdAt', 'desc'),
    where(!productId ? 'theorist' : 'product', '==', productId ?? theoristId),
  ], [productId, theoristId], !reviewCount || (!productId && !theoristId))
  const {addLoad, removeLoad} = useLoading()
  const isMounted = useIsMounted()
  const [validReview, setValidReview] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [submittingReview, setSubmittingReview] = useState(false)
  const [reviewSubmitted, setReviewSubmitted] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)
  const [manualReview, setManualReview] = useState({product: productId})
  useEffect(() => {
    if (theoristId) setManualReview(prev => ({...prev, theorist: theoristId}))
  }, [theoristId])
  useEffect(() => {
    setValidReview(!!manualReview.name && !!manualReview.email && !!manualReview.rating && !!manualReview.body)
  }, [manualReview])
  useEffect(() => {
    if (submittingReview) addLoad('reviewManual')
    else removeLoad('reviewManual')
    return () => removeLoad('reviewManual')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittingReview])
  useEffect(() => {
    if (linkCopied) {
      const timeout = setTimeout(() => setLinkCopied(false), 3000)
      return () => clearTimeout(timeout)
    }
  }, [linkCopied])
  const overallRating = ((reviewTotal ?? 0) / (reviewCount ?? 1)).toFixed(1)
  const loading = theoristLoading || reviewLoading
  return <div className={classList(['ReviewList', className])}>
    {!reviewCount ? <Text styleVariant="heading2">No reviews (yet)</Text>
      : <Grid
          title={<div className="ReviewList__title">
            <AppIcon icon="star" />
            <span>{overallRating}</span>
            <span>·</span>
            <span>{reviewCount} review{reviewCount > 1 ? 's' : ''}</span>
          </div>}
          columns={columns}>
        {loading ? [
          <LoadingIndicator key="ReviewList loading" />
        ] : (reviewData || []).map(doc => ({id: doc.id, ...doc.data}))
          .map(review => <ReviewItem
          key={`ReviewList ${review.id}`}
          name={review.name}
          location={review.location}
          rating={review.rating}
          date={timestamp(review.createdAt.seconds, review.createdAt.nanoseconds)}
          body={review.body} />)}
      </Grid>}
    {(reviewCount ?? 0) < 3 && <>
      <Text className="ReviewList__message">
        Reviews are an important part of the String Theory community. If you have experience with
        this {computeReviewType(productId, theoristHandle)}, please take a moment to provide our team with some helpful
        feedback—we promise it'll only take a minute.
      </Text>
      <div className="ReviewList__buttons">
        <Button
          styleVariant="secondary"
          onClick={() => setDialogOpen(true)}>
          Write a review
        </Button>
        <Button
          styleVariant="tertiary"
          onClick={() => {
            navigator.clipboard.writeText(`${window.location.href}#reviews`)
            setLinkCopied(true)
          }}>
          <AppIcon
            className="ReviewList__shareIcon"
            icon="share" />
          {linkCopied ? 'Link copied!' : 'Share link'}
        </Button>
      </div>
    </>}
    <Dialog
      title="Write a review"
      open={dialogOpen}
      onDismiss={() => setDialogOpen(false)}>
      <Input
        className="ReviewList__input"
        label="Name"
        value={manualReview.name}
        onChange={(name) => setManualReview(prev => ({...prev, name}))}
        disabled={submittingReview || reviewSubmitted} />
      <Input
        className="ReviewList__input"
        label="Email"
        value={manualReview.email}
        onChange={(email) => setManualReview(prev => ({...prev, email}))}
        disabled={submittingReview || reviewSubmitted} />
      <ReviewEditor
        className="ReviewList__editor"
        onUpdate={(review) => setManualReview(prev => ({...prev, ...review}))}
        disabled={submittingReview || reviewSubmitted}
        {...editorProps} />
      {reviewSubmitted && <Text className="ReviewList__confirmation">Thank you for reviewing!</Text>}
      <div className="ReviewList__submitButton">
        <Button
          slim
          disabled={!validReview || submittingReview || reviewSubmitted}
          onClick={async () => {
            setSubmittingReview(true)
            try {
              let location = null
              try {
                const key = '15467f675f704428b80a1ef493107ecb'
                const req = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${key}`)
                const res = await req.json()
                location = `${res.city}, ${res.state_code.split('-')[1]}`
              } catch (error) {
                console.log(error)
              }
              await callable('reviewManualV2')({
                ...manualReview,
                location,
              })
            } catch (error) {
              console.log(error)
            } finally {
              if (isMounted()) {
                setSubmittingReview(false)
                setReviewSubmitted(true)
              }
            }
          }}>
          Submit
        </Button>
      </div>
    </Dialog>
  </div>
}

const computeReviewType = (productId, theoristHandle) => {
  if (productId && theoristHandle) return 'product or theorist'
  if (productId) return 'product'
  if (theoristHandle) return 'theorist'
  return 'product or theorist'
}
