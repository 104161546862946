import React, {useEffect} from 'react'
import {useMutation} from '@apollo/client'
import {Link, useLocation} from 'react-router-dom'
import AppIcon from '../../components/AppIcon'
import AppImage from '../../components/AppImage'
import Button from '../../components/Button'
import Counter from '../../components/Counter'
import Drawer from '../../components/Drawer'
import FormattedPrice from '../../components/FormattedPrice'
import IconButton from '../../components/IconButton'
import LinkButton from '../../components/LinkButton'
import {LoadingConsumer} from '../../components/LoadingWrapper'
import Text from '../../components/Text'
import {CHECKOUT_LINE_ITEMS_UPDATE, groupLineItems, useCheckout} from '../../services/shopify'
import './styles.scss'

export default function Cart() {
  const {pathname} = useLocation()
  const {checkout, open, toggleCheckout, updateCheckout} = useCheckout()
  const [checkoutLineItemsUpdate] = useMutation(CHECKOUT_LINE_ITEMS_UPDATE)
  const isEmpty = !checkout || checkout.lineItems.length === 0
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => toggleCheckout(false), [pathname])
  const lineItemGroups = groupLineItems(isEmpty ? [] : checkout.lineItems)
  return <Drawer
    className="Cart"
    open={open}
    position="right"
    onOverlayClick={() => toggleCheckout(false)}>
    <div className="Cart__topBar">
      <Text styleVariant="heading2">Your cart</Text>
      <IconButton icon="clear" onClick={() => toggleCheckout(false)} />
    </div>
    <div className="Cart__body">
      {isEmpty ? <Text className="Cart__emptyMessage">Your cart is empty.</Text> : <>{lineItemGroups
        .map(lineItems => <LineItemGroup
          key={lineItems.map(item => item.id).reduce((acc, curr) => acc + curr, '')}
          checkoutId={checkout.id}
          checkoutLineItemsUpdate={checkoutLineItemsUpdate}
          updateCheckout={updateCheckout}
          lineItems={lineItems}
          quantity={lineItems[0].quantity} />
        )}
      </>}
      <Button
        styleVariant="tertiary"
        onClick={() => toggleCheckout(false)}>Add items</Button>
    </div>
    <div className="Cart__footer">
      {isEmpty ? null : <Text
        className="Cart__total"
        styleVariant="heading2">
        <div>Total</div>
        <FormattedPrice>{checkout.subtotalPrice.amount}</FormattedPrice>
      </Text>}
      <LoadingConsumer>
        {({addLoad}) => <Button
          className="Cart__checkoutButton"
          disabled={isEmpty}
          onClick={() => {
            addLoad('checkout')
            window.location.href = checkout.webUrl
          }}>Checkout</Button>}
      </LoadingConsumer>
      <div className="Cart__deliveryMessage">
        <AppIcon className="Cart__deliveryIcon" icon="truck" />
        <Text styleVariant="heading3">Free delivery on all orders.</Text>
      </div>
    </div>
  </Drawer>
}

function LineItemGroup({checkoutId, checkoutLineItemsUpdate, updateCheckout, lineItems, quantity}) {
  const notes = lineItems
    .map(item => (item.customAttributes.find(attr => attr.key === 'Notes') || {}).value)
    .filter(it => !!it)[0]
  return <div className="Cart__lineItem">
    {lineItems.map(lineItem => {
      const customAttributes = lineItem.customAttributes
        .filter(attr => attr.key !== 'Design ID' && attr.key !== 'Notes')
        .sort((a, b) => a.key.localeCompare(b.key))
      return <div
        key={lineItem.id}
        className="Cart__lineItemTop">
        <AppImage
          src={(lineItem.variant.image || {}).url}
          width={64}
          height={64}
          radius={12}
          bordered/>
        <div className="Cart__lineItemBody">
          <Text styleVariant="heading3">
            <Link
              className="Cart__lineItemLink"
              to={`/products/${lineItem.variant.product.handle}?v=${lineItem.variant.id.split('/').pop()}`}>
              {lineItem.title}
            </Link>
          </Text>
          {lineItem.variant.title === 'Default Title' ? null :
            <Text styleVariant="body3">{lineItem.variant.title}</Text>}
          {!customAttributes.length ? null : customAttributes.map(attr => {
            return <Text key={attr.key} styleVariant="body2">{attr.key}: {attr.value}</Text>
          })}
          <Text styleVariant="body3">
            <FormattedPrice compareAt={(lineItem.variant.compareAtPrice || {}).amount}>
              {lineItem.variant.price.amount}
            </FormattedPrice>
          </Text>
        </div>
        <Text styleVariant="heading3">
          <FormattedPrice compareAt={lineItem.quantity * (lineItem.variant.compareAtPrice || {}).amount}>
            {lineItem.quantity * lineItem.variant.price.amount}
          </FormattedPrice>
        </Text>
      </div>
    })}
    {!notes ? null : <Text className="Cart__lineItemNotes" styleVariant="body3">{notes}</Text>}
    <div className="Cart__lineItemBottom">
      <LoadingConsumer>
        {({addLoad, removeLoad}) => <>
          <Counter
            min={1}
            max={99}
            onDecrement={() => {
              updateLineItem(
                addLoad, removeLoad, 'lineItemDecrement',
                checkoutLineItemsUpdate, updateCheckout,
                checkoutId, lineItems, -1
              )
            }}
            onIncrement={() => {
              updateLineItem(
                addLoad, removeLoad, 'lineItemIncrement',
                checkoutLineItemsUpdate, updateCheckout,
                checkoutId, lineItems, +1
              )
            }}>
            {quantity}
          </Counter>
          <LinkButton
            className="Cart__lineItemRemove"
            onClick={() => {
              updateLineItem(
                addLoad, removeLoad, 'lineItemRemove',
                checkoutLineItemsUpdate, updateCheckout,
                checkoutId, lineItems, -quantity
              )
            }}>
            Remove
          </LinkButton>
        </>}
      </LoadingConsumer>
    </div>
  </div>
}

function updateLineItem(
  addLoad, removeLoad, loadId,
  checkoutLineItemsUpdate, updateCheckout,
  checkoutId, lineItems, quantityChange
) {
  addLoad(loadId)
  checkoutLineItemsUpdate({
    variables: {
      checkoutId: checkoutId,
      lineItems: lineItems.map(item => {
        return {
          id: item.id,
          quantity: item.quantity + quantityChange,
        }
      }),
    }
  }).then(res => {
    const data = res.data.checkoutLineItemsUpdate
    if (data.userErrors.length > 0) console.log(data.userErrors)
    updateCheckout(data.checkout)
    removeLoad(loadId)
  })
}
