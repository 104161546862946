import React from 'react'
import AppIcon from '../../../../../../components/AppIcon'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function TopTheoristReward({className, icon, title, description}) {
  return <div className={classList(['TopTheoristReward', className])}>
    <div className="TopTheoristReward__iconWrapper">
      <AppIcon className="TopTheoristReward__icon" icon={icon} />
    </div>
    <Text className="TopTheoristReward__title" styleVariant="heading4">{title}</Text>
    <Text styleVariant="body2">{description}</Text>
  </div>
}
