import React from 'react'
import {computeEarliestDelivery} from '../../services/product'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import DeliveryWindow from '../DeliveryWindow'
import Text from '../Text'
import './styles.scss'

export default function ProductDelivery({
  className, tags, inventoryPolicy, fulfillmentQuantity, totalQuantity, theoristCount,
}) {
  const earliest = computeEarliestDelivery(tags, inventoryPolicy, fulfillmentQuantity, totalQuantity, theoristCount)
  const latest = earliest * 2 - 1
  return <div className={classList(['ProductDelivery', className])}>
    <AppIcon className="ProductDelivery__icon" icon="package" />
    <Text styleVariant="body3">
      Order today. Free delivery <DeliveryWindow
      className="ProductDelivery__time"
      earliest={earliest}
      latest={latest} />
    </Text>
  </div>
}
