import React from 'react'
import {classList} from '../../services/util'
import Button from '../Button'
import './styles.scss'

export default function PaginationButton({className, hasPagination, onPagination}) {
  return !hasPagination ? null : <div className={classList(['PaginationButton', className])}>
    <Button
      styleVariant="secondary"
      onClick={() => onPagination()}>
      Load more
    </Button>
  </div>
}
