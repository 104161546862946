import React from 'react'
import {Link, Redirect, Route, Switch, useLocation, useRouteMatch} from 'react-router-dom'
import Shell from '../Shell'
import './styles.scss'

export default function Legal() {
  const {path, url} = useRouteMatch()
  const {pathname} = useLocation()
  const computeActive = (link) => pathname === link;
  return <Shell
    className="Legal"
    title="Legal"
    noSticky>
    <div className="Legal__links">
      <Link
        className={`Legal__link ${computeActive(url) ? 'Legal__link--active' : ''}`}
        to={url}>
        Terms of Use
      </Link>
      <Link
        className={`Legal__link ${computeActive(`${url}/privacy`) ? 'Legal__link--active' : ''}`}
        to={`${url}/privacy`}>
        Privacy Policy
      </Link>
      <Link
        className={`Legal__link ${computeActive(`${url}/returns`) ? 'Legal__link--active' : ''}`}
        to={`${url}/returns`}>
        Returns & Refunds
      </Link>
    </div>
    <Switch>
      <Route exact path={path}>
        <iframe
          className="Legal__content"
          title="Terms of Use"
          src="https://docs.google.com/document/d/e/2PACX-1vSIuCeTPQFsfg2We_dgn8uc0ewORzYZdPe6DaWgfYFWzKGE5G9xPm4pVa4HcpkJUMsbioJlEIJZwN_Q/pub?embedded=true" />
      </Route>
      <Route path={`${path}/privacy`}>
        <iframe
          className="Legal__content"
          title="Privacy Policy"
          src="https://docs.google.com/document/d/e/2PACX-1vTNFz__8Mlk_SUcCMCguvTyAIJmuXwni6nkf8yhXx9hfuP1DgtDIhpaG4NGQuHfuduW53eJNn1pNZFk/pub?embedded=true" />
      </Route>
      <Route path={`${path}/returns`}>
        <iframe
          className="Legal__content"
          title="Returns & Refunds"
          src="https://docs.google.com/document/d/e/2PACX-1vRRQbJf9P-UhlaiIH34DwohH_zn5njqxiV5p5EpODK7KmhL_gKxc_lskmAO0YOrE0TsdXon7C7LiQnQ/pub?embedded=true" />
      </Route>
      <Route path="*">
        <Redirect to={url} />
      </Route>
    </Switch>
  </Shell>
}
