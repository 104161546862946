import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Redirect, useLocation, useParams} from 'react-router-dom'
import {useIsMounted} from 'react-tidy'
import Button from '../../components/Button'
import LoadingIndicator from '../../components/LoadingIndicator'
import {useLoading} from '../../components/LoadingWrapper'
import ReviewEditor from '../../components/ReviewEditor'
import Text from '../../components/Text'
import TextArea from '../../components/TextArea'
import {openChat} from '../../services/crisp'
import {callable, fetchDoc, useQuery, where} from '../../services/firebase'
import {computeIntegerId} from '../../services/product'
import {decodeParams} from '../../services/url'
import NotFound from '../NotFound'
import Shell from '../Shell'
import './styles.scss'

export default function Order() {
  const {hash, search} = useLocation()
  const {name} = useParams()
  const {email} = decodeParams(search)
  const {addLoad, removeLoad} = useLoading()
  const isMounted = useIsMounted()
  const {loading, error, docs} = useQuery('orders', [
    where('name', '==', `#${name ?? ''}`),
  ], [name])
  const [order, setOrder] = useState(null)
  const [theoristMap, setTheoristMap] = useState(null)
  const [rawData, setRawData] = useState({})
  const [reviewData, setReviewData] = useState({})
  const [authorized, setAuthorized] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [reviewed, setReviewed] = useState(false)
  useEffect(() => {
    if (!docs || docs.length < 1) return
    const data = docs[0].data
    if (!data) return
    const shopify = data.shopify || {}
    setAuthorized(data.shopify.email === email)
    setReviewed(!!data.reviewedAt)
    setOrder({
      ...data,
      ...shopify,
      lineItems: (shopify.lineItems || []).filter(lineItem => !!lineItem.vendor && !!lineItem.currentQuantity),
      statuses: [
        shopify.displayFinancialStatus,
        shopify.displayFulfillmentStatus,
        ...(shopify.fulfillments.map(fulfillment => fulfillment.displayStatus)),
      ],
    })
  }, [docs, email])
  useEffect(() => {
    if (!order) return
    setReviewData(prev => ({
      ...prev,
      orderId: computeIntegerId(order.id),
    }))
  }, [order])
  useEffect(() => {
    setReviewData(prev => ({
      ...prev,
      feedback: rawData.feedback || '',
      reviews: Object.keys(rawData.reviews || {})
        .map(key => rawData.reviews[key])
        .filter(review => !!review.rating)
    }))
  }, [rawData])
  useEffect(() => {
    if (submitting) addLoad('reviewOrder')
    else removeLoad('reviewOrder')
    return () => removeLoad('reviewOrder')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting])
  const createTheoristMap = async () => {
    const results = await Promise.all((order.pipelines || [{
      lineItems: order.lineItems.map(lineItem => computePipelineId(lineItem)),
    }]).map(pipeline => {
      const lineItems = order.lineItems
        .filter(lineItem => pipeline.lineItems.includes(computePipelineId(lineItem)))
      const isDesign = !!lineItems
        .find(lineItem => !!lineItem.customAttributes.find(attr => attr.key === 'Design ID'))
      const productIds = lineItems.map(lineItem => computeIntegerId(lineItem.product.id))
      return Promise.all(productIds.map(productId => fetchDoc('products', productId)))
        .then(snaps => {
          const products = snaps.filter(snap => !!snap.exists())
            .map(snap => snap.data())
          const steps = pipeline.steps || []
          return products.map(product => {
            let theoristId = null
            let theoristName = null
            if (isDesign) {
              if (product.shopify.tags.includes('custom-pocket') && steps.length > 0) {
                theoristId = steps[steps.length - 1].from
                const assignee = order.assignees[theoristId]
                theoristName = assignee.company || assignee.name
              } else if (product.shopify.tags.includes('custom-dye')) {
                if (steps.length > 2) theoristId = steps[steps.length - 2].from
                else theoristId = steps[steps.length - 1].from
                const assignee = order.assignees[theoristId]
                theoristName = assignee.company || assignee.name
              }
            } else if (product.theorist) {
              theoristId = product.theorist
              theoristName = product.shopify.vendor
            }
            return !theoristId ? null : {[product.shopify.id]: {id: theoristId, name: theoristName}}
          })
        })
    }))
    const reduced = (results || [])
      .reduce((acc, curr) => ([...acc, ...(curr || [])]), [])
      .reduce((acc, curr) => ({...acc, ...(curr || {})}), {})
    setTheoristMap(reduced)
  }
  useEffect(() => {
    if (!order) return
    createTheoristMap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])
  if (hash) return <Redirect to={hash.slice(1)} />
  if (loading) return <Shell>
    <LoadingIndicator />
  </Shell>
  if (error) console.log(`Failed to fetch order: ${error}`)
  if (!order || !authorized) return <NotFound />
  if (!theoristMap) return <Shell>
    <LoadingIndicator />
  </Shell>
  if (reviewed) return <Shell
    className="Order"
    title={`Order ${order.name}`}
    noSticky showChat>
    <Text styleVariant="heading2">Order {order.name}</Text>
    <Text
      className="Order__date"
      styleVariant="body2">
      Placed on {moment(order.createdAt).format('MMM D, YYYY')}
    </Text>
    <Text styleVariant="display3">
      Thank you for reviewing!
    </Text>
    <Text className="Order__reviewThanks">
      We value your feedback. If you have any other questions or comments about your order, please do not hesitate to reach out.
    </Text>
    <Button
      styleVariant="secondary"
      onClick={() => openChat()}>
      Contact us
    </Button>
  </Shell>
  return <Shell
    className="Order"
    title={`Order ${order.name}`}
    noSticky showChat>
    <Text styleVariant="heading2">Order {order.name}</Text>
    <Text
      className="Order__date"
      styleVariant="body2">
      Placed on {moment(order.createdAt).format('MMM D, YYYY')}
    </Text>
    <Text styleVariant="display3">
      {order.customer.displayName.split(/\s/)[0]}, how was your experience?
    </Text>
    {order.lineItems.map(lineItem => <ReviewEditor
      className="Order__reviewEditor"
      key={lineItem.variant.id}
      image={lineItem.image.url}
      product={lineItem.title}
      productLink={`/products/${lineItem.product.handle}?v=${lineItem.variant.id.split('/').pop()}`}
      variantTitle={lineItem.variantTitle}
      customAttributes={lineItem.customAttributes
        .filter(attr => attr.key !== 'Design ID' && attr.key !== 'Notes')
        .sort((a, b) => a.key.localeCompare(b.key))}
      vendor={lineItem.vendor}
      theoristName={(theoristMap[lineItem.product.id] || {}).name}
      onUpdate={({body, rating}) => {
        setRawData(prev => ({
          ...prev,
          reviews: {
            ...(prev.reviews || {}),
            [lineItem.variant.id]: {
              rating: rating,
              body: body,
              theorist: (theoristMap[lineItem.product.id] || {}).id,
              product: computeIntegerId(lineItem.product.id),
              variant: computeIntegerId(lineItem.variant.id),
              customAttributes: lineItem.customAttributes,
            }
          },
        }))
      }} />)}
    <div className="Order__feedback">
      <Text className="Order__feedbackLabel" styleVariant="heading3">Team Feedback</Text>
      <TextArea
        className="Order__feedbackInput"
        label="Team Feedback"
        placeholder="Do you have any private feedback for our team?"
        onChange={(value) => {
          setRawData(prev => ({
            ...prev,
            feedback: value,
          }))
        }} />
    </div>
    <Button
      disabled={!reviewData.feedback && (reviewData.reviews || []).length < 1}
      onClick={async () => {
        setSubmitting(true)
        try {
          await callable('reviewOrderV2')(reviewData)
        } catch (error) {
          console.log(error)
        } finally {
          if (isMounted()) {
            setSubmitting(false)
            setReviewed(true)
          }
        }
      }}>
      Submit
    </Button>
  </Shell>
}

const computePipelineId = lineItem => (lineItem.customAttributes
  .find(attribute => attribute.key === 'Design ID') || {}).value
  || (lineItem.variant || {}).id || lineItem.title
