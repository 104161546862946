import React, {useEffect, useMemo, useState} from 'react'
import {useIsMounted} from 'react-tidy'
import AppIcon from '../../../../../../components/AppIcon'
import Button from '../../../../../../components/Button'
import LinkButton from '../../../../../../components/LinkButton'
import {useLoading} from '../../../../../../components/LoadingWrapper'
import StatusIndicator from '../../../../../../components/StatusIndicator'
import Text from '../../../../../../components/Text'
import {callable, useCallable} from '../../../../../../services/firebase'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function DashNextSteps({className, stripe}) {
  const [loading, setLoading] = useState(false)
  const emptyParams = useMemo(() => ({}), [])
  const {data} = useCallable('stripeAccountV2', emptyParams, !stripe)
  const isMounted = useIsMounted()
  const {addLoad, removeLoad} = useLoading()
  useEffect(() => {
    if (loading) addLoad('stripe')
    else removeLoad('stripe')
    return () => removeLoad('stripe')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  return <div className={classList(['DashNextSteps', className])}>
    <Text styleVariant="heading3">Things to do next</Text>
    <div className="DashNextSteps__quickLinks">
      {[{
        icon: 'users',
        title: 'Community',
        description: 'Join our Discord or GroupMe to meet and get support from other theorists.',
        socialLinks: [{
          icon: 'discord',
          text: 'Discord',
          to: '/dash/discord',
          style: {background: 'linear-gradient(45deg, #5865f2, #eb459e)'},
        }, {
          icon: 'group-me',
          text: 'GroupMe',
          to: '/dash/groupme',
          style: {background: 'linear-gradient(45deg, #206cf8, #00aff0)'},
        }],
      }, {
        icon: 'bank',
        title: 'Payouts',
        description: 'Setup or manage your Stripe account for secure payment from String Theory.',
        ctaLink: {
          text: !stripe ? 'Setup' : 'Manage',
          styleVariant: !stripe ? 'primary' : 'tertiary',
          onClick: async () => {
            setLoading(true)
            try {
              const result = await callable('stripeAccountLinkV2')()
              const accountLink = result.data
              if (isMounted() && accountLink && accountLink.url) {
                setLoading(false)
                window.location.href = accountLink.url
              }
            } catch (error) {
              console.log(error)
              if (isMounted()) setLoading(false)
            }
          },
        },
        ...(!data ? {} : {status: (data.capabilities && data.capabilities.transfers) || 'inactive'}),
      }, {
        icon: 'tag',
        title: 'Products',
        description: 'List strung heads or create pockets and dyes that can be added to any head.',
        ctaLink: {
          text: 'Add',
          to: '/dash/products/new',
        },
        link: {
          text: 'View',
          to: '/dash/products',
        },
      }, {
        icon: 'shopping-bag',
        title: 'Discounted inventory',
        description: <>Save 10% with code <b>POCKETSBYPROS</b> on all unstrung heads, mesh, and apparel.</>,
        link: {
          text: 'Shop now',
          to: '/shop/inventory',
        },
      }].map(option => <div
        key={`DashNextSteps ${option.title}`}
        className="DashNextSteps__option">
        <AppIcon className="DashNextSteps__optionIcon" icon={option.icon} />
        <div className="DashNextSteps__optionContent">
          <Text styleVariant="heading4">{option.title}</Text>
          <Text className="DashNextSteps__optionDescription" styleVariant="body2">{option.description}</Text>
          <div className="DashNextSteps__optionLinks">
            {option.ctaLink && <Button
              className="DashNextSteps__optionLink"
              styleVariant={option.ctaLink.styleVariant || 'tertiary'}
              to={option.ctaLink.to}
              onClick={() => {if (option.ctaLink.onClick) option.ctaLink.onClick()}}
              slim>
              {option.ctaLink.text}
            </Button>}
            {option.link && <LinkButton
              className="DashNextSteps__optionLink"
              to={option.link.to}>
              {option.link.text}
            </LinkButton>}
            {option.socialLinks && option.socialLinks.map(socialLink => <Button
              key={socialLink.text}
              className="DashNextSteps__optionLink"
              style={socialLink.style}
              to={socialLink.to}
              slim>
              <AppIcon className="DashNextSteps__optionLinkIcon" icon={socialLink.icon} />
              {socialLink.text}
            </Button>)}
            {option.status && <StatusIndicator status={option.status} />}
          </div>
        </div>
      </div>)}
    </div>
  </div>
}
