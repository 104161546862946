import React, {useState} from 'react'
import moment from 'moment'
import AppIcon from '../../../../../../components/AppIcon'
import Button from '../../../../../../components/Button'
import DeliveryWindow from '../../../../../../components/DeliveryWindow'
import Input from '../../../../../../components/Input'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function OrderPipelinePackage({className, createdAt, delivered, parcel, steps, onAddPackage}) {
  const [packageInputs, setPackageInputs] = useState({
    length: 12, width: 9, height: 4, weight: 1,
  })
  const earliest = 4 * ((steps || []).length - 1) + 3
  const latest = earliest * 2 - 1
  const codeYellow = moment().diff(moment(createdAt).add(earliest, 'd'), 'd') >= 0
  const codeRed = moment().diff(moment(createdAt).add(latest, 'd'), 'd') >= 0
  return <div className={classList(['OrderPipelinePackage', className])}>
    <AppIcon className="OrderPipelinePackage__icon" icon='package' />
    <div className="OrderPipelinePackage__text">
      {!parcel ? <>
        <div className="OrderPipelinePackage__inputs">
          {['Length', 'Width', 'Height', 'Weight'].map(label => <Input
            key={`OrderPipelinePackage_input_${label}`}
            label={label}
            value={packageInputs[label.toLowerCase()]}
            onChange={(value) => setPackageInputs(prev => ({
              ...prev,
              [label.toLowerCase()]: value,
            }))} />)}
        </div>
        <div className="OrderPipelinePackage__submit">
          <Button
            onClick={() => onAddPackage(packageInputs)}
            slim>
            Add package
          </Button>
        </div>
      </> : <Text>
        {parcel.weight} lb{parcel.weight === 1 ? '' : 's'}&nbsp;
        {parcel.length} x {parcel.width} x {parcel.height} in
      </Text>}
      {(steps || []).length < 1 ? null : <Text
        className={classList([
          'OrderPipelinePackage__delivery',
          !delivered && codeYellow && 'OrderPipelinePackage__delivery--yellow',
          !delivered && codeRed && 'OrderPipelinePackage__delivery--red',
        ])}
        styleVariant="body2">
        Expected <DeliveryWindow
        earliest={earliest}
        latest={latest}
        from={createdAt} />
      </Text>}
    </div>
  </div>
}
