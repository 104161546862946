import React from 'react'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import Text from '../Text'
import './styles.scss'

export default function Checkbox({className, label, checked, disabled, onToggle}) {
  const onClick = () => {if (onToggle) onToggle(checked)}
  return <div
    className={classList([
      'Checkbox',
      !disabled ? null : 'Checkbox--disabled',
      className,
    ])}
    onClick={onClick}>
    <button
      className={classList([
        'Checkbox__box',
        !checked ? null : 'Checkbox__box--checked'
      ])}
      disabled={disabled}
      onClick={onClick}>
      {!checked ? null : <AppIcon
        className="Checkbox__icon"
        icon="check"/>}
    </button>
    <Text>{label}</Text>
  </div>
}
