import React from 'react'
import Helmet from 'react-helmet'

export default function SEO({author, description, lang='en', meta, keywords, title}) {
  const metaDescription = description || 'Get custom heads from the best lacrosse stringers in the world.'
  return <Helmet
    htmlAttributes={{lang}}
    {...!title ? {
      title: 'String Theory',
    } : {
      title: title,
      titleTemplate: `%s | String Theory`,
    }}
    meta={[
      {
        name: 'description',
        content: metaDescription,
      },
      {
        property: 'og:title',
        content: title || 'String Theory',
      },
      {
        property: 'og:description',
        content: metaDescription,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:creator',
        content: author || 'String Theory',
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:description',
        content: metaDescription,
      },
    ].concat((keywords || []).length > 0 ? {
      name: 'keywords',
      content: keywords.join(`, `),
    } : []).concat(meta || [])} />
}
