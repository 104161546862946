import React from 'react'
import {classList} from '../../services/util'
import './styles.scss'

export default function Text({className, children, styleVariant, ...props}) {
  const TextComponent = mapComponent(styleVariant)
  return <TextComponent
    className={classList([
      'Text',
      `Text--${styleVariant || 'body1'}`,
      className,
    ])}
    {...props}>
    {children}
  </TextComponent>
}

function mapComponent(styleVariant) {
  switch (styleVariant) {
    case 'body1':
    case 'body2':
    case 'body3':
    case 'body4':
    case 'body5':
    case 'body6':
      return 'p'
    case 'display1':
    case 'display2':
    case 'display3':
    case 'heading1':
      return 'h1'
    case 'heading2':
      return 'h2'
    case 'heading3':
      return 'h3'
    case 'heading4':
      return 'h4'
    case 'heading5':
      return 'h5'
    case 'heading6':
    case 'heading7':
      return 'h6'
    case 'label1':
    case 'label2':
    case 'label3':
    case 'label4':
    case 'label5':
    case 'label6':
    case 'label7':
      return 'label'
    default:
      return 'div'
  }
}
