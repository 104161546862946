import React from 'react'
import {computeColor} from '../../services/product'
import {classList} from '../../services/util'
import Text from '../Text'
import './styles.scss'

export default function ColorOption({className, color, selected, disabled, ...props}) {
  return <button
    className={classList([
      'ColorOption',
      !selected ? null : 'ColorOption--selected',
      !disabled ? null : 'ColorOption--disabled',
      className,
    ])}
    disabled={disabled}
    {...props}>
    <div
      className="ColorOption__fill"
      style={{background: computeColor(color)}} />
    <div className="ColorOption__overlay" />
  </button>
}

export const ColorGroup = ({className, children, label}) => <div
  className={classList(['ColorGroup', className])}>
  <div className="ColorGroup__options">{children}</div>
  {!label ? null : <Text
    className="ColorGroup__label"
    styleVariant="heading3">
    {label}
  </Text>}
</div>
