import React from 'react'
import AppIcon from '../../../../components/AppIcon'
import Button from '../../../../components/Button'
import Text from '../../../../components/Text'
import './styles.scss'
import DashMetrics from './scenes/DashMetrics'
import DashNextSteps from './scenes/DashNextSteps'
import DashRewards from './scenes/DashRewards'

export default function DashHome({theorist}) {
  return <div className="DashHome">
    <Text
      styleVariant="heading2">
      Hey {theorist.name} 👋
    </Text>
    <div className="DashHome__quickView">
      <Button
        styleVariant="tertiary"
        to={`/${theorist.handle}`}
        slim>
        <AppIcon
          className="DashHome__profileIcon"
          icon="user" />
        View profile
      </Button>
      {!!theorist.metrics?.overall?.elite && <>
        <AppIcon className="DashHome__eliteIcon" icon="award-filled" />
        <Text styleVariant="heading4">Top Theorist</Text>
      </>}
    </div>
    <DashMetrics
      className="DashHome__section"
      metrics={theorist.metrics} />
    <DashRewards className="DashHome__section" />
    <DashNextSteps
      className="DashHome__section"
      stripe={theorist.stripe} />
  </div>
}
