import React from 'react'
import LinkButton from '../../../../components/LinkButton'
import Text from '../../../../components/Text'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function DesignNotes({className, value, onChange}) {
  return <div className={classList(['DesignNotes', className])}>
    <div className="DesignNotes__header">
      <Text styleVariant="heading3">Notes</Text>
      {!value ? null : <LinkButton
        className="DesignNotes__clearButton"
        onClick={() => {if (onChange) onChange('')}}>
        Clear
      </LinkButton>}
    </div>
    <textarea
      className="DesignNotes__textarea"
      value={value}
      onChange={(e) => {if (onChange) onChange(e.target.value)}}
      placeholder={'Add any special requests or details to your order and we will do our best to accommodate you.'} />
  </div>
}
