import React, {useEffect, useMemo, useState} from 'react'
import moment from 'moment'
import {useHistory, useParams} from 'react-router-dom'
import {useIsMounted} from 'react-tidy'
import Button from '../../../../../../components/Button'
import Checkbox from '../../../../../../components/Checkbox'
import Dialog from '../../../../../../components/Dialog'
import IconButton from '../../../../../../components/IconButton'
import LoadingIndicator from '../../../../../../components/LoadingIndicator'
import {useLoading} from '../../../../../../components/LoadingWrapper'
import Text from '../../../../../../components/Text'
import {callable, useQuery, useRealtimeDoc, where} from '../../../../../../services/firebase'
import {computeIntegerId} from '../../../../../../services/product'
import {groupLineItems} from '../../../../../../services/shopify'
import LineItemSection from '../../components/LineItemSection'
import OrderStatus from '../../components/OrderStatus'
import CustomerInfo from '../CustomerInfo'
import OrderPipeline from '../OrderPipeline'
import PaymentInfo from '../PaymentInfo'
import TrackingInfo from '../TrackingInfo'
import './styles.scss'

export default function DashOrderDetail({user, theorist}) {
  const isAdmin = theorist.role === 'admin'
  const isManager = theorist.role === 'manager'
  const [assigned, setAssigned] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [dialog, openDialog] = useState('')
  const [order, setOrder] = useState({})
  const [checkedItems, setCheckedItems] = useState({})
  const [automations, setAutomations] = useState({
    notifyCustomer: true,
    payTheorists: true,
    updateMetrics: true,
  })
  const history = useHistory()
  const {id} = useParams()
  const isMounted = useIsMounted()
  const {addLoad, removeLoad} = useLoading()
  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useRealtimeDoc('orders', id)
  const {
    loading: reviewLoading,
    error: reviewError,
    data: reviewDocs,
  } = useQuery('reviews', [
    where('order', '==', id),
  ])
  const loading = orderLoading || reviewLoading
  const error = orderError || reviewError
  useEffect(() => {
    if (!orderData) {
      setAssigned(false)
      setOrder({})
      return
    }
    setAssigned((orderData.assignees || {keys: []}).keys.includes(user.uid))
    const shopify = orderData.shopify || {}
    setOrder({
      ...orderData,
      ...shopify,
      statuses: [
        shopify.displayFinancialStatus,
        shopify.displayFulfillmentStatus,
        ...(shopify.fulfillments.map(fulfillment => fulfillment.displayStatus)),
      ],
    })
  }, [orderData, user.uid])
  useEffect(() => {
    if (updating || loading) addLoad('dash')
    else removeLoad('dash')
    return () => removeLoad('dash')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating, loading])
  const readAccess = useMemo(() => isAdmin || isManager || assigned, [isAdmin, isManager, assigned])
  const goBack = () => history.push('/dash/orders')
  const closeDialog = () => openDialog('')
  if (loading) return <div className="DashOrderDetail">
    <LoadingIndicator />
  </div>
  if (error) console.log(error)
  if (!orderData || !readAccess) return <div className="DashOrderDetail">
    <div className="DashOrderDetail__head DashOrderDetail__head--error">
      <IconButton icon="arrow-left" onClick={() => goBack()} />
      <Text
        className="DashOrderDetail__errorTitle"
        styleVariant="heading3">There's no order at this address.</Text>
    </div>
    <Text>Check the URL and try again, or go back to your list of orders to find it.</Text>
  </div>
  const runCallable = async (name, data) => {
    setUpdating(true)
    try {
      await callable(name)({
        orderId: computeIntegerId(order.id),
        ...data,
      })
    } catch (error) {
      console.log(error)
    } finally {
      if (isMounted()) setUpdating(false)
    }
  }
  const lineItems = (order.lineItems || []).filter(lineItem => !!lineItem.vendor)
  const reviewedLineItems = incorporateReviews(lineItems, reviewDocs || [])
  const lineItemGroups = groupLineItems(reviewedLineItems)
  const mappedLineItemGroups = mapLineItemGroups(lineItemGroups, order.pipelines || [])
  const checkedLineItems = Object.keys(checkedItems).filter(k => !!checkedItems[k])
  return <div className="DashOrderDetail">
    <div className="DashOrderDetail__head">
      <IconButton className="DashOrderDetail__headBack" icon="arrow-left" onClick={() => goBack()} />
      <div className="DashOrderDetail__headInfo">
        <div className="DashOrderDetail__titleStatus">
          <Text
            className="DashOrderDetail__title"
            styleVariant="heading2">{order.name}</Text>
          <OrderStatus statuses={order.statuses} />
        </div>
        <Text styleVariant="body2">{moment(order.createdAt).format('MMM D [at] h:mm a')}</Text>
      </div>
    </div>
    <div className="DashOrderDetail__body">
      <div className="DashOrderDetail__leftColumn">
        {!order.pipelines ? null : <>
          {order.pipelines.map((pipeline, index) => <OrderPipeline
            key={`pipeline_${index}`}
            className="DashOrderDetail__section"
            assignees={order.assignees}
            closedAt={order.closedAt}
            createdAt={order.createdAt}
            customerAddress={order.shippingAddress}
            isAdmin={isAdmin}
            isManager={isManager}
            lineItemGroups={mappedLineItemGroups[index]}
            parcel={pipeline.package}
            steps={pipeline.steps}
            userId={user.uid}
            onAddPackage={(parcel) => runCallable(
              'addPipelinePackageV2',
              {pipelineIndex: index, ...parcel}
            )}
            onAddSteps={(assignees, steps) => runCallable(
              'addPipelineStepsV2',
              {pipelineIndex: index, assignees: assignees, steps: steps},
            )}
            onBuyShippingLabel={(stepIndex, rateId) => runCallable(
              'buyShippingLabelV2',
              {pipelineIndex: index, stepIndex: stepIndex, rateId: rateId},
            )}
            onUpdateStep={(stepIndex, step) => runCallable(
              'updatePipelineStepV2',
              {
                pipelineIndex: index,
                stepIndex: stepIndex,
                cancelled: step.cancelled,
                payout: step.payout,
                shippingDays: step.shippingDays,
                tracking: step.tracking,
              },
            )} />)}
        </>}
        <div className="DashOrderDetail__section">
          <LineItemSection
            title="Fulfilled by String Theory"
            lineItemGroups={mappedLineItemGroups.fulfilled}
            mapQuantity={group => group[0].currentQuantity - (group[0].unfulfilledQuantity || 0)} />
          <LineItemSection
            title="Unfulfilled"
            lineItemGroups={mappedLineItemGroups.unfulfilled}
            mapQuantity={group => group[0].unfulfilledQuantity || 0}
            wrapItem={item => {
              if (!isAdmin) return item
              const groupId = computeLineItemId(item.props.lineItems)
              return <div
                key={groupId}
                className="DashOrderDetail__adminLine">
                <Checkbox
                  checked={checkedItems[groupId]}
                  onToggle={(checked) => setCheckedItems(prev => ({
                    ...prev,
                    [groupId]: !checked,
                  }))} />
                {item}
              </div>
            }} />
          {isAdmin && mappedLineItemGroups.unfulfilled && <div className="DashOrderDetail__adminButton">
            <Button
              disabled={checkedLineItems.length === 0}
              onClick={() => runCallable('createPipelineV2', {lineItems: checkedLineItems})}
              slim>
              Create pipeline
            </Button>
          </div>}
          <LineItemSection
            title="Removed"
            lineItemGroups={mappedLineItemGroups.removed}
            mapQuantity={(group) => group[0].quantity - group[0].currentQuantity} />
        </div>
        <PaymentInfo order={order} />
        <TrackingInfo fulfillments={order.fulfillments} />
      </div>
      <CustomerInfo
        className="DashOrderDetail__rightColumn"
        order={order} />
    </div>
    {isAdmin && !order.completedAt && <div className="DashOrderDetail__footer">
      <div>
        <Text styleVariant="heading5">Automations</Text>
        {Object.keys(automations).map(key =>
          <Checkbox
            key={`DashOrderDetail_${key}`}
            className="DashOrderDetail__automation"
            checked={automations[key]}
            label={(key.charAt(0).toUpperCase() + key.slice(1)).replace(/([A-Z])/g, ' $1')}
            onToggle={checked => setAutomations(prev => ({
              ...prev,
              [key]: !checked,
            }))} />
        )}
      </div>
      <Button
        slim
        disabled={false}
        onClick={() => {
          const likelyComplete = order.statuses.includes('DELIVERED')
          if (likelyComplete) runCallable('completeOrderV2', automations)
          else openDialog('complete')
        }}>
        Complete
      </Button>
    </div>}
    <Dialog
      title={`Complete order ${order.name}?`}
      open={dialog === 'complete'}
      onDismiss={() => closeDialog()}>
      <Text>
        Are you sure you want to complete this order? No line items have been delivered and it can't be undone.
      </Text>
      <div className="DashProductEdit__dialogActions">
        <Button slim styleVariant="tertiary" onClick={() => closeDialog()}>Cancel</Button>
        <Button slim onClick={() => {
          closeDialog()
          runCallable('completeOrderV2', automations)
        }}>
          Complete
        </Button>
      </div>
    </Dialog>
  </div>
}

const computeLineItemId = group => (group[0].customAttributes
  .find(attribute => attribute.key === 'Design ID') || {}).value
  || (group[0].variant || {}).id || group[0].title

const incorporateReviews = (lineItems, reviewDocs) => lineItems.map(lineItem => {
  const review = reviewDocs.find(reviewDoc =>
    reviewDoc.data.product === computeIntegerId(lineItem.product.id)
    && reviewDoc.data.variant === computeIntegerId(lineItem.variant.id))
  if (!review) return lineItem
  return {
    ...lineItem,
    review: review.data,
  }
})

const mapLineItemGroups = (groups, pipelines) => groups.map(group => {
  const groupId = computeLineItemId(group)
  const pipelineIndex = pipelines.findIndex(pipeline => pipeline.lineItems.includes(groupId))
  const currentItems = group.filter(item => item.currentQuantity > 0)
  const removedItems = group.filter(item => item.quantity - item.currentQuantity > 0)
  const unfulfilledItems = group.filter(item => (item.unfulfilledQuantity || 0) > 0 && item.currentQuantity > 0)
  const fulfilledItems = group.filter(item => item.currentQuantity - (item.unfulfilledQuantity || 0) > 0)
  return {
    [pipelineIndex]: pipelineIndex < 0 ? [] : currentItems,
    fulfilled: pipelineIndex < 0 ? fulfilledItems : [],
    removed: removedItems,
    unfulfilled: pipelineIndex < 0 ? unfulfilledItems : [],
  }
}).reduce((acc, curr) => {
  Object.keys(curr)
    .filter(k => curr[k].length > 0)
    .forEach(k => acc[k] = [...(acc[k] || []), curr[k]])
  return acc
}, {})
