import React from 'react'
import {createBrowserHistory} from 'history'
import {Router, Route, Switch} from 'react-router-dom'
import Accessibility from '../../components/Accessibility'
import LoadingWrapper from '../../components/LoadingWrapper'
import ScrollTop from '../../components/ScrollTop'
import * as analytics from '../../services/analytics'
import ContentfulService from '../../services/contentful'
import {initChat} from '../../services/crisp'
import FirebaseProvider from '../../services/firebase'
import ShopifyProvider from '../../services/shopify'
import Blog from '../Blog'
import Cart from '../Cart'
import Dash from '../Dash'
import Design from '../Design'
import Home from '../Home'
import Legal from '../Legal'
import Login from '../Login'
import Order from '../Order'
import Product from '../Product'
import Shop from '../Shop'
import SignUp from '../SignUp'
import Theorist from '../Theorist'

export default function App() {
  const history = createBrowserHistory()
  analytics.initialize(history)
  initChat()
  return <FirebaseProvider>
    <ContentfulService>
      <ShopifyProvider>
        <Accessibility>
          <LoadingWrapper>
            <Router history={history}>
              <ScrollTop />
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/signup">
                  <SignUp />
                </Route>
                <Route exact path="/design">
                  <Design />
                </Route>
                <Route path="/dash">
                  <Dash />
                </Route>
                <Route path="/products/:handle">
                  <Product />
                </Route>
                <Route path="/shop">
                  <Shop />
                </Route>
                <Route path="/orders/:name">
                  <Order />
                </Route>
                <Route path="/orders">
                  <Order />
                </Route>
                <Route path="/blog/:slug">
                  <Blog />
                </Route>
                <Route path="/terms">
                  <Legal />
                </Route>
                <Route path="*">
                  <Theorist />
                </Route>
              </Switch>
              <Cart />
            </Router>
          </LoadingWrapper>
        </Accessibility>
      </ShopifyProvider>
    </ContentfulService>
  </FirebaseProvider>
}
