import React from 'react'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function CustomerLine({className, children, styleVariant}) {
  return !children ? null : <Text
    className={classList(['CustomerLine', className])}
    styleVariant={styleVariant || 'body3'}>
    {children}
  </Text>
}
