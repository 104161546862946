import React from 'react'
import {classList} from '../../../../services/util'
import Text from '../../../../components/Text'
import './styles.scss'

export default function DashPending({className, name}) {
  return <div className={classList(['DashPending', className])}>
    <Text styleVariant="heading2">Hey {name} 👋</Text>
    <Text
      className="DashPending__message"
      styleVariant="heading3">
      Your account is under review.
    </Text>
    <Text className="DashPending__message">
      Our review process currently takes about 1-2 weeks. If approved, we will be in touch to welcome you to the team!
    </Text>
  </div>
}
