import React from 'react'
import {classList} from '../../../../../../services/util'
import PriceLine from '../../components/PriceLine'
import './styles.scss'

export default function PaymentInfo({className, order}) {
  return <div className={classList(['PaymentInfo', className])}>
    {computeAmount(order.currentTotalPriceSet) !== computeAmount(order.totalPriceSet) ? <div
      className="PaymentInfo__sectionTop">
      <PriceLine
        className="PaymentInfo__line"
        prices={[computeAmount(order.totalReceivedSet)]}
        required>
        Original order
      </PriceLine>
    </div> : null}
    <div className="PaymentInfo__sectionTop">
      {priceLineList([{
        title: 'Subtotal',
        prices: [order.currentSubtotalPriceSet, order.currentTotalDiscountsSet],
        extra: `${order.currentSubtotalLineItemsQuantity || 0} item${
          order.currentSubtotalLineItemsQuantity === 1 ? '' : 's'}`,
        required: true,
      }, {
        title: 'Discount',
        prices: [order.currentTotalDiscountsSet],
        extra: order.discountCode,
        negative: true,
      }, {
        title: 'Shipping',
        prices: [order.totalShippingPriceSet],
        extra: 'Standard',
        required: true,
      }, {
        title: 'Tax',
        prices: [order.currentTotalTaxSet],
      }, {
        title: 'Tip',
        prices: [order.totalTipReceivedSet],
      }, {
        title: 'Total',
        prices: [order.currentTotalPriceSet],
        required: true,
        bold: true,
      }])}
    </div>
    <PriceLine
      className="PaymentInfo__line"
      prices={[computeAmount(order.totalReceivedSet)]}
      currency={order.totalReceivedSet?.presentmentMoney?.currencyCode}
      required>
      Paid by customer
    </PriceLine>
    {computeAmount(order.totalRefundedSet) === 0 && computeAmount(order.totalRefundedShippingSet) === 0
      ? null : <div className="PaymentInfo__sectionBottom">
        {priceLineList([{
          title: 'Refunded',
          prices: [order.totalRefundedSet],
          negative: true,
        }, {
          title: 'Refunded shipping',
          prices: [order.totalRefundedShippingSet],
          negative: true,
        }, {
          title: 'Net payment',
          prices: [order.netPaymentSet],
          required: true,
          bold: true,
        }])}
      </div>}
  </div>
}

const computeAmount = (price) => !price ? 0 : parseFloat(price.presentmentMoney.amount) || 0

const priceLineList = (priceLines) => priceLines.map(priceLine => <PriceLine
  className="PaymentInfo__line"
  key={`priceLine_${priceLine.title}`}
  prices={priceLine.prices.map(price => computeAmount(price))}
  currency={priceLine.prices[0]?.presentmentMoney?.currencyCode}
  extra={priceLine.extra}
  required={!!priceLine.required}
  bold={!!priceLine.bold}
  negative={!!priceLine.negative}>
  {priceLine.title}
</PriceLine>)
