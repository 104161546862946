import React, {useEffect, useState} from 'react'
import {useContentful} from 'react-contentful'
import {useHistory, useLocation} from 'react-router'
import {useMediaQuery} from 'react-responsive/src'
import LinkButton from '../../components/LinkButton'
import LoadingIndicator from '../../components/LoadingIndicator'
import Text from '../../components/Text'
import {ContentModel} from '../../services/contentful'
import {decodeParams} from '../../services/url'
import {classList} from '../../services/util'
import NotFound from '../NotFound'
import Shell from '../Shell'
import ShopModule from '../ShopModule'
import ShopFilterToggle from './components/ShopFilterToggle'
import ShopComplexFilters from './scenes/ShopComplexFilters'
import ShopSimpleFilters from './scenes/ShopSimpleFilters'
import './styles.scss'

export default function Shop() {
  const history = useHistory()
  const {pathname, search} = useLocation()
  const isMobile = useMediaQuery({maxWidth: 720})
  const [resultSize, setResultSize] = useState(null)
  const [openFilters, setOpenFilters] = useState(false)
  const {loading, fetched, error, data} = useContentful({
    contentType: 'page',
    query: {'fields.link': pathname},
  })
  useEffect(() => {setOpenFilters(!isMobile)}, [isMobile])
  if (loading || !fetched) return <Shell className="Shop" />
  if (error || !data || data.items.length === 0) return <NotFound />
  const page = ContentModel(data.items[0])
  const queryParams = decodeParams(search) || {}
  delete queryParams.fbclid
  const activeModules = page.modules.filter(mod => !(mod.tags || []).includes('hidden'))
  const simpleFilters = <ShopSimpleFilters filters={(page.filters || []).filter(fil => !fil.children)} />
  const complexFilters = (page.filters || []).filter(fil => !!fil.children)
  const noResults = activeModules.length === 1 && resultSize === 0
  const showResultSize = activeModules.length === 1 && resultSize > 0
  const showReset = Object.keys(queryParams).length > 0
  const additionalTags = Object.keys(queryParams)
    .map(key => key === 'filter' ? queryParams[key] : `${key}-${queryParams[key]}`)
  return <Shell
    className="Shop"
    title={page.title}
    noSticky>
    <div className="Shop__header">
      <Text styleVariant="heading1">{page.title}</Text>
      {isMobile ? simpleFilters : null}
      <div className="Shop__headerExtra">
        {complexFilters.length > 0 && showReset ? <LinkButton
          className="Shop__reset"
          onClick={() => history.replace(pathname)}>
          Reset
        </LinkButton> : null}
        {showResultSize ? <Text
          className="Shop__resultSize"
          styleVariant="body2">
          {resultSize > 99 ? '99+' : resultSize} result{resultSize === 1 ? '' : 's'}
        </Text>: null}
        {!isMobile ? simpleFilters : <div className="Shop__headerExtraSpace" />}
        {isMobile && complexFilters.length > 0 ? <ShopFilterToggle
          className="Shop__filterToggle"
          count={Object.keys(queryParams).filter(key => key !== 'filter').length}
          onClick={() => setOpenFilters(true)} /> : null}
      </div>
    </div>
    <div className="Shop__body">
      <ShopComplexFilters
        filters={complexFilters}
        resultSize={resultSize}
        open={openFilters}
        onClose={() => setOpenFilters(false)} />
      <div className="Shop__content">
        {resultSize === null || noResults ? <div className="Shop__empty">
          {!noResults ? <LoadingIndicator /> : <Text styleVariant="heading2">No results.</Text>}
        </div> : null}
        <div className={classList([
          'Shop__modules',
          resultSize !== null ? null : 'Shop__modules--loading'
        ])}>
          {activeModules.map((mod, index) => <ShopModule
            key={mod.id}
            onLoad={(size) => {if (index === 0) setResultSize(size)}}
            {...mod}
            tags={[
              ...(mod.tags || []),
              ...(additionalTags || [])
            ].filter(it => !!it)} />)}
        </div>
      </div>
    </div>
  </Shell>
}
