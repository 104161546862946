import React from 'react'
import {classList} from '../../services/util'
import './styles.scss'

export default function TextArea({className, value, placeholder, onChange}) {
  return <textarea
    className={classList(['TextArea', className])}
    value={value}
    onChange={(e) => {if (onChange) onChange (e.target.value)}}
    placeholder={placeholder} />
}
