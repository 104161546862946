import React, {useState} from 'react'
import {useMediaQuery} from 'react-responsive/src'
import {useLocation} from 'react-router-dom'
import {animateScroll} from 'react-scroll'
import AppBar from '../../components/AppBar'
import Footer from '../../components/Footer'
import SEO from '../../components/SEO'
import SiteLinks, {SiteDrawer} from '../../components/SiteLinks'
import Text from '../../components/Text'
import {useTheorist} from '../../services/contentful'
import {useChat} from '../../services/crisp'
import {useCheckout} from '../../services/shopify'
import {classList} from '../../services/util'
import './styles.scss'

export default function Shell({
  className, children, noSticky, showChat, renderDrawer, drawerPosition,
  appBarContent, banner, bannerClass, ...props
}) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const smallScreen = useMediaQuery({maxWidth: 1080})
  const {pathname} = useLocation()
  const {checkout, open, toggleCheckout} = useCheckout()
  const {theorist} = useTheorist()
  useChat(!!showChat)
  const drawer = renderDrawer && <div className="Shell__drawer">
    {renderDrawer()}
  </div>
  return <div className="Shell">
    <SEO {...props} />
    <AppBar
      className="Shell__appBar"
      sticky={!noSticky}
      fullWidth={!!drawer}
      borderBottom={!!drawer}
      menuPosition="right"
      cartSize={!checkout ? 0 : computeCartSize(checkout)}
      onMenuClick={!smallScreen ? null : () => setDrawerOpen(!drawerOpen)}
      onLogoClick={(scrollTop) => {
        const duration = Math.min(scrollTop / 1000, 1) * 300
        animateScroll.scrollToTop({duration: duration})
      }}
      onCartClick={() => toggleCheckout(!open)}>
      {appBarContent || (smallScreen ? null : <SiteLinks
        path={pathname}
        theorist={theorist} />)}
    </AppBar>
    {!banner ? null : <div className={classList(['Shell__banner', bannerClass])}>
      <Text styleVariant="body3">{banner}</Text>
    </div>}
    <div className={classList([
      'Shell__container',
      drawerPosition !== 'right' ? null : 'Shell__container--drawerRight',
    ])}>
      {!smallScreen ? (drawer || null) : <SiteDrawer
        path={pathname}
        theorist={theorist}
        open={drawerOpen}
        closeDrawer={() => setDrawerOpen(false)} />}
      <div className="Shell__main">
        <main className={classList(['Shell__content', className])}>{children}</main>
        <Footer className="Shell__footer" />
      </div>
    </div>
  </div>
}

const computeCartSize = (checkout) => checkout.lineItems
  .map(item => item.quantity)
  .reduce((quantity, total) => total + quantity, 0)
