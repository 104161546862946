import React from 'react'
import Text from '../Text'
import IconButton from '../IconButton'
import './styles.scss'

export default function Counter({className, children, min, max, onIncrement, onDecrement}) {
  return <div className={`Counter ${className}`}>
    <IconButton
      className="Counter__button"
      icon="minus"
      disabled={min && children <= min}
      onClick={() => {if (onDecrement) onDecrement()}} />
    <Text className="Counter__value">{children}</Text>
    <IconButton
      className="Counter__button"
      icon="plus"
      disabled={max && children >= max}
      onClick={() => {if (onIncrement) onIncrement()}} />
  </div>
}
