import React, {useEffect, useMemo, useState} from 'react'
import lodash from 'lodash'
import moment from 'moment'
import Button from '../../../../../../components/Button'
import Checkbox from '../../../../../../components/Checkbox'
import Dropdown, {DropdownItem} from '../../../../../../components/Dropdown'
import FormattedPrice from '../../../../../../components/FormattedPrice'
import Input from '../../../../../../components/Input'
import LinkButton from '../../../../../../components/LinkButton'
import Text from '../../../../../../components/Text'
import {useCallable} from '../../../../../../services/firebase'
import {classList} from '../../../../../../services/util'
import OrderTracking from '../OrderTracking'
import './styles.scss'

export default function OrderPipelineStep({
  assignees, customerAddress, isAdmin, isManager, isAssigned, step,
  onBuyShippingLabel, onUpdateStep,
}) {
  const originalStep = {
    cancelled: step.cancelled || false,
    payout: step.payout || 0,
    shippingDays: step.shippingDays || 0,
    tracking: step.tracking || {eta: '', number: '', url: ''},
  }
  const shippo = step.shippo || {}
  const shipmentParams =
    useMemo(() => ({id: shippo.shipment}), [shippo.shipment])
  const transactionParams =
    useMemo(() => ({id: shippo.transaction}), [shippo.transaction])
  const [expanded, setExpanded] = useState(false)
  const [updatedStep, setUpdatedStep] = useState(originalStep)
  const [selectedRate, setSelectedRate] = useState(null)
  const [shippingRates, setShippingRates] = useState([])
  const {data: rateData} = useCallable(
    'retrieveShippingRatesV2',
    shipmentParams,
    !isAdmin || !shippo.shipment || !!shippo.transaction,
  )
  const {data: trackingData} = useCallable(
    'retrieveShippingLabelV2',
    transactionParams,
    !shippo.shipment || !shippo.transaction,
  )
  useEffect(() => {
    if (!rateData) return
    setShippingRates(rateData.sort((a, b) => {
      const providerScore = (provider) => {
        switch (provider) {
          case 'USPS': return 0
          case 'FedEx': return 0.25
          case 'UPS': return 0.5
          default: return 1
        }
      }
      const amountScore = (amount) => parseFloat(amount) / 11
      const daysScore = (days) => parseFloat(days) / 3
      const aScore = providerScore(a.provider) + amountScore(a.amount) + daysScore(a.estimatedDays)
      const bScore = providerScore(b.provider) + amountScore(b.amount) + daysScore(b.estimatedDays)
      return aScore - bScore
    }))
  }, [rateData])
  const tracking = !trackingData ? step.tracking : {
    eta: trackingData.eta,
    number: trackingData.trackingNumber,
    status: trackingData.trackingStatus,
    url: trackingData.trackingUrl,
  }
  return <div className="OrderPipelineStep">
    {!tracking ? null : <>
      <OrderTracking
        number={tracking.number}
        url={tracking.url}
        status={tracking.status} />
      {!tracking.eta ? null : <Text
        styleVariant="body2">
        Estimated {moment.utc(tracking.eta).format('MMM D')}
      </Text>}
    </>}
    <div className="OrderPipelineStep__addressInfo">
      <div className="OrderPipelineStep__address">
        <Text styleVariant="heading5">From</Text>
        <>{computeAddress(step.from, assignees, customerAddress)}</>
      </div>
      <div className="OrderPipelineStep__address">
        <Text styleVariant="heading5">To</Text>
        <>{computeAddress(step.to, assignees, customerAddress)}</>
      </div>
    </div>
    {!isAdmin && !isManager && !isAssigned ? null : <div className="OrderPipelineStep__assigneeInfo">
      <div className="OrderPipelineStep__stats">
        <Text styleVariant="heading4">
          {isAssigned ? 'Your payout' : 'Payout'}
        </Text>
        <div className="OrderPipelineStep__statLine">
          <FormattedPrice>{step.payout}</FormattedPrice>
          {!!step.shippingDays && <Text
            className={classList([
              'OrderPipelineStep__statExtra',
              step.shippingDays < 2 ? 'OrderPipelineStep__statExtra--good' : null,
              step.shippingDays > 3 ? 'OrderPipelineStep__statExtra--bad' : null,
            ])}
            styleVariant="body2">
            • {step.shippingDays} day{(step.shippingDays ?? 0) === 1 ? '' : 's'} to ship
          </Text>}
          {!!step.cancelled && <Text
            className="OrderPipelineStep__statExtra OrderPipelineStep__statExtra--bad"
            styleVariant="body2">
            • Cancelled
          </Text>}
        </div>
      </div>
      {trackingData && trackingData.label && <Button
        styleVariant={isAssigned ? 'secondary' : 'tertiary'}
        href={trackingData.label}
        target="_blank"
        slim>
        Print label
      </Button>}
    </div>}
    {isAdmin && <>
      <LinkButton
        className="OrderPipelineStep__updateStep"
        onClick={() => setExpanded(!expanded)}>
        Update step
      </LinkButton>
      {expanded && <>
        {shippingRates.length > 0 && !shippo.transaction && <>
          <Dropdown
            className="OrderPipelineStep__input"
            selectedClass="OrderPipelineStep__selectedRate"
            key={`dropdown_${shippo.shipment}`}
            label="Shipping"
            onSelected={(value) => setSelectedRate(value)}>
            {shippingRates.map(rate => <DropdownItem
              className="OrderPipelineStep__rateItem"
              key={`item-${rate.id}`}
              value={rate.id}
              selected={rate.id === selectedRate}>
              <div className="OrderPipelineStep__rate">
                <Text styleVariant="body3">
                  {rate.provider} {rate.serviceLevel}
                </Text>
                <Text styleVariant="body2">
                  <b>${rate.amount}</b> for {rate.estimatedDays} days
                </Text>
              </div>
            </DropdownItem>)}
          </Dropdown>
          <div className="OrderPipelineStep__submit">
            <Button
              onClick={() => onBuyShippingLabel(selectedRate)}
              slim>
              Buy label
            </Button>
          </div>
        </>}
        {!shippo.transaction && ['Number', 'URL', 'eta'].map(label => <Input
          key={`Input_${label}`}
          className="OrderPipelineStep__input"
          label={label}
          value={updatedStep.tracking[label.toLowerCase()]}
          onChange={(value) => setUpdatedStep(prev => ({
            ...prev,
            tracking: {
              ...prev.tracking,
              [label.toLowerCase()]: value,
            },
          }))} />)}
        {[
          {label: 'Payout', field: 'payout'},
          {label: 'Shipping days', field: 'shippingDays'},
        ].map(({label, field}) => <Input
          key={`Input_${field}`}
          className="OrderPipelineStep__input"
          label={label}
          value={updatedStep[field]}
          onChange={(value) => setUpdatedStep(prev => ({
            ...prev,
            [field]: isNaN(parseInt(value)) ? null : parseInt(value),
          }))} />)}
        <Checkbox
          className="OrderPipelineStep__input"
          label="Cancelled?"
          checked={updatedStep.cancelled}
          onToggle={(value) => setUpdatedStep(prev => ({
            ...prev,
            cancelled: !value,
          }))} />
        <div className="OrderPipelineStep__submit">
          <Button
            styleVariant="secondary"
            disabled={lodash.isEqual(originalStep, updatedStep)}
            onClick={() => onUpdateStep(updatedStep)}
            slim>
            Update
          </Button>
        </div>
      </>}
    </>}
  </div>
}

const computeAddress = (id, assignees, customerAddress) => {
  const address = id === 'customer' ? customerAddress : assignees[id]
  return [
    address.name,
    address.company,
    address.address1,
    address.address2,
    `${address.city}, ${address.region || address.provinceCode} ${address.zip}`,
  ].filter(it => !!it).map((line, index) => <Text
    key={`${id}_${index}`}
    styleVariant="body3">
    {line}
  </Text>)
}
