import React, {useEffect, useState} from 'react'
import {useContentful} from 'react-contentful'
import Dropdown, {DropdownItem} from '../../../../../../components/Dropdown'
import Text from '../../../../../../components/Text'
import {ContentModel} from '../../../../../../services/contentful'
import {findTagValue} from '../../../../../../services/product'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function ProductCustomizer({className, tags, onChange}) {
  const category = findTagValue(tags, 'category')
  const configurator = findTagValue(tags, 'configurator')
  const [customizers, setCustomizers] = useState([])
  const {error, data} = useContentful({
    contentType: 'configurator',
    query: {'fields.category[exists]': true},
  })
  useEffect(() => {
    if (!data || data.items.length === 0) return
    const customizers = data.items.map(item => ContentModel(item))
      .filter(customizer => customizer.category === category || customizer.category === 'all')
      .sort((a, b) => {
        if (a.tag === 'none' || b.tag === 'new') return -1
        if (a.tag === 'new' || b.tag === 'none') return 1
        return a.title.localeCompare(b.title)
      })
    setCustomizers(customizers)
  }, [data, category, configurator])
  if (error) console.log(error)
  if (customizers.length === 0) return null
  return <div className={classList(['ProductCustomizer', className])}>
    <Dropdown
      label="Customization"
      onSelected={(value) => onChange([
        ...(tags.filter(tag => !tag.includes('configurator'))),
        `configurator-${value}`,
      ].sort())}>
      {customizers.map((customizer) => <DropdownItem
        key={`customizer-${customizer.tag}`}
        value={customizer.tag}
        selected={customizer.tag === configurator}>
        {customizer.title}
      </DropdownItem>)}
    </Dropdown>
    <Text className="ProductCustomizer__description" styleVariant="body2">
      {(customizers.find(customizer => customizer.tag === configurator) || {}).description}
    </Text>
  </div>
}
