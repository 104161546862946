import React from 'react'
import {Link} from 'react-router-dom'
import './styles.scss'

export default function Button({className, children, styleVariant, slim, ...props}) {
  const ButtonComponent = props.to ? Link : (props.href ? 'a' : 'button')
  return <ButtonComponent
    className={[
      'Button',
      `Button--${styleVariant || 'primary'}`,
      !slim ? null : 'Button--slim',
      className,
    ].filter(it => !!it).join(' ')}
    {...props}>
    {children}
  </ButtonComponent>
}
