import React from 'react'
import Text from '../../../../../../components/Text'
import './styles.scss'

export default function ProductInventory({className, totalInventory, tracksInventory}) {
  const count = totalInventory || 0
  return <Text
    className={[
      'ProductInventory',
      !tracksInventory ? 'ProductInventory--disabled' : null,
      className,
    ].filter(it => !!it).join(' ')}
    styleVariant="body3">
    {!tracksInventory ? 'Inventory not tracked.' : <span>
      <span className={[
        'ProductInventory__count',
        !count ? 'ProductInventory__count--zero' : null,
      ].filter(it => !!it).join(' ')}>{count}</span> in stock.
    </span>}
  </Text>
}
