import React from 'react'
import {Link} from 'react-router-dom'
import {classList} from '../../services/util'
import './styles.scss'

export default function LinkButton({className, children, ...props}) {
  const ButtonComponent = props.to ? Link : (props.href ? 'a' : 'button')
  return <ButtonComponent
    className={classList(['LinkButton', className])}
    {...props}>
    {children}
  </ButtonComponent>
}
