import React from 'react'
import {classList} from '../../services/util'
import AppImage from '../AppImage'
import './styles.scss'

export default function ImageSelector({
  className, styleVariant, images, featuredIndex,
  renderImage, onClickImage, onEnterImage, onLeaveImage
}) {
  return <div className={classList(['ImageSelector', className])}>
    <div className="ImageSelector__featured">
      <AppImage
        key={images[featuredIndex]}
        className={classList([
          'ImageSelector__featuredImage',
          styleVariant !== 'square' ? null : 'ImageSelector__featuredImage--square',
        ])}
        src={images[featuredIndex]}
        radius={24}
        bordered
        imageFit={`cover`} />
    </div>
    {images.length > 1 ? <div className="ImageSelector__gallery">
      {images.map((image, index) => {
        const child = <AppImage
          key={image}
          className={[
            'ImageSelector__galleryImage',
            featuredIndex === index ? 'ImageSelector__galleryImage--clicked' : null,
          ].filter(it => !!it).join(' ')}
          src={image}
          size={64}
          radius={12}
          imageFit={`cover`}
          onClick={() => { if (onClickImage) onClickImage(index) }}
          onMouseEnter={() => { if (onEnterImage) onEnterImage(index) }}
          onMouseLeave={() => { if (onLeaveImage) onLeaveImage(index) }} />
        return !renderImage ? child : renderImage(child)
      })}
    </div> : <div className="ImageSelector__spacer" />}
  </div>
}
