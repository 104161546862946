import React from 'react'
import Text from '../../../../components/Text'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function DashError({className}) {
  return <div className={classList(['DashError', className])}>
    <Text styleVariant="heading3">There's an issue with your account.</Text>
    <Text className="DashError__contact">
      Please contact <a href="mailto:support@stringtheory.us">support@stringtheory.us</a>
    </Text>
  </div>
}
