import React, {createRef, useEffect} from 'react'
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {classList} from '../../services/util'
import './styles.scss'

export default function Drawer({className, children, open, position='left', onOverlayClick}) {
  const drawerRef = createRef()
  useEffect(() => {
    if (open) disableBodyScroll(drawerRef.current)
    else enableBodyScroll(drawerRef.current)
    return () => clearAllBodyScrollLocks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  return <div
    ref={drawerRef}
    className={classList([
      'Drawer',
      !open ? 'Drawer--closed' : null,
      position !== 'left' ? 'Drawer--right' : null,
    ])}>
    <div className={classList([
      'Drawer__content',
      className,
    ])}>{children}</div>
    <div className="Drawer__overlay" onClick={() => {if (onOverlayClick) onOverlayClick()}} />
  </div>
}
