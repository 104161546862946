import React, {useMemo} from 'react'
import {Link} from 'react-router-dom'
import {computeSubtitle, isPortrait} from '../../services/product'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import AppImage from '../AppImage'
import FormattedPrice from '../FormattedPrice'
import ProductHighlight from '../ProductHighlight'
import Text from '../Text'
import './styles.scss'

export default function ProductItem({
  availableForSale, image, title, price, comparePrice, maxPrice, tags, link, reviewCount, reviewTotal,
}) {
  const isSoldOut = availableForSale !== 'true'
  const tagsArr = tags.split(',')
  let rating = useMemo(
    () => ((parseInt(reviewTotal) || 0) / (parseInt(reviewCount) || 1)),
    [reviewCount, reviewTotal],
  )
  const subtitle = computeSubtitle(tagsArr)
  return <Link className="ProductItem" to={link}>
    <AppImage
      className={classList([
        'ProductItem__image',
        !isSoldOut ? null : 'ProductItem__image--soldOut',
        isPortrait(tagsArr) ? null : 'ProductItem__image--square',
      ])}
      src={image}
      radius={12}
      bordered={true}
      imageFit={'cover'} />
    <ProductHighlight
      className="ProductItem__highlight"
      isSoldOut={isSoldOut}
      price={price}
      comparePrice={comparePrice}
      tags={tagsArr} />
    <Text className="ProductItem__titleLine">
      <span className="ProductItem__title">{title}</span>
      {rating > 0 && <span className="ProductItem__rating">
        <AppIcon  className="ProductItem__ratingStar" icon="star" />
        {rating.toFixed(1)}
      </span>}
    </Text>
    {!subtitle ? null : <Text className="ProductItem__subtitle" styleVariant="body2">{subtitle}</Text>}
    <Text
      className="ProductItem__price"
      styleVariant="heading3">
      {price === maxPrice ? null : <span className="ProductItem__priceFrom">From</span>}
      <FormattedPrice compareAt={comparePrice}>{price}</FormattedPrice>
    </Text>
  </Link>
}
