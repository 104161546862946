/* eslint-disable */
import React, {useEffect, useRef} from 'react'
import {useMediaQuery} from 'react-responsive/src'
import {useHistory} from 'react-router'
import {SnapItem, SnapList, useScroll, useVisibleElements} from 'react-snaplist-carousel'
import {useElementSize} from 'usehooks-ts'
import {classList} from '../../services/util'
import IconButton from '../IconButton'
import LinkButton from '../LinkButton'
import Text from '../Text'
import './styles.scss'

export default function Carousel({
  className, children, title, subtitle, link,
  width, maxWidth, margin, columns, spacing, align, bleed,
}) {
  const sm = useMediaQuery({maxWidth: 720})
  const md = useMediaQuery({maxWidth: 1080})
  const history = useHistory()
  const listRef = useRef(null)
  // noinspection JSCheckFunctionSignatures
  const visible = useVisibleElements(
    {debounce: 10, ref: listRef},
    ([element]) => element,
  );
  // noinspection JSCheckFunctionSignatures
  const goToItem = useScroll({ref: listRef})
  const [carouselRef, {width: computedWidth}] = useElementSize()
  useEffect(() => goToItem(0), [sm, md])
  const size = sm ? 'sm' : (md ? 'md' : 'lg')
  const listWidth = (width || {})[size] || '100vw'
  const listMargin = (margin || {})[size] || 0
  const listBleed = (bleed || {})[size] || 0
  const defaultColumns = sm ? 1 : (md ? 2 : 3)
  const computedColumns = (columns || {})[size] || defaultColumns
  const defaultSpacing = sm ? 16 : 24
  const resultSize = (children ?? []).length
  return <section
    ref={carouselRef}
    className={classList(['Carousel', className])}
    style={{
      width: listWidth,
      maxWidth: (maxWidth || {})[size] || listWidth,
      margin: `0 -${listMargin}px`,
    }}>
    <div
      className="Carousel__header"
      style={{padding: `0 ${listMargin}px`}}>
      <div className="Carousel__titles">
        {!title ? null : <Text
          className="Carousel__title"
          styleVariant="heading2">
          {title}
        </Text>}
        {!subtitle ? null : <Text className="Carousel__title">{subtitle}</Text>}
      </div>
      {resultSize <= computedColumns ? null : <div className="Carousel__buttons">
        {!link ? null : <LinkButton
          className="Carousel__showButton"
          to={link}>
          Show ({resultSize > 99 ? '99+' : resultSize})
        </LinkButton>}
        {sm ? null : <>
          <IconButton
            className="Carousel__arrowButton"
            icon="chevron-left"
            onClick={() => goToItem(visible - (visible % computedColumns || computedColumns))} />
          <IconButton
            className="Carousel__arrowButton"
            icon="chevron-right"
            onClick={() => goToItem(visible + (computedColumns - visible % computedColumns))} />
        </>}
      </div>}
    </div>
    <div className="Carousel__listWrapper">
      <SnapList
        ref={listRef}
        className="Carousel__list"
        direction="horizontal"
        disableScroll={!sm}>
        {(children || []).map((child, index) => <CarouselItem
          {...child.props}
          key={child.key}
          index={index}
          total={children.length}
          width={computedWidth}
          margin={listMargin}
          columns={computedColumns}
          spacing={(spacing || {})[size] || defaultSpacing}
          align={align || 'start'}
          bleed={listBleed}>
          {child}
        </CarouselItem>)}
      </SnapList>
      {listBleed === 0 ? null : ['left', 'right'].map(side => <div
        key={`bleed ${side}`}
        className={classList([
          'Carousel__bleed',
          `Carousel__bleed--${side}`,
        ])}
        style={{width: listBleed}} />)}
    </div>
  </section>
}

const CarouselItem = ({children, index, total, width, margin, columns, spacing, align, bleed, ...props}) => <SnapItem
  snapAlign={align}>
  <div
    className="Carousel__item"
    style={{
      width: `${(width - margin - columns * spacing) / (columns + 0.5)}px`,
      marginLeft: index === 0 ? Math.max(margin, bleed) : spacing,
      marginRight: index === (total - 1) ? Math.max(margin, bleed) : 0,
    }}
    {...props}>
    {children}
  </div>
</SnapItem>
