import React from 'react'
import AppIcon from '../AppIcon'
import {classList} from '../../services/util'
import './styles.scss'

export default function IconButton({className, icon, disabled, onClick}) {
  return <button
    className={classList(['IconButton', className])}
    disabled={disabled}
    onClick={onClick}>
    <AppIcon
      className="IconButton__icon"
      icon={icon} />
  </button>
}
