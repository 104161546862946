import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {ContentfulClient, ContentfulProvider, useContentful} from 'react-contentful'
import {useFirebase} from './firebase'

const client = new ContentfulClient({
  space: 'g7kafqxp569n',
  accessToken: 'g1FMigzLYXCOU1wqqlcHvANatItWXFW45FNe5h_Ty_U'
})

export default function ContentfulService({children}) {
  return <ContentfulProvider client={client}>
    <TheoristProvider>{children}</TheoristProvider>
  </ContentfulProvider>
}

const TheoristContext = createContext({
  theorist: null,
  updateTheorist: () => {},
})

export const useTheorist = () => useContext(TheoristContext)

export const CheckoutConsumer = TheoristContext.Consumer

function TheoristProvider({children}) {
  const {loading, theorist: firebaseTheorist} = useFirebase()
  const [theoristHandle, setTheoristHandle] = useState(sessionStorage.getItem('theorist'))
  useEffect(() => sessionStorage.setItem('theorist', theoristHandle), [theoristHandle])
  const {data, error} = useContentful({
    skip: !theoristHandle,
    contentType: 'theorist',
    query: {'fields.theoristHandle': theoristHandle},
  })
  useEffect(() => {
    if (loading) return
    setTheoristHandle((firebaseTheorist || {}).handle || null)
  }, [loading, firebaseTheorist])
  if (error) console.log(`Failed to fetch theorist: ${error}`)
  // noinspection JSValidateTypes
  return <TheoristContext.Provider value={{
    theorist: !data || data.items.length < 1 ? null : ContentModel(data.items[0]),
    updateTheorist: (handle) => setTheoristHandle(handle || null),
  }}>{children}</TheoristContext.Provider>
}

export function ContentModel(data) {
  return {
    ...data.fields,
    id: data.sys.id,
    ...buildInnerModels(data.fields),
    ...buildInnerModelArrays(data.fields),
  }
}

function buildInnerModels(fields) {
  return Object.keys(fields)
    .filter(key => fields[key].sys)
    .map(key => {
      return {[key]: ContentModel(fields[key])}
    })
    .reduce((prev, curr) => {
      return {...prev, ...curr}
    }, {})
}

function buildInnerModelArrays(fields) {
  return Object.keys(fields)
    .filter(key => fields[key] instanceof Array && fields[key][0] && fields[key][0].sys)
    .map(key => {
      return {[key]: fields[key].map(data => ContentModel(data))}
    })
    .reduce((prev, curr) => {
      return {...prev, ...curr}
    }, {})
}

export const useProductTheorist = (product) => {
  const [theorist, setTheorist] = useState(null)
  const vendor = (product || {}).vendor
  const {data} = useContentful({
    contentType: 'theorist',
    query: {
      'fields.name[match]': vendor,
      'fields.tags[nin]': 'hidden',
      limit: 1,
    },
    skip: !vendor,
  })
  useEffect(() => {
    if (!data || data.items.length === 0) {
      setTheorist(null)
      return
    }
    setTheorist(ContentModel(data.items[0]))
  }, [data])
  return theorist
}

export const useContentfulWithPagination = ({contentType, query, skip, keyArgs}) => {
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState(null)
  useEffect(() => {
    setOffset(0)
    setData(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, (keyArgs || []).map(key => query[key]))
  let {loading, error, data: queryData} = useContentful({
    skip: !!skip,
    contentType: contentType,
    query: {
      ...query,
      skip: offset,
    }
  })
  useEffect(() => {
    if (!queryData) return
    setData(prev => !prev ? queryData : {
      ...prev,
      ...queryData,
      items: [...(prev.items || []), ...(queryData.items || [])],
    })
  }, [queryData])
  const fetchMore = useCallback((after) => setOffset(after), [])
  return {
    loading,
    error,
    data,
    fetchMore,
  }
}

// Future Apollo Client Implementation?
// -------
// import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client'
// import {setContext} from '@apollo/client/link/context'
//
// const spaceId = 'g7kafqxp569n'
// const accessToken = 'g1FMigzLYXCOU1wqqlcHvANatItWXFW45FNe5h_Ty_U'
//
// const httpLink = createHttpLink({
//   uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}`,
// })
//
// const authLink = setContext((_, {headers}) => {
//   return {
//     headers: {
//       ...headers,
//       authorization: `Bearer ${accessToken}`,
//     }
//   }
// })
//
// export const contentfulClient = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache(),
// })
