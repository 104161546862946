import React, {useState, useEffect} from 'react';
import {classList} from '../../services/util';
import AppIcon from '../AppIcon';
import Text from '../Text';
import './styles.scss';

export default function Dropdown({
  className, selectedClass, children, label, labelVariant = 'label3', onSelected,
}) {
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const childrenArray = React.Children.toArray(children);
    if (childrenArray.length > 0) {
      const initialSelected = childrenArray.find((child) => child.props.selected)
      handleSelected(initialSelected || childrenArray[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelected = (child) => {
    setSelected(child)
    if (onSelected) onSelected(child.props.value)
  }

  const toggleOpen = () => setOpen(!open)

  return <div className={classList(['Dropdown', className])}>
    <button
      className={classList(['Dropdown__selected', selectedClass])}
      onClick={toggleOpen}>
      <div className="Dropdown__selectedLeft">
        <Text className="Dropdown__label" styleVariant={labelVariant}>
          {label}
        </Text>
        {selected && selected.props.children}
      </div>
      <AppIcon className={`Dropdown__arrow ${open ? 'Dropdown__arrow--open' : ''}`} icon="chevron-down" />
    </button>
    <div className={`Dropdown__overlay ${open ? '' : 'Dropdown__overlay--closed'}`} onClick={toggleOpen} />
    <div className={`Dropdown__items ${open ? '' : 'Dropdown__items--closed'}`}>
      {React.Children.map(children, (child) => <DropdownItem
        {...child.props}
        selected={selected && selected.props.value === child.props.value}
        onClick={() => {
          handleSelected(child);
          toggleOpen();
          if (child.props.onClick) child.props.onClick();
        }}/>)}
    </div>
  </div>
}

export const DropdownItem = ({className, selected, ...props}) => <button
  className={classList([
    'DropdownItem',
    selected && 'DropdownItem--selected',
    className,
  ])}
  {...props} />
