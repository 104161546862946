import React, {useCallback} from 'react'
import Checkbox from '../../../../../../components/Checkbox'
import Input from '../../../../../../components/Input'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function ProductInventoryEditor({
  className, isAdmin, tracksInventory, theoristQuantity, fulfillmentQuantity, onDemand, onChange,
}) {
  const updateInventory = useCallback((key, value) => {
    if (!onChange) return
    const inventory = {
      tracksInventory,
      theoristQuantity,
      fulfillmentQuantity,
      onDemand,
    }
    if (key === 'tracksInventory' || key === 'onDemand') onChange({...inventory, [key]: value})
    else if (!value) onChange({...inventory, [key]: ''})
    else {
      const wholeNumber = parseInt(value)
      if (!isNaN(wholeNumber)) onChange({...inventory, [key]: wholeNumber})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentQuantity, theoristQuantity, tracksInventory])
  return <div className={classList(['ProductInventoryEditor', className])}>
    <Text styleVariant="heading3">Inventory</Text>
    <Checkbox
      className="ProductInventoryEditor__checkbox"
      checked={tracksInventory}
      onToggle={(checked) => updateInventory('tracksInventory', !checked)}
      disabled={!isAdmin && fulfillmentQuantity > 0}
      label="Track quantity" />
    <Input
      className="ProductInventoryEditor__input"
      label="Your quantity"
      disabled={!tracksInventory}
      value={!tracksInventory ? 'Not tracked' : theoristQuantity}
      onChange={(value) => updateInventory('theoristQuantity', value)} />
    <Input
      className="ProductInventoryEditor__input"
      label="Fulfilled by String Theory"
      disabled={!tracksInventory || !isAdmin}
      value={!tracksInventory ? 'Not tracked' : fulfillmentQuantity}
      onChange={(value) => updateInventory('fulfillmentQuantity', value)} />
    {!tracksInventory ? null : <>
      <Checkbox
        className="ProductInventoryEditor__checkbox"
        checked={onDemand}
        onToggle={(checked) => updateInventory('onDemand', !checked)}
        label="Sell on demand" />
      <Text styleVariant="body2">Continue selling when out of stock and recreate this product on demand.</Text>
    </>}
  </div>
}
