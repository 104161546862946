import React from 'react'
import {useHistory, useLocation} from 'react-router'
import Pill from '../../../../components/Pill'
import {decodeParams, encodeParams} from '../../../../services/url'
import './styles.scss'

export default function ShopSimpleFilters({filters}) {
  const history = useHistory()
  const {pathname, search} = useLocation()
  const queryParams = decodeParams(search) || {}
  return (filters || []).length > 0 ? <div className="ShopSimpleFilters">
    {filters.map(filter => <Pill
      key={filter.id}
      className="ShopSimpleFilters__pill"
      active={filter.tag === queryParams.filter}
      onClick={() => {
        if (queryParams.filter !== filter.tag) {
          const newQueryParams = {...queryParams, filter: filter.tag}
          history.replace(`${pathname}${encodeParams(newQueryParams)}`)
        } else {
          const {filter, ...newQueryParams} = queryParams
          history.replace(`${pathname}${encodeParams(newQueryParams)}`)
        }
      }}>{filter.title}</Pill>)}
  </div> : null
}
