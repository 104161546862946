import React from 'react'
import ColorOption, {ColorGroup} from '../ColorOption'
import Text from '../Text'
import {useSelectedOptions} from '../../services/product'
import {classList} from '../../services/util'
import './styles.scss'

export default function ProductOptions({className, label, options, variants, selected, onSelect}) {
  const {selectedOptions, onSelectOption} = useSelectedOptions(options, variants, selected, onSelect)
  return <div className={classList(['ProductOptions', className])}>
    {options.map((option) => {
      const computeClassList = (baseClass, value, index) => classList([
        baseClass,
        selectedOptions[option.name] !== value ? null : `${baseClass}--selected`,
        !option.availability[index] ? `${baseClass}--disabled` : null
      ])
      let optionGroupElement
      switch (option.name) {
        case 'Color':
          optionGroupElement = <ColorGroup label={selectedOptions[option.name]}>
            {option.values.map((value, index) => <ColorOption
              key={`${option.name} ${value}`}
              className={classList([
                'ProductOptions__color',
                !option.availability[index] ? 'ProductOptions__color--disabled' : null,
              ])}
              color={value}
              selected={selectedOptions[option.name] === value}
              onClick={() => onSelectOption(option, index)} />)}
          </ColorGroup>
          break
        default:
          const longestValue = [...option.values].sort((a, b) => a.length - b.length).pop()
          const itemWidth = longestValue.length * 8 + 32
          optionGroupElement = option.values.map((value, index) => <button
            className={computeClassList('ProductOptions__item', value, index)}
            key={value}
            style={{width: itemWidth}}
            onClick={() => onSelectOption(option, index)}>
            {value}
          </button>)
      }
      return <div
        className="ProductOptions__select"
        key={`${option.name}_${selectedOptions[option.name]}`}>
        <div className="ProductOptions__label">
          {label && <Text styleVariant="body3">{label} |</Text>}
          <Text styleVariant="body2">{option.name}</Text>
        </div>
        {optionGroupElement}
      </div>
    })}
  </div>
}
