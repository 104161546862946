import React, {useEffect, useState} from 'react'
import RichTextEditor from 'react-rte'
import {classList} from '../../services/util'
import './styles.scss'

export default function HtmlTextEditor({className, initialValue, onChange}) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue())
  useEffect(() => {
    setValue(!initialValue ? RichTextEditor.createEmptyValue()
      : RichTextEditor.createValueFromString(initialValue, 'html'))
  }, [initialValue])
  return <div className={classList(['HtmlTextEditor', className])}>
    <RichTextEditor
      value={value}
      toolbarConfig={{
        display: [
          'INLINE_STYLE_BUTTONS',
          'BLOCK_TYPE_BUTTONS',
          'LINK_BUTTONS',
        ],
        INLINE_STYLE_BUTTONS: [
          {label: 'Bold', style: 'BOLD'},
          {label: 'Italic', style: 'ITALIC'},
          {label: 'Underline', style: 'UNDERLINE'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ],
      }}
      onChange={(value) => {
        setValue(value)
        if (onChange) {
          // noinspection JSCheckFunctionSignatures
          onChange(value.toString('html'))
        }
      }} />
  </div>
}
