import React, {useMemo} from 'react'
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import parse from 'html-react-parser'
import moment from 'moment'
import AppIcon from '../../../../components/AppIcon'
import RichText from '../../../../components/RichText'
import Text from '../../../../components/Text'
import './styles.scss'

export default function TheoristInfo({
  name, location, elite, orderCount = 0, reviewTotal = 0, reviewCount = 0,
  stringingSince, pinnedNote,
}) {
  const experience = useMemo(
    () => moment().diff(moment().year(stringingSince ?? moment().year()), 'years'),
    [stringingSince],
  )
  const overallRating = useMemo(
    () => ((reviewTotal ?? 0) / (reviewCount || 1)).toFixed(1),
    [reviewCount, reviewTotal],
  )
  return <div className="TheoristInfo">
    <Text styleVariant="heading1">{name}</Text>
    <div className="TheoristInfo__stats">
      <div className="TheoristInfo__stat">
        {elite && <>
          <AppIcon className="TheoristInfo__elite" icon="award-filled" />
          <Text styleVariant="heading4">Top Theorist</Text>
          <Text className="TheoristInfo__divider" styleVariant="heading3">•</Text>
        </>}
        <AppIcon className="TheoristInfo__icon" icon="location" />
        <Text styleVariant="body3">{location}</Text>
      </div>
      <div className="TheoristInfo__stat">
        {(overallRating > 0 || orderCount > 0 || reviewCount > 0) && <Text
          className="TheoristInfo__divider" styleVariant="heading3">•</Text>}
        {overallRating > 0 && <>
          <Text styleVariant="heading4">{overallRating}</Text>
          <AppIcon className="TheoristInfo__star" icon="star" />
        </>}
        {(orderCount > 0 || reviewCount > 0) && <>
          <Text styleVariant="body2">
            {orderCount > reviewCount
              ? `${orderCount} sale${orderCount === 1 ? '' : 's'}`
              : `${reviewCount} review${reviewCount === 1 ? '' : 's'}`}
          </Text>
        </>}
        {experience > 0 && <>
          <Text className="TheoristInfo__divider" styleVariant="heading3">•</Text>
          <Text styleVariant="body2">{experience}+ years experience</Text>
        </>}
      </div>
    </div>
    {!pinnedNote ? null : <div className="TheoristInfo__pinnedNote">
      <div className="TheoristInfo__iconText">
        <AppIcon className="TheoristInfo__icon" icon="paperclip" />
        <Text styleVariant="heading3">Pinned note</Text>
      </div>
      <RichText>{parse(documentToHtmlString(pinnedNote))}</RichText>
    </div>}
  </div>
}
