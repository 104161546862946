import React from 'react'
import FormattedPrice from '../../../../../../components/FormattedPrice'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function PriceLine({className, children, prices, currency, extra, required, bold, negative}) {
  const computedPrice = (prices || []).reduce((acc, curr) => acc + curr, 0)
  return !required && !computedPrice ? null : <Text
    className={classList(['PriceLine', className])}
    styleVariant={!bold ? 'body3' : 'heading4'}>
    <span className="PriceLine__info">
      <span className="PriceLine__title">{children}</span>
      <span className="PriceLine__extra">{extra}</span>
    </span>
    <span>{!negative ? '' : '-'}<FormattedPrice
      currencyCode={currency}
      minimumFractionDigits={2}>
      {computedPrice}
    </FormattedPrice></span>
  </Text>
}
