import React, {useEffect, useState} from 'react'
import {useContentful} from 'react-contentful'
import Text from '../Text'
import LinkButton from '../LinkButton'
import ProfileCard from '../ProfileCard'
import TheoristLookup from '../TheoristLookup'
import {ContentModel} from '../../services/contentful'
import {classList} from '../../services/util'
import './styles.scss'

export default function TheoristSelector({className, initialTheorist, onSelect}) {
  const [mode, setMode] = useState(!initialTheorist ? 'automatic' : 'manual')
  const [theorist, setTheorist] = useState(initialTheorist)
  const [theoristData, setTheoristData] = useState(null)
  const {data} = useContentful({
    contentType: 'theorist',
    query: {
      'fields.name[match]': theorist,
      'fields.tags[nin]': 'hidden',
      limit: 1,
    },
    skip: !theorist,
  })
  useEffect(() => {
    if (!data || data.items.length === 0) {
      setTheoristData(null)
      return
    }
    setTheoristData(ContentModel(data.items[0]))
  }, [data])
  useEffect(() => {
    if (!onSelect) return
    onSelect(mode === 'manual' && theoristData ? theoristData.name : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, theoristData])
  return <div className={classList(['TheoristSelector', className])}>
    <div className="TheoristSelector__options">
      {[{
        title: 'Automatic',
        description: 'We’ll choose a professional in our network for fastest service.',
      }, {
        title: 'Manual',
        description: 'You choose the professional you want to work with.',
      }].map(({title, description}) => <button
        key={`TheoristSelector ${title}`}
        className={classList([
          'TheoristSelector__option',
          mode === title.toLowerCase() && 'TheoristSelector__option--selected',
        ])}
        onClick={() => setMode(title.toLowerCase())}>
        <Text styleVariant="heading3">{title}</Text>
        <Text styleVariant="body3">{description}</Text>
      </button>)}
    </div>
    {mode === 'manual' && <>
      {theoristData && <div className="TheoristSelector__manualTheorist">
        <ProfileCard
          className="TheoristSelector__profile"
          image={theoristData.profilePicture.file.url}
          title={theoristData.name}
          subtitle={theoristData.locationText}
          elite={theoristData?.metrics?.overall?.elite}
          orderCount={theoristData?.metrics?.overall?.orderCount}
          reviewCount={theoristData?.metrics?.overall?.reviewCount}
          reviewTotal={theoristData?.metrics?.overall?.reviewTotal}
          stringingSince={theoristData.stringingSince} />
        <LinkButton
          onClick={() => setTheorist(null)}>
          Change
        </LinkButton>
      </div>}
      {!theorist && <TheoristLookup
        onSelect={(result) => setTheorist(result.name)}
        excludeTag="hidden" />}
    </>}
  </div>
}
