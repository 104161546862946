import React, {useState} from 'react'
import AppIcon from '../../../../../../components/AppIcon'
import Dialog from '../../../../../../components/Dialog'
import LinkButton from '../../../../../../components/LinkButton'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function DashMetricCard({className, value, unit, unitType, label, criteria, achieved, explainer}) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)
  return <div className={classList(['DashMetricCard', className])}>
    <div className="DashMetricCard__header">
      <Text styleVariant="heading1">{value}</Text>
      {unitType === 'icon'
        ? <AppIcon className="DashMetricCard__unit" icon={unit}/>
        : <Text className="DashMetricCard__unit" styleVariant="heading3">{unit}</Text>}
      <div className="DashMetricCard__divider"/>
      <button
        className="DashMetricCard__help"
        onClick={() => openDialog()}>?</button>
    </div>
    <Text styleVariant="heading4">{label}</Text>
    <Text
      className="DashMetricCard__criteria"
      styleVariant="body5">
      Goal: {criteria}
    </Text>
    <div className="DashMetricCard__divider"/>
    <div className="DashMetricCard__status">
      <div className={classList([
        'DashMetricCard__statusIconCircle',
        !achieved ? null : 'DashMetricCard__statusIconCircle--achieved',
      ])}>
        <AppIcon
          className="DashMetricCard__statusIcon"
          icon={achieved ? 'check' : 'clear'}/>
      </div>
      <Text styleVariant="heading6">{achieved ? 'Achieved!' : 'Didn\'t meet'}</Text>
    </div>
    <Dialog
      title={label}
      open={dialogOpen}
      onDismiss={() => closeDialog()}>
      <Text styleVariant="body3">{explainer}</Text>
      <div className="DashMetricCards__dialogDismiss">
        <LinkButton onClick={() => closeDialog()}>Done</LinkButton>
      </div>
    </Dialog>
  </div>
}
