import React from 'react'
import {classList} from '../../services/util'
import Text from '../Text'
import './styles.scss'

export default function StatusIndicator({className, status}) {
  return <Text
    key={status}
    className={classList([
      'StatusIndicator',
      `StatusIndicator--${mapStatusColor(status)}`,
      className,
    ])}
    styleVariant="body3">
    {status.replace(/_/g, ' ')}
  </Text>
}

const mapStatusColor = (status) => {
  switch (status) {
    case 'active':
    case 'completed':
    case 'delivered':
    case 'fulfilled':
    case 'marked_as_fulfilled':
    case 'paid':
      return 'green'
    case 'in_transit':
    case 'out_for_delivery':
    case 'picked_up':
    case 'transit':
      return 'blue'
    case 'canceled':
    case 'label_voided':
    case 'partially_refunded':
    case 'refunded':
    case 'voided':
      return 'gray'
    case 'inactive':
    case 'restricted':
      return 'red'
    case 'attempted_delivery':
    case 'authorized':
    case 'confirmed':
    case 'expired':
    case 'failure':
    case 'in_progress':
    case 'on_hold':
    case 'label_printed':
    case 'label_purchased':
    case 'not_delivered':
    case 'partially_fulfilled':
    case 'partially_paid':
    case 'pending':
    case 'ready_for_pickup':
    case 'scheduled':
    case 'submitted':
    case 'unfulfilled':
    default:
      return 'yellow'
  }
}
