import React from 'react'
import Input from '../../../../components/Input'
import Text from '../../../../components/Text'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function DashPriceInput({className, label, value, onChange}) {
  return <div className={classList([
    'DashPriceInput',
    className
  ])}>
    <Input
      inputClass="DashPriceInput__input"
      placeholder={0}
      label={label}
      value={value}
      onChange={(value) => {
        if (onChange) {
          if (!value) onChange(0)
          const wholeNumber = parseInt(value)
          if (wholeNumber) onChange(wholeNumber)
        }
      }} />
    <Text className="DashPriceInput__currency">$</Text>
  </div>
}
