import {useEffect} from 'react'
import * as Scroll from 'react-scroll'

export const decodeParams = query => {
  return query ? (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      let [key, value] = param.split('=')
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
      return params
    }, {}) : {}
}

export const encodeParams = params => {
  return Object.keys(params)
    .filter(param => !!params[param])
    .map((param, index) => `${index === 0 ? '?' : ''}${param}=${encodeURIComponent(params[param])}`)
    .join('&')
}

export const useHashScroll = (hash, deps = [], offset = 0) => {
  useEffect(() => {
    if (hash) {
      const allDefined = deps.map(dep => !!dep).reduce((a, b) => a && b, true)
      if (!allDefined) return
      const timeout = setTimeout(() => {
        Scroll.scroller.scrollTo(hash.slice(1), {duration: 300, offset, smooth: true})
      }, 1000)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, ...deps])
}
