import React from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import DashOrderList from './scenes/DashOrderList'
import DashOrderDetail from './scenes/DashOrderDetail'

export default function DashOrders({user, theorist}) {
  const {path, url} = useRouteMatch()
  return <Switch>
    <Route exact path={path}>
      <DashOrderList user={user} theorist={theorist} />
    </Route>
    <Route path={`${path}/:id`}>
      <DashOrderDetail user={user} theorist={theorist} />
    </Route>
    <Route path="*">
      <Redirect to={url} />
    </Route>
  </Switch>
}
