import React from 'react'
import {usePlacesWidget} from 'react-google-autocomplete'
import {classList} from '../../services/util'
import Dropdown, {DropdownItem} from '../Dropdown'
import Input from '../Input'
import './styles.scss'

const countries = [
  {name: 'United States', code: 'US'},
  {name: 'Canada', code: 'CA'},
]

export default function AddressInput({className, data={}, error={}, onChange=() => {}}) {
  const {ref} = usePlacesWidget({
    apiKey: 'AIzaSyAE_Lhs6zeOKGiV7mUH9rhR4zFJouPvnf8',
    options: {
      componentRestrictions: {
        country: countries.map(country => country.code).filter(country => country === data.country),
      },
      types: ['address'],
    },
    onPlaceSelected: (place) => {
      const findComponent = type => place.address_components.find(it => it.types.includes(type))
        || {long_name: '', short_name: ''}
      // noinspection JSUnresolvedFunction
      onChange({
        streetAddress: `${findComponent('street_number').long_name} ${findComponent('route').long_name}`,
        city: findComponent('locality').long_name,
        region: findComponent('administrative_area_level_1').short_name,
        postalCode: findComponent('postal_code').long_name,
        country: findComponent('country').short_name,
        location: {
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng(),
        }
      })
    },
  })
  return <div className={classList(['AddressInput', className])}>
    <Dropdown
      className="AddressInput__input"
      key={`dropdown-${data.country}`}
      label="Country"
      onSelected={(value) => onChange({...data, country: value})}>
      {countries.map(country => <DropdownItem
        key={`item-${country.code}`}
        value={country.code}
        selected={country.code === data.country}>
        {country.name}
      </DropdownItem>)}
    </Dropdown>
    <div className="AddressInput__row">
      <Input
        className="AddressInput__input"
        placeholder="First name"
        value={data.firstName}
        error={error.firstName}
        onChange={(value) => onChange({...data, firstName: value})} />
      <Input
        className="AddressInput__input"
        placeholder="Last name"
        value={data.lastName}
        error={error.lastName}
        onChange={(value) => onChange({...data, lastName: value})} />
    </div>
    <Input
      inputRef={ref}
      className="AddressInput__input"
      placeholder="Address"
      value={data.streetAddress}
      error={error.streetAddress}
      onChange={(value) => onChange({...data, streetAddress: value})} />
    <Input
      className="AddressInput__input"
      placeholder="Apartment, suite, etc. (optional)"
      value={data.apartment}
      error={error.apartment}
      onChange={(value) => onChange({...data, apartment: value})} />
    <Input
      className="AddressInput__input"
      placeholder="City"
      value={data.city}
      error={error.city}
      onChange={(value) => onChange({...data, city: value})} />
    <div className="AddressInput__row">
      <Input
        className="AddressInput__input"
        placeholder={data.country === 'Canada' ? 'Province' : 'State'}
        value={data.region}
        error={error.region}
        onChange={(value) => onChange({...data, region: value})} />
      <Input
        className="AddressInput__input"
        placeholder={data.country === 'Canada' ? 'Postal code' : 'ZIP code'}
        value={data.postalCode}
        error={error.postalCode}
        onChange={(value) => onChange({...data, postalCode: value})} />
    </div>
    <Input
      className="SignUp__input"
      placeholder="Phone"
      value={data.phone}
      error={error.phone}
      onChange={(value) => onChange({...data, phone: value})} />
  </div>
}
