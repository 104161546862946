import React, {useEffect, useState} from 'react'
import {Redirect, useHistory, useLocation} from 'react-router-dom'
import Button from '../../components/Button'
import Dialog from '../../components/Dialog'
import Input from '../../components/Input'
import LinkButton from '../../components/LinkButton'
import {useLoading} from '../../components/LoadingWrapper'
import Text from '../../components/Text'
import {useFirebase} from '../../services/firebase'
import {decodeParams} from '../../services/url'
import Shell from '../Shell'
import './styles.scss'

export default function Login() {
  const history = useHistory()
  const {search} = useLocation()
  const {addLoad, removeLoad} = useLoading()
  const {loading, sendPasswordResetEmail, signIn, user} = useFirebase()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [showResetPassword, setShowResetPassword] = useState(false)
  useEffect(() => {
    if (loading) addLoad('login')
    else removeLoad('login')
    return () => removeLoad('login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  const {redirect} = decodeParams(search)
  if (!!user) return <Redirect to={redirect || '/'} />
  const login = () => {
    if (!email || !password) return
    addLoad('login')
    signIn(email, password).then(() => {
      history.replace(redirect || '/')
    }).catch(() => {
      setError('Invalid email or password.')
    }).finally(() => removeLoad('login'))
  }
  const resetPassword = () => {
    if (!email) return
    addLoad('login')
    sendPasswordResetEmail(email).catch((e) => {
      console.log(e.message)
    }).finally(() => {
      setShowResetPassword(false)
      removeLoad('login')
    })
  }
  return <Shell
    className="Login"
    title="Login">
    <div className="Login__box">
      <Text styleVariant="heading2">Welcome to String Theory</Text>
      <Input
        className="Login__input"
        value={email}
        label="Email"
        onChange={(value) => {
          setEmail(value)
          setError('')
        }}
        onSubmit={() => login()} />
      <Input
        className="Login__input"
        type="password"
        value={password}
        label="Password"
        onChange={(value) => {
          setPassword(value)
          setError('')
        }}
        onSubmit={() => login()} />
      <Button
        className="Login__button"
        onClick={() => login()}>Log in</Button>
      <LinkButton
        className="Login__forgotPassword"
        onClick={() => setShowResetPassword(true)}>
        Forgot password?
      </LinkButton>
      {!error ? null : <Text className="Login__error">{error}</Text>}
    </div>
    <Dialog
      title="Forgot password?"
      open={showResetPassword}
      onDismiss={() => setShowResetPassword(false)}>
      <Text>Enter the email address associated with your account to receive a link to reset your password.</Text>
      <Input
        className="Login__input"
        value={email}
        label="Email"
        onChange={(value) => {
          setEmail(value)
          setError('')
        }}
        onSubmit={() => resetPassword()} />
      <Button
        className="Login__button"
        onClick={() => resetPassword()}>
        Reset password
      </Button>
    </Dialog>
  </Shell>
}
