import React from 'react'
import {Link} from 'react-router-dom'
import AppImage from '../AppImage'
import Text from '../Text'
import './styles.scss'

export default function CategoryItem({image, title, link}) {
  const tokens = title.split(' ')
  const primary = tokens.length > 1 ? tokens[1] : tokens[0]
  const secondary = tokens.length > 1 ? tokens[0] : null
  return <Link className="CategoryItem" to={link}>
    <AppImage className="CategoryItem__image" src={image} radius={12} />
    <div className="CategoryItem__overlay" />
    <div className="CategoryItem__text">
      <Text styleVariant="heading3">{primary}</Text>
      <Text>{secondary}</Text>
    </div>
  </Link>
}
