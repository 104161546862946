import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import AddressInput from '../../components/AddressInput'
import AppImage from '../../components/AppImage'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import HtmlTextEditor from '../../components/HtmlTextEditor'
import Input from '../../components/Input'
import {useLoading} from '../../components/LoadingWrapper'
import Text from '../../components/Text'
import {callable, createUser, useFirebase} from '../../services/firebase'
import Shell from '../Shell'
import './styles.scss'
import introImage from './images/pockets-by-pros.jpg'

const skills = [
  {
    label: `Mesh`,
    value: 'mesh',
  },
  {
    label: `Traditional`,
    value: 'traditional',
  },
  {
    label: `Women's`,
    value: 'women',
  },
  {
    label: `Goalie`,
    value: 'goalie',
  },
  {
    label: `Dye`,
    value: 'dye',
  },
]

const emptyData = {
  email: '',
  password: '',
  handle: '',
  firstName: '',
  lastName: '',
  theoristName: '',
  country: 'US',
  streetAddress: '',
  apartment: '',
  city: '',
  region: '',
  postalCode: '',
  phone: '',
  shortBio: '',
  skills: [],
}

const noError = {
  email: null,
  password: null,
  handle: null,
  firstName: null,
  lastName: null,
  streetAddress: null,
  city: null,
  region: null,
  postalCode: null,
  phone: null,
}

export default function SignUp() {
  const history = useHistory()
  const [data, setData] = useState(emptyData)
  const [error, setError] = useState(noError)
  const [isValid, setValid] = useState(true)
  const {addLoad, removeLoad} = useLoading()
  const {syncTheorist} = useFirebase()
  const setLoading = (isLoading) => {
    if (isLoading) addLoad('signUp')
    else removeLoad('signUp')
  }
  const updateError = (key, value) => {
    setError(prevError => {
      return {
        ...prevError,
        [key]: value,
      }
    })
  }
  const updateData = (key, value) => {
    setData(prevData => {
      return {
        ...prevData,
        [key]: value,
      }
    })
    if (error[key]) updateError(key, null)
  }
  const validateRequired = (key) => {
    if (data[key]) return true
    updateError(key, 'This field cannot be left blank.')
    return false
  }
  const validatePhoneNumber = (phone) => {
    if (!/^\+[1-9]\d{1,14}$/.test(phone)) {
      updateError('phone', 'Phone must be in the format +1XXXXXXXXXX')
      return false
    }
    return true
  }
  const allTrue = (booleans) => booleans.reduce((curr, acc) => acc && curr, true)
  useEffect(() => setValid(allTrue(Object.keys(error).map(key => !error[key]))), [error])
  const banner = <>
    Are you already a Theorist? <Link className="SignUp__bannerLink" to="/dash">Log in</Link>
  </>
  return <Shell
    className="SignUp"
    title="Become a Theorist"
    banner={banner}>
    <Text styleVariant="heading1">Become a Theorist</Text>
    <div className="SignUp__intro">
      <div className="SignUp__introImage">
        <AppImage
          src={introImage}
          radius={12}
          imageFit={`cover`} />
      </div>
      <div className="SignUp__introText">
        <Text styleVariant="heading2">Welcome to String Theory!</Text>
        <p>
          String Theory is a lacrosse marketplace for custom stringing from professionals
          across the world. If you're an expert stringer, we want to work with you!
        </p>
        <p>As a theorist, you get:</p>
        <ul>
          <li>A free profile to offer custom stringing and list items for sale online</li>
          <li>Exclusive member discounts on materials and entry to team competitions</li>
          <li>Access to all communication channels within our growing theorist community</li>
        </ul>
        <p><b>For a limited time, members pay no transaction fees and we ship your orders for free.</b></p>
        <p>Fill out this form and we'll get back to you as soon as we can about your admission to the team.</p>
      </div>
    </div>
    <div className="SignUp__section">
      <div className="SignUp__block">
        <Text
          className="SignUp__blockHeading"
          styleVariant="heading3">
          Create your account
        </Text>
        <Input
          className="SignUp__input"
          label="Email"
          value={data.email}
          error={error.email}
          onChange={(value) => updateData('email', value)} />
        <Input
          className="SignUp__input"
          type="password"
          label="Password"
          value={data.password}
          error={error.password}
          onChange={(value) => updateData('password', value)} />
        <div className="SignUp__blockDivider" />
        <Input
          className="SignUp__input"
          label="Instagram handle"
          value={data.handle}
          error={error.handle}
          onChange={(value) => updateData('handle', value)} />
        <Text
          className="SignUp__blockDescription"
          styleVariant="body3">
          We'll look at your Instagram to verify your skills and pull images to populate your theorist page.
        </Text>
      </div>
      <div className="SignUp__block">
        <Text
          className="SignUp__blockHeading"
          styleVariant="heading3">
          Shipping address
        </Text>
        <AddressInput
          data={data}
          error={error}
          onChange={newData => Object.keys(newData).forEach(key => updateData(key, newData[key]))} />
        <Text
          className="SignUp__blockDescription"
          styleVariant="body3">
          This will be used as the return address on shipping labels created for you.
        </Text>
      </div>
    </div>
    <div className="SignUp__section">
      <div className="SignUp__block">
        <Text
          className="SignUp__blockHeading"
          styleVariant="heading3">
          Theorist name
        </Text>
        <Input
          className="SignUp__input"
          placeholder={`${data.firstName} ${data.lastName}`}
          value={data.theoristName}
          onChange={(value) => updateData('theoristName', value)} />
        <Text
          className="SignUp__blockDescription"
          styleVariant="body3">
          The name players will see when they visit your page. If left blank, we'll use your real name.
        </Text>
        <Text
          className="SignUp__blockHeading"
          styleVariant="heading3">
          Short bio
        </Text>
        <HtmlTextEditor onChange={(value) => updateData('shortBio', value)} />
      </div>
      <div className="SignUp__block">
        <Text
          className="SignUp__blockHeading"
          styleVariant="heading3">
          Your skills
        </Text>
        <Text
          className="SignUp__blockDescription"
          styleVariant="body3">
          These will be available to order from your page.
        </Text>
        {skills.map(skill => <Checkbox
          key={skill.value}
          className="SignUp__checkbox"
          label={skill.label}
          checked={data.skills.includes(skill.value)}
          onToggle={(checked) => {
            updateData('skills', (!checked
              ? [...data.skills, skill.value]
              : data.skills.filter(value => value !== skill.value)
            ).sort((a, b) => {
              const aRank = skills.findIndex(s => s.value === a)
              const bRank = skills.findIndex(s => s.value === b)
              return aRank - bRank
            }))
          }} />)}
      </div>
    </div>
    {isValid ? null : <Text className="SignUp__error">
      An error occurred. Please fix the errors above and try again.
    </Text>}
    <div className="SignUp__button">
      <Button onClick={async () => {
        setLoading(true)
        if (!allTrue(Object.keys(noError).map(key => {
          if (key === 'phone') return validatePhoneNumber(data.phone)
          return validateRequired(key)
        }))) {
          setLoading(false)
          return
        }
        let result = await callable('theoristExistsV2')({handle: data.handle})
        if (result.data) {
          updateError('handle', 'This handle is already in use. If this is a mistake, please contact support.')
          setLoading(false)
          return
        }
        result = await createUser(data.email, data.password)
        if (!result.data) {
          if (typeof result.error === 'object') {
            Object.keys(result.error).forEach(key => updateError(key, result.error[key]))
          } else {
            updateError('email', 'Something went wrong. Please contact support.')
          }
          setLoading(false)
          return
        }
        delete data.password
        try {
          await callable('createTheoristV2')({
            name: data.theoristName || `${data.firstName} ${data.lastName}`,
            owner: `${data.firstName} ${data.lastName}`,
            handle: data.handle,
            email: data.email,
            phone: data.phone,
            address: `${data.streetAddress}${!data.apartment ? '' : `, ${data.apartment}`}`,
            location: {
              ...(data.location || {}),
              text: `${data.city}, ${data.region}`,
              zip: `${data.postalCode}`,
            },
            tags: [
              `country-${data.country.toLowerCase()}`,
              `region-${data.region.toLowerCase()}`,
              ...data.skills.map(skill => `skill-${skill}`),
            ],
            about: data.shortBio,
            rawData: data,
          })
          syncTheorist({
            name: data.theoristName || `${data.firstName} ${data.lastName}`,
            handle: data.handle,
            pending: true,
          })
          history.push('/dash')
        } catch (e) {
          console.log(e.message)
          updateError('email', 'Something went wrong. Please contact support.')
        }
        setLoading(false)
      }}>Continue</Button>
    </div>
  </Shell>
}
