import React, {useEffect, useState} from 'react'
import {useContentful} from 'react-contentful'
import {useHistory, useLocation} from 'react-router'
import * as Scroll from 'react-scroll'
import {useSessionStorage} from 'usehooks-ts'
import ImageSelector from '../../components/ImageSelector'
import LoadingIndicator from '../../components/LoadingIndicator'
import Text from '../../components/Text'
import {ContentModel} from '../../services/contentful'
import {useDoc} from '../../services/firebase'
import {computeIntegerId, findTagValue} from '../../services/product'
import {resizeImage} from '../../services/shopify'
import {decodeParams} from '../../services/url'
import NotFound from '../NotFound'
import Shell from '../Shell'
import './styles.scss'
import DesignGuarantee from './components/DesignGuarantee'
import DesignNotes from './components/DesignNotes'
import DesignOrder from './scenes/DesignOrder'
import DesignReview from './scenes/DesignReview'
import DesignSection from './scenes/DesignSection'

export default function Design() {
  const history = useHistory()
  const {pathname, search} = useLocation()
  const {filter} = decodeParams(search)
  const [page, setPage] = useState(null)
  const [selectedHead, setSelectedHead] = useState(null)
  const [images, setImages] = useState({})
  const [imageIndex, setImageIndex] = useState(1)
  const {
    loading: contentfulLoading,
    fetched: contentfulFetched,
    error: contentfulError,
    data: contentfulData,
  } = useContentful({
    contentType: 'page',
    query: {'fields.link': '/design'},
  })
  const [customOrder, setCustomOrder] = useSessionStorage('custom', {})
  const filterTag = (customOrder || {}).filter || 'men'
  const headSelection = (customOrder || {}).head
  const {
    loading: headLoading,
    error: headError,
    data: headData,
  } = useDoc('products', computeIntegerId((headSelection || {}).productId), !headSelection)
  useEffect(() => {
    if (!contentfulData || contentfulData.items.length === 0) return
    const page = ContentModel(contentfulData.items[0])
    setPage(page)
    if (page.banner) {
      setImages(images => {
        return {
          ...images,
          [page.title]: page.banner.file.url,
        }
      })
    }
  }, [contentfulData])
  useEffect(() => {
    if (!filter || !page) return
    const newFilter = (page.filters.find(f => f.tag === filter) || {}).tag || 'men'
    setCustomOrder({filter: newFilter})
    history.replace(pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page])
  useEffect(() => {
    if (!headSelection || !headData) setSelectedHead(null)
    else setSelectedHead(headData.shopify)
  }, [headSelection, headData])
  useEffect(() => {
    if (customOrder && selectedHead) {
      if (customOrder.dye && customOrder.head) {
        const firstHead = selectedHead.variants[0].id
        if (customOrder.head.variantId !== firstHead) {
          setCustomOrder(customOrder => {
            return {
              ...customOrder,
              head: {
                ...customOrder.head,
                variantId: firstHead,
              }
            }
          })
        }
      }
      const headFilter = selectedHead.tags.find(tag => ['goalie', 'men', 'women'].includes(tag))
      if (headFilter && customOrder.filter !== headFilter) {
        setCustomOrder(customOrder => {
          return {
            ...customOrder,
            filter: headFilter,
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customOrder, selectedHead])
  const loading = contentfulLoading || headLoading
  const error = contentfulError || headError
  if (loading || !contentfulFetched) return <Shell className="Design">
    <LoadingIndicator />
  </Shell>
  if (error || !page) return <NotFound />
  const activeModules = page.modules.filter(mod => !(mod.tags || []).includes('hidden'))
  const imageModKeys = Object.keys(images).filter(it => !!images[it])
  return <Shell
    className="Design"
    title={page.title}
    noSticky showChat>
    <div className="Design__content">
      <div className="Design__images">
        <ImageSelector
          className="Design__imageSelector"
          images={imageModKeys.map(k => images[k])}
          featuredIndex={!imageModKeys[imageIndex] ? 0 : imageIndex}
          renderImage={(child) => {
            const mod = activeModules.find(mod => images[mod.title] === child.props.src)
            if (!mod) return child
            return <Scroll.Link
              key={`link ${mod.id}`}
              to={mod.title}
              offset={-40}
              duration={300}
              onSetActive={(to) => setImageIndex(imageModKeys.indexOf(to))}
              smooth spy>
              {child}
            </Scroll.Link>
          }} />
      </div>
      <div>
        <Text
          className="Design__heading"
          styleVariant="heading1">
          {page.title}
        </Text>
        <Text>{page.subtitle}</Text>
        <DesignReview className="Design__review" />
        <DesignGuarantee className="Design__guarantee" />
        {activeModules.map(mod => <Scroll.Element
          key={`element ${mod.id}`}
          name={mod.title}>
          <DesignSection
            module={mod}
            filters={page.filters}
            selectedHead={selectedHead}
            selectedFilter={filterTag}
            onSelectFilter={(tag) => setCustomOrder({...customOrder, filter: tag})}
            onSelectImage={(image) => setImages(images => {
              return {
                ...images,
                [mod.title]: !image ? null : resizeImage(image, 1080),
              }
            })} />
        </Scroll.Element>)}
        {!selectedHead && !customOrder.notes ? null : <DesignNotes
          className="Design__notes"
          value={customOrder.notes}
          onChange={(value) => setCustomOrder(customOrder => {
            return {
              ...customOrder,
              notes: value,
            }
          })}/>}
        <DesignOrder customizations={activeModules.map(mod => findTagValue(mod.tags, 'custom'))} />
      </div>
    </div>
  </Shell>
}
