import React from 'react'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import TopTheoristReward from '../../components/TopTheoristReward'
import './styles.scss'

export default function DashRewards({className}) {
  return <div className={classList(['DashRewards', className])}>
    <Text styleVariant="heading3">Top Theorist rewards</Text>
    <div className="DashRewards__row">
      {[{
        icon: 'gift',
        title: 'Annual bonus',
        description: 'Each time you maintain your Top Theorist status for a whole year, you get a $100 bonus.',
      }, {
        icon: 'sliders',
        title: 'Team filter',
        description: 'Stand out on our team page with a special filter for Top Theorists.',
      }, {
        icon: 'award',
        title: 'Top Theorist badge',
        description: 'This trusted symbol of great service will show up on your profile and listing pages.',
      }].map(reward => <TopTheoristReward
        className="DashRewards__item"
        key={`DashRewards ${reward.title}`}
        {...reward} />)}
    </div>
  </div>
}
