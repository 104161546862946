import React from 'react'
import ImageUploading from 'react-images-uploading'
import AppImage from '../../../../../../components/AppImage'
import IconButton from '../../../../../../components/IconButton'
import LinkButton from '../../../../../../components/LinkButton'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function ProductImageUpload({className, images, onChange}) {
  return <ImageUploading
    acceptType={['jpeg', 'jpg', 'png']}
    dataURLKey="url"
    maxNumber={5}
    multiple
    onChange={(imageList) => onChange(imageList)}
    value={images}>
    {({
      imageList,
      ...uploadProps
    }) => <div className={classList([
      'ProductImageUpload',
      className
    ])}>
      <div className="ProductImageUpload__head">
        <Text styleVariant="heading3">Images</Text>
      </div>
      <div className="ProductImageUpload__body">
        <div className="ProductImageUpload__column">
          <UploadCell images={imageList} index={0} {...uploadProps} />
        </div>
        <div className="ProductImageUpload__column">
          <div className="ProductImageUpload__row">
            <UploadCell images={imageList} index={1} {...uploadProps} />
            <UploadCell images={imageList} index={2} {...uploadProps} />
          </div>
          <div className="ProductImageUpload__row">
            <UploadCell images={imageList} index={3} {...uploadProps} />
            <UploadCell images={imageList} index={4} {...uploadProps} />
          </div>
        </div>
      </div>
    </div>}
  </ImageUploading>
}

const UploadCell = ({images = [], index, onImageRemove, ...props}) => images.length < index ? null : <div className="ProductImageUpload__cell">
  {images.length === index ? <AddImage {...props} /> : <>
    <AppImage
      src={images[index].url}
      radius={12}
      bordered />
    <div className="ProductImageUpload__imageOverlay" />
    <IconButton
      className="ProductImageUpload__removeImage"
      icon="trash"
      onClick={() => onImageRemove(index)} />
  </>}
</div>

const AddImage = ({onImageUpload, isDragging, dragProps}) => <div
  className={classList([
    'ProductImageUpload__addImage',
    isDragging ? 'ProductImageUpload__addImage--dragging' : null,
  ])}
  onClick={() => onImageUpload()}
  {...dragProps}>
  <LinkButton>Add image</LinkButton>
  <Text className="ProductImageUpload__addImageText" styleVariant="body3">or drop files to upload.</Text>
</div>
