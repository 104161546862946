import React from 'react'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function OrderStatus({className, statuses}) {
  const statusMap = statuses
    .map(status => (status || '').toLowerCase())
    .filter(status => status !== 'paid')
    .reduce((acc, status) => {
      if (acc[status]) return {...acc, [status]: acc[status] + 1}
      return {...acc, [status]: 1}
    }, {})
  if (statusMap['unfulfilled'] && statusMap['refunded']) delete statusMap['unfulfilled']
  if (statusMap['fulfilled']) {
    statusMap['fulfilled'] -= 1
    if (statusMap['fulfilled'] === 0) delete statusMap['fulfilled']
  }
  return <div className={classList(['OrderStatus', className])}>
    {Object.keys(statusMap).map(status => <Text
      key={status}
      className={classList([
        'OrderStatus__status',
        `OrderStatus__status--${mapStatusColor(status)}`,
      ])}
      styleVariant="body3">
      {status.replace(/_/g, ' ')}
      {statusMap[status] > 1 && <Text
        className="OrderStatus__statusCount"
        styleVariant="label3">
        {statusMap[status]}
      </Text>}
    </Text>)}
  </div>
}

const mapStatusColor = (status) => {
  switch (status) {
    case 'delivered':
    case 'fulfilled':
    case 'marked_as_fulfilled':
    case 'paid':
      return 'green'
    case 'in_transit':
    case 'out_for_delivery':
    case 'picked_up':
    case 'transit':
      return 'blue'
    case 'canceled':
    case 'label_voided':
    case 'partially_refunded':
    case 'refunded':
    case 'voided':
      return 'gray'
    case 'attempted_delivery':
    case 'authorized':
    case 'confirmed':
    case 'expired':
    case 'failure':
    case 'in_progress':
    case 'on_hold':
    case 'label_printed':
    case 'label_purchased':
    case 'not_delivered':
    case 'partially_fulfilled':
    case 'partially_paid':
    case 'pending':
    case 'ready_for_pickup':
    case 'scheduled':
    case 'submitted':
    case 'unfulfilled':
    default:
      return 'yellow'
  }
}
