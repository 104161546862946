import React, {useState} from 'react'
import ImageFadeIn from 'react-image-fade-in'
import './styles.scss'

export default function AppImage({
  className, src, width, height, size, radius, circular, bordered, elevated,
  imageFit, imagePosition, ...props
}) {
  const [loaded, setLoaded] = useState(false)
  return <div
    className={[
      'AppImage',
      !loaded ? null : 'AppImage--loaded',
      !circular ? null : 'AppImage--circular',
      !bordered ? null : 'AppImage--bordered',
      !elevated ? null : 'AppImage--elevated',
      className,
    ].filter(it => !!it).join(' ')}
    style={{
      ...!width && !size ? {} : {
        width: `${width || size}px`,
        minWidth: `${width || size}px`,
      },
      ...!height && !size ? {} : {
        height: `${height || size}px`,
        minHeight: `${height || size}px`,
      },
      ...!radius ? {} : {borderRadius: `${radius}px`},
    }}
    {...props}>
    <ImageFadeIn
      className="AppImage__image"
      src={src || ''}
      style={{
        objectFit: imageFit || 'cover',
        objectPosition: imagePosition || 'center',
      }}
      onLoad={() => setLoaded(true)}
      draggable={false} />
  </div>
}
