import React from 'react';
import {useHistory} from 'react-router-dom'
import Button from '../../components/Button'
import Text from '../../components/Text'
import Shell from '../Shell'
import './styles.scss'

export default function NotFound() {
  const history = useHistory();
  return (
    <Shell
      className="NotFound"
      title="404">
      <Text
        className="NotFound__text"
        styleVariant="display3">
        We can't seem to find the page you're looking for.
      </Text>
      <Button
        styleVariant="secondary"
        onClick={() => history.replace('/') }>
        Take me home
      </Button>
    </Shell>
  );
}
