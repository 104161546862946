import React, {useState} from 'react'
import Button from '../../../../../../components/Button'
import ProfileCard from '../../../../../../components/ProfileCard'
import TheoristLookup from '../../../../../../components/TheoristLookup'
import {classList} from '../../../../../../services/util'
import DashPriceInput from '../../../../components/DashPriceInput'
import OrderPipelineStep from '../OrderPipelineStep'
import './styles.scss'

export default function OrderPipelineStepCreator({className, createSteps, customerAddress}) {
  const [selectedTheorist, setSelectedTheorist] = useState(null)
  const [payout, setPayout] = useState(0)
  const [assignees, setAssignees] = useState({})
  const [steps, setSteps] = useState([])
  const addTheorist = (theorist) => {
    setAssignees(prev => ({
      ...prev,
      [theorist.id]: {
        address1: theorist.address.split(',')[0].trim(),
        address2: (theorist.address.split(',')[1] || '').trim(),
        city: theorist.locationText.split(',')[0].trim(),
        company: theorist.name !== theorist.owner ? theorist.name : '',
        country: theorist.tags.find(tag => tag.includes('country')).split('-')[1].toUpperCase(),
        email: theorist.email,
        handle: theorist.theoristHandle,
        name: theorist.owner,
        phone: theorist.phone,
        region: theorist.locationText.split(',')[1].trim(),
        zip: theorist.postalCode,
      }
    }))
    setSteps(prev => {
      if (prev.length > 0) {
        const lastStep = prev.pop()
        return [...prev, {
          from: lastStep.from,
          to: theorist.id,
          payout: lastStep.payout,
        }, {
          from: theorist.id,
          to: 'customer',
          payout: payout,
        }]
      } else {
        return [{
          from: theorist.id,
          to: 'customer',
          payout: payout,
        }]
      }
    })
    setSelectedTheorist(null)
    setPayout(0)
  }
  return <div className={classList(['OrderPipelineStepCreator', className])}>
    {(steps || []).length > 0 && <ol>
      {steps.map(step => <li key={step.from}>
        <OrderPipelineStep
          assignees={assignees}
          customerAddress={customerAddress}
          step={step}
          isManager />
      </li>)}
    </ol>}
    <TheoristLookup onSelect={(theorist) => setSelectedTheorist(theorist)} />
    {!selectedTheorist ? null : <div className="OrderPipelineStepCreator__selectedTheorist">
      <ProfileCard
        className="OrderPipelineStepCreator__profileCard"
        image={selectedTheorist.profilePicture.file.url}
        title={selectedTheorist.name}
        subtitle={selectedTheorist.locationText}
        elite={selectedTheorist?.metrics?.overall?.elite}
        orderCount={selectedTheorist?.metrics?.overall?.orderCount}
        reviewCount={selectedTheorist?.metrics?.overall?.reviewCount}
        reviewTotal={selectedTheorist?.metrics?.overall?.reviewTotal}
        stringingSince={selectedTheorist.stringingSince} />
      <DashPriceInput
        label="Payout"
        value={payout}
        onChange={(value) => setPayout(value)} />
      <div className="OrderPipelineStepCreator__submit">
        <Button
          styleVariant="secondary"
          onClick={() => addTheorist(selectedTheorist)}
          slim>
          Add theorist
        </Button>
      </div>
    </div>}
    <div className="OrderPipelineStepCreator__submit">
      <Button
        disabled={!steps.length}
        onClick={() => createSteps(assignees, steps)}
        slim>
        Add steps
      </Button>
    </div>
  </div>
}
