import React from 'react'
import {Link} from 'react-router-dom'
import AppIcon from '../../../../components/AppIcon'
import Text from '../../../../components/Text'
import {isFulfilled, isOnDemand} from '../../../../services/product'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function ProductPromise({
  className, tags, fulfillmentQuantity, inventoryPolicy, totalQuantity,
}) {
  if (isFulfilled(tags, fulfillmentQuantity)) return <div
    className={classList(['ProductPromise', className])}>
    <AppIcon className="ProductPromise__icon" icon="check-circle" />
    <div className="ProductPromise__text">
      <Text className="ProductPromise__heading" styleVariant="heading3">Fulfilled by String Theory</Text>
      <Text styleVariant="body3">Ships fast directly to you and
        can be returned in its original condition for any reason, free of charge, within 30 days. <Link
          className="ProductPromise__link" to="/terms/returns">Return policy</Link>
      </Text>
    </div>
  </div>
  if (isOnDemand(tags, totalQuantity, inventoryPolicy)) return <div
    className={classList(['ProductPromise', className])}>
    <AppIcon className="ProductPromise__icon" icon="play-circle"/>
    <div className="ProductPromise__text">
      <Text className="ProductPromise__heading" styleVariant="heading3">Design On Demand</Text>
      <Text styleVariant="body3">This product is custom-made for you upon order and
        can be returned in its original condition for any reason, free of charge, within 30 days. <Link
          className="ProductPromise__link" to="/terms/returns">Return policy</Link>
      </Text>
    </div>
  </div>
  return null
}
