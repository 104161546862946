import React from 'react'
import './styles.scss'

export default function FormattedPrice({children, compareAt, currencyCode, minimumFractionDigits, ...props}) {
  const price = parseFloat(children) || 0
  const comparePrice = parseFloat(compareAt) || 0
  return <span {...props}>
    {price < comparePrice ? <span className="FormattedPrice__compare">
      {format(comparePrice, currencyCode, minimumFractionDigits)}
    </span> : null}
    <span>{format(price, currencyCode, minimumFractionDigits)}</span>
  </span>
}

export const format = (price = 0, currencyCode, minimumFractionDigits) =>
  price.toLocaleString(navigator.language, {
    style: 'currency',
    currency: currencyCode ?? 'USD',
    minimumFractionDigits: minimumFractionDigits ?? 0,
    maximumFractionDigits: Math.max(minimumFractionDigits ?? 0, 2),
  })
