import React from 'react'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import CustomerLine from '../../components/CustomerLine'
import './styles.scss'

export default function CustomerInfo({className, order}) {
  const customer = order.customer
  const shippingAddress = order.shippingAddress
  const billingAddress = order.billingAddress
  return <div className={classList(['CustomerInfo', className])}>
    {order.note && <>
      <Text
        className="CustomerInfo__sectionTitle"
        styleVariant="heading3">
        Notes
      </Text>
      <div className="CustomerInfo__section">
        <Text className="CustomerInfo__line" styleVariant="body3">{order.note}</Text>
      </div>
    </>}
    <Text
      className="CustomerInfo__sectionTitle"
      styleVariant="heading3">
      Customer
    </Text>
    <div className="CustomerInfo__section">
      <CustomerLine className="CustomerInfo__line">{customer.displayName}</CustomerLine>
      <CustomerLine className="CustomerInfo__line">
        {customer.numberOfOrders} order{parseInt(customer.numberOfOrders) === 1 ? '' : 's'}
      </CustomerLine>
    </div>
    <div className="CustomerInfo__section">
      <Text styleVariant="heading4">Private feedback</Text>
      <CustomerLine
        className="CustomerInfo__line"
        styleVariant={!order.feedback ? 'body2' : 'body3'}>
        {!order.feedback ? 'None provided yet' : order.feedback}
      </CustomerLine>
    </div>
    <div className="CustomerInfo__section">
      <Text styleVariant="heading4">Contact information</Text>
      <CustomerLine className="CustomerInfo__line">{order.email}</CustomerLine>
      <CustomerLine className="CustomerInfo__line">{order.phone}</CustomerLine>
    </div>
    {shippingAddress && <div className="CustomerInfo__section">
      <Text styleVariant="heading4">Shipping address</Text>
      <CustomerLine className="CustomerInfo__line">{shippingAddress.name}</CustomerLine>
      {(shippingAddress.formatted || []).map(line => <CustomerLine
        className="CustomerInfo__line"
        key={`shippingAddress_${line}`}>
        {line}
      </CustomerLine>)}
      <CustomerLine className="CustomerInfo__line">{shippingAddress.phone}</CustomerLine>
    </div>}
    <div className="CustomerInfo__section">
      <Text styleVariant="heading4">Billing address</Text>
      {!order.billingAddressMatchesShippingAddress ? <>
        <CustomerLine className="CustomerInfo__line">{billingAddress.name}</CustomerLine>
        {(billingAddress.formatted || []).map(line => <CustomerLine
          className="CustomerInfo__line"
          key={`billingAddress_${line}`}>
          {line}
        </CustomerLine>)}
        <CustomerLine className="CustomerInfo__line">{billingAddress.phone}</CustomerLine>
      </> : <CustomerLine className="CustomerInfo__line" styleVariant="body2">
        Same as shipping address
      </CustomerLine>}
    </div>
  </div>
}
