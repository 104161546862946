import React from 'react'
import {useContentful} from 'react-contentful'
import ImageFadeIn from 'react-image-fade-in'
import {useHistory} from 'react-router-dom'
import Button from '../../components/Button'
import Text from '../../components/Text'
import {ContentModel} from '../../services/contentful'
import Shell from '../Shell'
import ShopModule from '../ShopModule'
import desktopLanding from './images/landing-desktop.jpg'
import mobileLanding from './images/landing-mobile.jpg'
import fingertips from './images/fingertips.svg'
import premium from './images/premium.svg'
import tailored from './images/tailored.svg'
import './styles.scss'

const differentiators = [{
  image: premium,
  title: 'Premium skill',
  body: 'We hand-pick lacrosse stringing experts to be on one platform.',
}, {
  image: tailored,
  title: 'Tailored for you',
  body: 'Find your go-to stringer for a reliable pocket.',
}, {
  image: fingertips,
  title: 'At your fingertips',
  body: 'In just a few clicks, order from professionals across the world.',
}]

export default function Home() {
  const history = useHistory()
  const {data} = useContentful({
    contentType: 'page',
    query: {'fields.link': '/'},
  })
  const page = !data ? {} : ContentModel(data.items[0])
  return <Shell
    className="Home"
    title="Home">
    <div className="Home__landing">
      <ImageFadeIn
        className="Home__landingDesktopImage"
        src={desktopLanding}
        loadAsBackgroundImage />
      <ImageFadeIn
        className="Home__landingMobileImage"
        src={mobileLanding}
        loadAsBackgroundImage />
      <Text
        className="Home__landingTagline"
        styleVariant="display1">
        Pockets by pros.
      </Text>
      <Text className="Home__landingSubtext">
        Elevate your game with lacrosse heads from top stringers and dyers worldwide.
      </Text>
      <Button
        className="Home__landingButton"
        onClick={() => history.push('/design')}>
        Start Designing
      </Button>
      <Button
        className="Home__landingButton"
        onClick={() => history.push('/shop/heads/theorist')}
        styleVariant="tertiary">
        Shop Inventory
      </Button>
    </div>
    {(page.modules || [])
    .filter(mod => !(mod.tags || []).includes('hidden'))
    .map(mod => <ShopModule
      className="Home__module"
      key={mod.id}
      {...mod} />)}
    <Section title="What makes us different">
      {differentiators.map(diff => <div key={diff.title} className="Home__sectionItem">
        <img src={diff.image} alt={diff.title} />
        <Text
          className="Home__differentiatorTitle"
          styleVariant="heading3">
          {diff.title}
        </Text>
        <Text>{diff.body}</Text>
      </div>).reduce((prev, curr) => [prev, <SectionSpacer key={`${curr.title} spacer`} />, curr])}
    </Section>
  </Shell>
}

const Section = ({children, title}) => <div className="Home__section">
  <Text className="Home__sectionTitle" styleVariant="heading1">{title}</Text>
  <div className="Home__sectionContent">{children}</div>
</div>

const SectionSpacer = (props) => <div className="Home__sectionSpacer" {...props} />
