import React, {useEffect, useState} from 'react'
import {useContentful} from 'react-contentful'
import {ContentModel} from '../../services/contentful'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import Input from '../Input'
import Text from '../Text'
import './styles.scss'

export default function TheoristLookup({
  className, limit, placeholder, includeTag, excludeTag, onPopulate, onSelect,
}) {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const {data} = useContentful({
    contentType: 'theorist',
    skip: !query,
    query: {
      'fields.name[match]': query,
      ...(!includeTag ? {} : {'fields.tags[in]': includeTag}),
      ...(!excludeTag ? {} : {'fields.tags[nin]': excludeTag}),
      order: 'fields.name',
      limit: limit ?? 5,
    },
  })
  useEffect(() => {
    setResults(!data ? [] : (data.items || []).map(item => ContentModel(item)))
  }, [data])
  useEffect(() => {
    if (!onPopulate) return
    onPopulate(results)
  }, [onPopulate, results])
  return <div className={classList(['TheoristLookup', className])}>
    <Input
      inputClass="TheoristLookup__input"
      placeholder={'Search theorists' || placeholder}
      value={query}
      onChange={(value) => setQuery(value)} />
    <AppIcon
      className="TheoristLookup__icon"
      icon="search" />
    {results.length > 0 && <div className="TheoristLookup__results">
      {results.map(result => <Text
        className="TheoristLookup__result"
        key={result.id}
        onClick={() => {
          onSelect(result)
          setQuery('')
        }}>
        {result.name}
      </Text>)}
    </div>}
  </div>
}
