import React, {useEffect, useState} from 'react'
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import {useIsMounted} from 'react-tidy'
import AddressInput from '../../../../components/AddressInput'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import HtmlTextEditor from '../../../../components/HtmlTextEditor'
import Input from '../../../../components/Input'
import {useLoading} from '../../../../components/LoadingWrapper'
import Text from '../../../../components/Text'
import {useTheorist} from '../../../../services/contentful'
import {openChat} from '../../../../services/crisp'
import {callable, fetchLocation} from '../../../../services/firebase'
import './styles.scss'

const skills = [
  {
    label: `Mesh`,
    value: 'mesh',
  },
  {
    label: `Traditional`,
    value: 'traditional',
  },
  {
    label: `Women's`,
    value: 'women',
  },
  {
    label: `Goalie`,
    value: 'goalie',
  },
  {
    label: `Dye`,
    value: 'dye',
  },
]

const initialState = {
  owner: '',
  phone: '',
  address: '',
  location: {},
  tags: [],
  about: '',
}

export default function DashSettings() {
  const {theorist} = useTheorist()
  const [original, setOriginal] = useState(null)
  const [edits, setEdits] = useState(null)
  const [different, setDifferent] = useState(false)
  const [saving, setSaving] = useState(false)
  const [addressInput, setAddressInput] = useState({data: null, error: null})
  const {addLoad, removeLoad} = useLoading()
  const isMounted = useIsMounted()
  const addTag = tag => {
    setEdits(edits => {
      return {
        ...edits,
        tags: [...edits.tags, tag].sort(),
      }
    })
  }
  const removeTag = tag => {
    setEdits(edits => {
      return {
        ...edits,
        tags: edits.tags.filter(t => t !== tag),
      }
    })
  }
  const initSettings = async () => {
    const location = await fetchLocation(theorist.location.lat, theorist.location.lon)
    if (!isMounted()) return
    const base = {
      owner: theorist.owner || initialState.owner,
      phone: theorist.phone || initialState.phone,
      address: theorist.address || initialState.address,
      location: {
        ...theorist.location,
        text: theorist.locationText,
      } || initialState.location,
      tags: theorist.tags.sort() || initialState.tags,
      about: documentToHtmlString(theorist.about) || initialState.about,
    }
    const nameTokens = base.owner.split(/\s+/)
    const addressTokens = base.address.split(', ')
    setEdits(base)
    setAddressInput({
      data: {
        ...location,
        firstName: nameTokens[0],
        lastName: nameTokens.slice(-1),
        streetAddress: addressTokens[0],
        apartment: addressTokens[1],
        phone: base.phone,
      },
      error: {},
    })
    setOriginal(base)
  }
  const validAddress = () => {
    let valid = true
    const data = addressInput.data
    const error = {}
    const fields = ['firstName', 'lastName', 'streetAddress', 'city', 'region', 'phone']
    fields.forEach(field => {
      if (!data[field]) {
        error[field] = 'This field cannot be left blank'
        valid = false
      }
    })
    if (!/^\+[1-9]\d{1,14}$/.test(data.phone)) {
      error.phone = 'Phone must be in the format +1XXXXXXXXXX'
      valid = false
    }
    // noinspection JSCheckFunctionSignatures
    setAddressInput({data, error})
    return valid
  }
  const saveTheorist = async () => {
    if (!different) return
    if (!validAddress()) return
    setSaving(true)
    try {
      await callable('updateTheoristV2')({id: theorist.id, ...edits})
      if (isMounted()) {
        setOriginal(edits)
        setSaving(false)
      }
    } catch (error) {
      console.log(error)
      if (isMounted()) setSaving(false)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {if (theorist) initSettings()}, [theorist])
  useEffect(() => {
    if (!addressInput.data) return
    const {
      firstName, lastName, streetAddress, apartment, city, region, postalCode, country, phone, location,
    } = addressInput.data
    setEdits(edits => {
      return {
        ...edits,
        phone,
        owner: `${firstName} ${lastName}`,
        address: `${streetAddress}${!apartment ? '' : `, ${apartment}`}`,
        location: {
          ...(location || edits.location),
          text: `${city}, ${region}`,
          zip: postalCode,
        },
        tags: [
          ...edits.tags.filter(tag => !tag.includes('country') && !tag.includes('region')),
          `country-${country.toLowerCase()}`,
          `region-${region.toLowerCase()}`,
        ].sort(),
      }
    })
  }, [addressInput])
  useEffect(() => {
    if (saving || !theorist) addLoad('settings')
    else removeLoad('settings')
    return () => removeLoad('settings')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving, theorist])
  useEffect(() => {
    setDifferent(JSON.stringify(edits) !== JSON.stringify(original))
  }, [original, edits])
  if (!original || !edits) return null
  // noinspection JSCheckFunctionSignatures
  return <div className="DashSettings">
    <div className="DashSettings__head">
      <Text styleVariant="heading2">Settings</Text>
      <div className="DashSettings__actions">
        <Button
          styleVariant="tertiary"
          to={`/${theorist.theoristHandle}`}
          slim>
          View profile
        </Button>
        <Button
          disabled={!different}
          onClick={() => saveTheorist()}
          slim>
          Save
        </Button>
      </div>
    </div>
    <div className="DashSettings__block">
      <Text
        className="DashSettings__heading"
        styleVariant="heading3">
        Account info
      </Text>
      <div className="DashSettings__field">
        <div className="DashSettings__inputRow">
          <Input
            className="DashSettings__input"
            label="Theorist name"
            value={theorist.name}
            disabled />
        </div>
        <div className="DashSettings__inputRow">
          <Input
            className="DashSettings__input"
            label="Email"
            value={theorist.email}
            disabled />
          <Input
            className="DashSettings__input"
            label="Handle"
            value={theorist.theoristHandle}
            disabled />
        </div>
        <Text
          className="DashSettings__description"
          styleVariant="body2">
          These settings including images on your profile are not currently available for editing. Contact us to make
          changes.
        </Text>
        <Button
          className="DashSettings__input"
          styleVariant="tertiary"
          slim
          onClick={() => openChat()}>
          Contact support
        </Button>
      </div>
    </div>
    <div className="DashSettings__block">
      <Text
        className="DashSettings__heading"
        styleVariant="heading3">
        Short bio
      </Text>
      <div className="DashSettings__field">
        <HtmlTextEditor
          initialValue={original.about}
          onChange={(value) => setEdits(edits => {
            return {...edits, about: value}
          })} />
      </div>
    </div>
    <div className="DashSettings__block">
      <Text
        className="DashSettings__heading"
        styleVariant="heading3">
        Your skills
      </Text>
      <div className="DashSettings__field">
        {skills.map(skill => <Checkbox
          key={skill.value}
          className="DashSettings__input"
          label={skill.label}
          checked={edits.tags.includes(`skill-${skill.value}`)}
          onToggle={(checked) => {
            if (!checked) addTag(`skill-${skill.value}`)
            else removeTag(`skill-${skill.value}`)
          }} />)}
        <Text
          className="DashSettings__description"
          styleVariant="body2">
          Checked skills are available to order from your page. Toggle them off to remove them.
        </Text>
      </div>
    </div>
    <div className="DashSettings__block">
      <Text
        className="DashSettings__heading"
        styleVariant="heading3">
        Shipping info
      </Text>
      <div className="DashSettings__field">
        <AddressInput
          {...addressInput}
          onChange={newData => setAddressInput(addressInput => {
            return {
              ...addressInput,
              data: {
                ...(addressInput.data || {}),
                ...newData,
              },
            }
          })} />
        <Text
          className="DashSettings__description"
          styleVariant="body2">
          This is used as the return address on shipping labels created for you.
        </Text>
      </div>
    </div>
  </div>
}
