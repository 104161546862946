import React from 'react'
import {useScript} from 'usehooks-ts'
import {classList} from '../../services/util'

export default function TikTok({className, videoId}) {
  useScript('https://www.tiktok.com/embed.js', {removeOnUnmount: true})
  return <blockquote
    className={classList(['tiktok-embed', className])}
    cite={`https://www.tiktok.com/@onlyvarro/video/${videoId}`}
    data-video-id={videoId}
    style={{maxWidth: 600, minWidth: 300}}>
    <section />
  </blockquote>
}
