import React, {useState} from 'react'
import {useContentful} from 'react-contentful'
import AppImage from '../../../../components/AppImage'
import LinkButton from '../../../../components/LinkButton'
import Text from '../../../../components/Text'
import TikTok from '../../../../components/TikTok'
import {ContentModel} from '../../../../services/contentful'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function DesignReview({className}) {
  const [expanded, setExpanded] = useState(false)
  const {loading, fetched, error, data} = useContentful({
    contentType: 'testimonial',
    id: '7JadbbpVmWulrRjpJaR7MI',
  })
  if (loading || !fetched) return null
  if (error) console.log(error)
  if (!data) return null
  const testimonial = ContentModel(data)
  return <div className={classList(['DesignReview', className])}>
    <div className="DesignReview__speechBubble">
      <Text className="DesignReview__quotationMark" styleVariant="display1">“</Text>
      <Text styleVariant="body3">{testimonial.quote}</Text>
    </div>
    <div className="DesignReview__profile">
      <AppImage src={testimonial.image.file.url} size={48} circular bordered />
      <div className="DesignReview__profileText">
        <a
          className="DesignReview__profileName"
          href={testimonial.socialLink}
          rel="noreferrer"
          target="_blank">
          <Text styleVariant="heading3">{testimonial.name}</Text>
        </a>
        <Text className="DesignReview__profileStats" styleVariant="body2">
          <b>{testimonial.followerCount}</b> Followers
        </Text>
      </div>
      <div className="DesignReview__profileSpacer" />
      <LinkButton
        className="DesignReview__expandButton"
        onClick={() => setExpanded(!expanded)}>
        {!expanded ? 'Show' : 'Hide'} video
      </LinkButton>
    </div>
    {!expanded ? null : <TikTok videoId={testimonial.videoId} />}
  </div>
}
