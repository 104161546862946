import React from 'react'
import OrderStatus from '../OrderStatus'
import './styles.scss'

export default function OrderTracking({url, number, status}) {
  return <div className="OrderTracking">
    <a
      className="OrderTracking__url"
      target="_blank"
      rel="noreferrer"
      href={url}>
      {number}
    </a>
    {status && <OrderStatus statuses={[status]} />}
  </div>
}
