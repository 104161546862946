import React from 'react'
import moment from 'moment'

export default function DeliveryWindow({earliest, latest, from, ...props}) {
  const earliestDelivery = moment(from).add(earliest, 'd')
  const latestDelivery = moment(from).add(latest, 'd')
  return <span {...props}>{earliestDelivery.format('MMM D')} - {
    earliestDelivery.month() !== latestDelivery.month()
      ? latestDelivery.format('MMM D')
      : latestDelivery.format('D')}</span>
}
