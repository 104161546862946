import React, {useMemo} from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import AppImage from '../AppImage'
import Text from '../Text'
import './styles.scss'

export default function ProfileCard({
  className, image, imageSize, title, subtitle, link, variant= 'small',
  elite, orderCount = 0, reviewTotal = 0, reviewCount = 0,
  stringingSince, ...props
}) {
  const classNames = classList([
    'ProfileCard',
    variant && `ProfileCard--${variant}`,
    !link && !props.onClick ? null : 'ProfileCard--link',
    className,
  ])
  const experience = useMemo(
    () => moment().diff(moment().year(stringingSince ?? moment().year()), 'years'),
    [stringingSince],
  )
  const cleanOrderCount = useMemo(
    () => isNaN(orderCount) ? 0 : parseInt(`${orderCount}`),
    [orderCount],
  )
  const cleanReviewCount = useMemo(
    () => isNaN(reviewCount) ? 0 : parseInt(`${reviewCount}`),
  [reviewCount],
  )
  const cleanReviewTotal = useMemo(
    () => isNaN(reviewTotal) ? 0 : parseInt(`${reviewTotal}`),
  [reviewTotal],
  )
  const overallRating = useMemo(
    () => ((cleanReviewTotal ?? 0) / (cleanReviewCount || 1)).toFixed(1),
    [cleanReviewCount, cleanReviewTotal],
  )
  const highlights = [
    (cleanReviewCount > 0 || cleanOrderCount > 0) && {
      title: cleanReviewCount > cleanOrderCount ? cleanReviewCount : cleanOrderCount,
      subtitle: cleanReviewCount > cleanOrderCount
        ? `Review${cleanReviewCount === 1 ? '' : 's'}`
        : `Sale${cleanOrderCount === 1 ? '' : 's'}`,
    },
    overallRating > 0 && {
      title: <>{overallRating} <AppIcon className="Theorist__starIcon" icon="star" /></>,
      subtitle: 'Rating',
    },
    experience > 0 && {
      title: `${experience}+`,
      subtitle: 'Years experience',
    }
  ].filter(it => !!it)
  const children = <>
    <div className="ProfileCard__overlay" />
    <div className="ProfileCard__main">
      <div className="ProfileCard__image">
        <AppImage src={image} size={imageSize || (variant === 'small' ? 64 : 96)} circular bordered />
        {`${elite}` === 'true' && <AppIcon
          className="ProfileCard__eliteIcon"
          icon="award-filled"
          style={{
            width: variant === 'small' ? 16 : 24,
            height: variant === 'small' ? 24 : 36,
          }}/>}
      </div>
      <div className="ProfileCard__text">
        <Text styleVariant="heading3">{title}</Text>
        <Text styleVariant="body3">{subtitle}{variant === 'small' && (experience > 0 || cleanReviewCount > 0) && <>
          {' • '}{cleanReviewCount > 0 ? <>
          <b>{overallRating}</b> <AppIcon className="ProfileCard__subtitleStar" icon="star" /> <span
            className="ProfileCard__subtitleHighlight">
            {cleanReviewCount > cleanOrderCount
              ? `${cleanReviewCount} review${cleanReviewCount === 1 ? '' : 's'}`
              : `${cleanOrderCount} sale${cleanOrderCount === 1 ? '' : 's'}`}
          </span>
        </> : <span className="ProfileCard__subtitleHighlight">
          {cleanOrderCount > 0
            ? `${cleanOrderCount} sale${cleanOrderCount === 1 ? '' : 's'}`
            : `${experience}+ years experience`}
        </span>}
        </>}</Text>
      </div>
    </div>
    {variant === 'large' && (highlights || []).length > 0 && <div className="ProfileCard__highlights">
      {highlights.map((highlight, index) => <div
        key={`ProfileCard__highlight ${index}`}
        className="ProfileCard__highlight">
        <Text styleVariant="heading1">{highlight.title}</Text>
        <Text styleVariant="body6">{highlight.subtitle}</Text>
      </div>)}
    </div>}
  </>
  return !link ? <div className={classNames} {...props}>{children}</div>
    : <Link className={classNames} to={link} {...props}>{children}</Link>
}
