import React, {createRef, useEffect} from 'react'
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {useMediaQuery} from 'react-responsive/src'
import {useHistory, useLocation} from 'react-router'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import IconButton from '../../../../components/IconButton'
import LinkButton from '../../../../components/LinkButton'
import Text from '../../../../components/Text'
import {decodeParams, encodeParams} from '../../../../services/url'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function ShopComplexFilters({filters, resultSize, open, onClose}) {
  const history = useHistory()
  const {pathname, search} = useLocation()
  const queryParams = decodeParams(search) || {}
  const filterRef = createRef()
  const isMobile = useMediaQuery({maxWidth: 720})
  const hasFilters = (filters || []).length > 0
  useEffect(() => {
    if (!hasFilters) return
    if (open && isMobile) disableBodyScroll(filterRef.current)
    else enableBodyScroll(filterRef.current)
    return () => clearAllBodyScrollLocks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFilters, open, isMobile])
  return hasFilters ? <div
    ref={filterRef}
    className={classList([
      'ShopComplexFilters',
      open ? null : 'ShopComplexFilters--hidden',
    ])}>
    <div className="ShopComplexFilters__header">
      <Text styleVariant="heading2">Filters</Text>
      <IconButton icon="clear" onClick={() => {if (onClose) onClose()}} />
    </div>
    <div className="ShopComplexFilters__footer">
      <LinkButton onClick={() => history.replace(pathname)}>Clear all</LinkButton>
      <Button styleVariant="secondary" onClick={() => {if (onClose) onClose()}}>
        {!resultSize
          ? 'No exact matches'
          : `Show ${resultSize > 99 ? '99+' : resultSize} result${resultSize === 1 ? '' : 's'}`}
      </Button>
    </div>
    {filters.map(filter => <div
      key={`ShopComplexFilters-${filter.tag}`}
      className="ShopComplexFilters__section">
      <Text className="ShopComplexFilters__sectionTitle" styleVariant="heading3">{filter.title}</Text>
      {(filter.children || []).map(child => <Checkbox
        className="ShopComplexFilters__checkbox"
        key={`${filter.tag}-${child.tag}`}
        label={child.title}
        checked={queryParams[filter.tag] === child.tag}
        onToggle={(checked) => {
          let newQueryParams
          if (!checked) {
            newQueryParams = {...queryParams, [filter.tag]: child.tag}
          } else {
            newQueryParams = {...queryParams}
            delete newQueryParams[filter.tag]
          }
          history.replace(`${pathname}${encodeParams(newQueryParams)}`)
        }} />)}
    </div>)}
  </div> : null
}
