import React from 'react'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import OrderTracking from '../../components/OrderTracking'
import './styles.scss'

export default function TrackingInfo({className, fulfillments}) {
  const orderTracking = (fulfillments || [])
    .filter(fulfillment => (fulfillment.trackingInfo || []).length > 0)
    .map(fulfillment => fulfillment.trackingInfo.map(info => ({...info, displayStatus: fulfillment.displayStatus})))
    .reduce((acc, curr) => acc.concat(curr), [])
  if (orderTracking.length === 0) return null
  return <div className={classList(['TrackingInfo', className])}>
    <Text
      className="TrackingInfo__line"
      styleVariant="heading3">
      Tracking
    </Text>
    {orderTracking.map(tracking => <div
      key={`tracking_${tracking.number}`}
      className="TrackingInfo__line">
      <Text styleVariant="body2">{tracking.company}</Text>
      <OrderTracking
        number={tracking.number}
        url={tracking.url}
        status={tracking.displayStatus} />
    </div>)}
  </div>
}
