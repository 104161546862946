import React from 'react'
import { Link } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import AppIcon from '../AppIcon'
import Text from '../Text'
import './styles.scss'
import Logo from '../Logo'

const links = [{
  type: 'site',
  content: 'Privacy & Legal',
  url: '/terms',
}, {
  type: 'mail',
  content: 'Contact us',
  url: 'mailto:hey@stringtheory.us'
}, {
  type: 'site',
  content: 'Become a Theorist',
  url: '/signup',
}, {
  type: 'social',
  content: 'facebook',
  url: 'https://www.facebook.com/stringtheory.us/',
}, {
  type: 'social',
  content: 'instagram',
  url: 'https://www.instagram.com/stringtheory.us/',
}, {
  type: 'social',
  content: 'tik-tok',
  url: 'https://www.tiktok.com/@stringtheory.us',
}]

export default function Footer(props) {
  return <footer className={`Footer ${props.className}`}>
    <nav className="Footer__links">
      <Link
        className="Footer__link"
        to='/'
        onClick={() => animateScroll.scrollToTop({duration: 300})}>
        <AppIcon className="Footer__icon" icon="string-theory" />
        <Logo className="Footer__logo" mono />
      </Link>
      {links.filter(link => link.type !== 'social').map(link => {
        switch (link.type) {
          case 'mail': return <MailLink key={link.url} {...link} />
          default: return <SiteLink key={link.url} {...link} />
        }
      }).reduce((prev, curr) => [prev, <Spacer key={`${curr.url}/spacer`} />, curr], [])}
      <div className="Footer__flex" />
      <div className="Footer__social">
        {links.filter(link => link.type === 'social').map(link => <SocialLink key={link.url} {...link} />)}
      </div>
    </nav>
    <Text
      className="Footer__copyright"
      styleVariant="body2">
      © {Math.max(new Date().getFullYear(), 2023)} Rad Cannon LLC. All rights reserved.
    </Text>
  </footer>
}

const Spacer = (props) => <Text className="Footer__spacer" {...props}>·</Text>

const SiteLink = ({content, url}) => <Link className="Footer__link" to={url}>{content}</Link>

const MailLink = ({content, url}) => <a className="Footer__link" href={url}>{content}</a>

const SocialLink = ({content, url}) => <a
  className="Footer__link Footer__link--social"
  href={url}
  rel="noopener noreferrer"
  target="_blank">
  <AppIcon icon={content} />
</a>
