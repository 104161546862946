import React from 'react'
import Dropdown, {DropdownItem} from '../../../../../../components/Dropdown'
import Pill from '../../../../../../components/Pill'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function ProductCategories({className, categories, tags, onChange}) {
  const updateTag = (key, value) => {
    if (onChange) {
      onChange(tags.map(tag => {
        if (tag.split('-')[0] === key) return `${key}-${value}`
        return tag
      }))
    }
  }
  const categoryTag = tags.find(tag => tag.includes(categories.tag)) || ''
  const category = categories.children.find(filter => `${categories.tag}-${filter.tag}` === categoryTag) || {}
  return <div className={classList(['ProductCategories', className])}>
    <Text styleVariant="heading3">Category</Text>
    <div className="ProductCategories__selection">
      {categories.children.map(filter => <Pill
        key={filter.id}
        className="ProductCategories__category"
        active={tags.includes(`${categories.tag}-${filter.tag}`)}
        onClick={() => updateTag(categories.tag, filter.tag)}>
        {filter.title}
      </Pill>)}
    </div>
    <div className="ProductCategories__subSelection">
      {(category.children || []).filter(filter => !!filter.required).map(filter => {
        if (!filter.children) return null
        const subCategoryTag = tags.find(tag => tag.includes(filter.tag)) || filter.tag
        return <Dropdown
          className="ProductCategories__dropdown"
          key={`${category.tag}-${subCategoryTag}`}
          label={filter.title}
          onSelected={(value) => updateTag(filter.tag, value)}>
          {filter.children.map(child => <DropdownItem
            key={`${filter.tag}-${child.tag}`}
            value={child.tag}
            selected={tags.includes(`${filter.tag}-${child.tag}`)}>
            {child.title}
          </DropdownItem>)}
        </Dropdown>
      })}
    </div>
  </div>
}
