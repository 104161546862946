import React, {useState} from 'react'
import AppIcon from '../../../../components/AppIcon'
import Dialog from '../../../../components/Dialog'
import Text from '../../../../components/Text'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function DesignGuarantee({className}) {
  const [showGuarantee, setShowGuarantee] = useState(false)
  return <div className={classList(['DesignGuarantee', className])}>
    <div className="DesignGuarantee__teaser">
      <AppIcon className="DesignGuarantee__icon" icon="award" />
      <Text>
        Every design order is guaranteed by our String Theory Pro Pledge. <button
          className="DesignGuarantee__link"
          onClick={() => setShowGuarantee(true)}>
          Learn more
        </button>
      </Text>
    </div>
    <Dialog
      title="The String Theory Pro Pledge"
      open={showGuarantee}
      onDismiss={() => setShowGuarantee(false)}>
      <Text>
        At String Theory, we hold our team to the highest professional standards. Your satisfaction is our mission,
        and we're willing to put our money where our mesh is. If you're not happy with your newly designed
        lacrosse head, here's how we set things straight:
      </Text>
      <Text>
        <ol>
          <li>
            <span style={{fontWeight: 600}}>Keep playing with it while we re-do your order.</span> Once your
            new head arrives, you'll find a prepaid return label in the package to send us back the old one.
          </li>
          <li>
            If you'd rather just walk away, return the head within
            30 days for a <span style={{fontWeight: 600}}>full refund.</span>
          </li>
        </ol>
      </Text>
      <Text>
        We're committed to fueling exceptional gameplay—one design at a time.
      </Text>
    </Dialog>
  </div>
}
