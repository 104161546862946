import React from 'react'
import Text from '../Text'
import {classList} from '../../services/util'
import './styles.scss'

export default function RichText({className, children}) {
  return <Text
    className={classList(['RichText', className])}
    styleVariant="rich">
    {children}
  </Text>
}
