import React from 'react'
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import parse from 'html-react-parser'
import moment from 'moment'
import {useContentful} from 'react-contentful'
import {useParams} from 'react-router'
import ProfileCard from '../../components/ProfileCard'
import Text from '../../components/Text'
import RichText from '../../components/RichText'
import {ContentModel} from '../../services/contentful'
import NotFound from '../NotFound'
import Shell from '../Shell'
import './styles.scss'

export default function Blog() {
  const {slug} = useParams()
  const {loading, fetched, error, data} = useContentful({
    contentType: 'blogPost',
    query: {'fields.slug': slug},
  })
  if (loading || !fetched) return <Shell className="Blog" />
  if (error || !data || data.items.length === 0) return <NotFound />
  const blog = ContentModel(data.items[0])
  console.log(blog)
  return <Shell
    className="Blog"
    title={blog.title}
    noSticky>
    <div className="Blog__content">
      <Text styleVariant="heading1">{blog.title}</Text>
      <Text
        className="Blog__publishDate"
        styleVariant="body2">
        {moment(blog.publishDate).format('MMM D, YYYY')}
      </Text>
      <ProfileCard
        className="Blog__author"
        image={`${blog.author.profilePicture.file.url}?w=144&h=144`}
        imageSize={48}
        title={blog.author.name}
        subtitle={blog.author.locationText}
        link={`/${blog.author.theoristHandle}`}
        elite={blog.author?.metrics?.overall?.elite}
        orderCount={blog.author?.metrics?.overall?.orderCount}
        reviewCount={blog.author?.metrics?.overall?.reviewCount}
        reviewTotal={blog.author?.metrics?.overall?.reviewTotal}
        stringingSince={blog.author.stringingSince} />
      <RichText>
        {parse(documentToHtmlString(blog.body, {
          renderNode: {
            'embedded-asset-block': (node) => {
              return `<div style="display: flex; justify-content: center; width: 100%; margin: 40px 0;">
              <img 
                src="${node.data.target.fields.file.url}" 
                alt="${node.data.target.fields.description}" 
                style="width: 90%; border: solid 1px #2223; border-radius: 12px; box-shadow: 0 2px 8px #2228;" />
            </div>`;
            }
          }
        }))}
      </RichText>
    </div>
  </Shell>
}
