import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

let lastPage = ''

export function initialize(history) {
  ReactGA.initialize('UA-76462208-3')
  ReactPixel.init('4889349244437566')
  history.listen(location => reportPageView(location))
  reportPageView(history.location)
}

function reportPageView(location) {
  const newPage = location.pathname + location.search
  if (newPage !== lastPage) {
    lastPage = newPage
    ReactGA.pageview(lastPage)
    ReactPixel.pageView()
  }
}
