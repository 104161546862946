import React, {useState} from 'react'
import moment from 'moment'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {useMediaQuery} from 'react-responsive/src'
import FormattedPrice from '../../../../../../components/FormattedPrice'
import LoadingIndicator from '../../../../../../components/LoadingIndicator'
import OrderStatus from '../../components/OrderStatus'
import PaginationButton from '../../../../../../components/PaginationButton'
import Tab, {TabGroup} from '../../../../../../components/Tab'
import Text from '../../../../../../components/Text'
import {orderBy, useQueryWithPagination, where} from '../../../../../../services/firebase'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function DashOrderList({user, theorist}) {
  const readAccess = theorist.role === 'admin' || theorist.role === 'manager'
  const [filter, setFilter] = useState(readAccess ? 'all' : 'assigned-to-you')
  const [status, setStatus] = useState('open')
  const history = useHistory()
  const {url} = useRouteMatch()
  const isCompact = useMediaQuery({maxWidth: 720})
  const {loading, error, docs, hasMore, fetchMore} = useQueryWithPagination(
    'orders', [
      orderBy('name', 'desc'),
      (filter === 'assigned-to-you') && where('assignees.keys', 'array-contains', user.uid),
      status === 'open' && where('open', '==', true),
    ],
    [filter, status],
  )
  if (error) console.log(error)
  const orders = (docs || [])
    .filter(doc => status === 'closed' ? !doc.data.open : true)
    .map(doc => {
      const shopify = doc.data.shopify || {}
      return {
        key: doc.id,
        ...shopify,
        assignees: Object.keys(doc.data.assignees || {})
        .filter(k => k !== 'keys')
        .map(k => {
          const assignee = doc.data.assignees[k]
          return assignee.company || assignee.name
        }).sort(),
      }
    })
  return <div className="DashOrderList">
    <Text className="DashOrderList__heading" styleVariant="heading2">Orders</Text>
    {readAccess && <TabGroup
      className="DashOrderList__heading"
      styleVariant="pill">
      {['all', 'assigned-to-you'].map(tab => <Tab
        key={`DashOrderList__tab--${tab}`}
        onClick={() => setFilter(tab)}
        selected={tab === filter}>
        {(tab.charAt(0).toUpperCase() + tab.slice(1)).replace(/-/g, ' ')}
      </Tab>)}
    </TabGroup>}
    <TabGroup className="DashOrderList__heading">
      {['open', 'closed'].map(tab => <Tab
        key={`DashOrderList__tab--${tab}`}
        onClick={() => setStatus(tab)}
        selected={tab === status}>
        {(tab.charAt(0).toUpperCase() + tab.slice(1)).replace(/-/g, ' ')}
      </Tab>)}
    </TabGroup>
    {loading && orders.length < 1 ? <LoadingIndicator /> : (orders.length < 1 ? <Text>
      You have no {status} orders.
    </Text> : <table className="DashOrderList__table">
      <thead>
      {isCompact ? null : <tr>
        <th className="DashOrderList__th">Order</th>
        <th className="DashOrderList__th">Date</th>
        <th className="DashOrderList__th">Customer</th>
        <th className="DashOrderList__th">Total</th>
        <th className="DashOrderList__th">Items</th>
        <th className="DashOrderList__th">Status</th>
        {!readAccess ? null : <th className="DashOrderList__th">Assignees</th>}
      </tr>}
      </thead>
      <tbody>
      {orders.map(order => <tr
        className={classList([
          'DashOrderList__order',
          !order.closedAt ? null : 'DashOrderList__order--closed',
        ])}
        key={order.key}
        onClick={() => history.push(`${url}/${order.key}`)}>
        <td className="DashOrderList__td">
          {!isCompact ? <Text className="DashOrderList__link">
            {order.name}
          </Text> : <div>
            <Text className="DashOrderList__link" styleVariant="display3">{order.name}</Text>
            <div className="DashOrderList__infoLine">
              <OrderStatus
                className="DashOrderList__status"
                statuses={[
                  order.displayFinancialStatus,
                  order.displayFulfillmentStatus,
                  ...(order.fulfillments.map(fulfillment => fulfillment.displayStatus)),
                ]} />
            </div>
            {!readAccess ? null : <Text className="DashOrderList__infoLine DashOrderList__infoLine--text">
              Assigned to {order.assignees.length > 0 ? <b>{order.assignees.join(', ')}</b> : <i>Unassigned</i>}
            </Text>}
            <Text className="DashOrderList__infoLine DashOrderList__infoLine--text">
              <b>{order.currentSubtotalLineItemsQuantity || 0}</b> item{order.currentSubtotalLineItemsQuantity === 1
              ? '' : 's'} for <b><FormattedPrice
                currencyCode={order.currentTotalPriceSet.presentmentMoney.currencyCode}
                minimumFractionDigits={2}>
              {order.currentTotalPriceSet.presentmentMoney.amount}
              </FormattedPrice></b>
            </Text>
            <Text className="DashOrderList__infoLine DashOrderList__infoLine--text">
              Placed by <b>{order.customer.displayName}</b> on <b>
                {moment(order.createdAt).format('MMM D [at] h:mm a')}
              </b>
            </Text>
          </div>}
        </td>
        {isCompact ? null : <>
          <td className="DashOrderList__td DashOrderList__td--text">
            <Text>{moment(order.createdAt).format('MMM D [at] h:mm a')}</Text>
          </td>
          <td className="DashOrderList__td DashOrderList__td--text">
            <Text>{order.customer.displayName}</Text>
          </td>
          <td className="DashOrderList__td DashOrderList__td--text">
            <Text>
              <FormattedPrice
                currencyCode={order.currentTotalPriceSet.presentmentMoney.currencyCode}
                minimumFractionDigits={2}>
                {order.currentTotalPriceSet.presentmentMoney.amount}
              </FormattedPrice>
            </Text>
          </td>
          <td className="DashOrderList__td DashOrderList__td--text">
            <Text>{order.currentSubtotalLineItemsQuantity || 0}</Text>
          </td>
          <td className="DashOrderList__td">
            <OrderStatus
              className="DashOrderList__status"
              statuses={[
                order.displayFinancialStatus,
                order.displayFulfillmentStatus,
                ...(order.fulfillments.map(fulfillment => fulfillment.displayStatus)),
              ]} />
          </td>
          {!readAccess ? null : <td className="DashOrderList__td DashOrderList__td--text">
            <Text className="DashOrderList__assignees">
              {order.assignees.length > 0
                ? order.assignees.map(assignee => <div key={`assignee_${assignee}`}>{assignee}</div>)
                : <i>Unassigned</i>}
            </Text>
          </td>}
        </>}
      </tr>)}
      </tbody>
    </table>)}
    <PaginationButton
      className="DashOrderList__pagination"
      hasPagination={hasMore}
      onPagination={() => fetchMore()} />
  </div>
}
