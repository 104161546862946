import React, {createRef, useEffect} from 'react'
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {classList} from '../../services/util'
import IconButton from '../IconButton'
import Text from '../Text'
import './styles.scss'

export default function Dialog({className, title, children, open, onDismiss}) {
  const _onDismiss = !onDismiss ? () => {} : () => onDismiss()
  const dialogRef = createRef()
  useEffect(() => {
    if (open) disableBodyScroll(dialogRef.current)
    else enableBodyScroll(dialogRef.current)
    return () => clearAllBodyScrollLocks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  return <div
    ref={dialogRef}
    className={classList([
      'Dialog',
      !open ? 'Dialog--closed' : null,
    ])}
    onClick={_onDismiss}>
    <div
      className={classList([
        'Dialog__content',
        className,
      ])}
      onClick={(e) => e.stopPropagation()}>
      <div className="Dialog__topBar">
        {!title ? <div /> : <Text styleVariant="heading3">{title}</Text>}
        <IconButton icon="clear" onClick={_onDismiss} />
      </div>
      {children}
    </div>
  </div>
}
