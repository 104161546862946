/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Link, Redirect, Route, Switch, useLocation, useRouteMatch} from 'react-router-dom'
import AppIcon from '../../components/AppIcon'
import Button from '../../components/Button'
import {useLoading} from '../../components/LoadingWrapper'
import Text from '../../components/Text'
import {useFirebase} from '../../services/firebase'
import {encodeParams} from '../../services/url'
import {classList} from '../../services/util'
import Shell from '../Shell'
import DashError from './components/DashError'
import DashPending from './components/DashPending'
import DashHome from './scenes/DashHome'
import DashOrders from './scenes/DashOrders'
import DashProducts from './scenes/DashProducts'
import DashSettings from './scenes/DashSettings'
import './styles.scss'

export default function Dash() {
  const {path, url} = useRouteMatch()
  const {pathname} = useLocation()
  const {loading, user, theorist} = useFirebase()
  const {addLoad, removeLoad} = useLoading()
  useEffect(() => {
    if (loading) addLoad('dash')
    else removeLoad('dash')
    return () => removeLoad('dash')
  }, [loading])
  if (!loading && !user) return <Redirect to={`/login${encodeParams({redirect: pathname})}`} />
  if (loading) return <Shell className="Dash" title="Dash" />
  if (!theorist) return <Shell className="Dash" title="Dash">
    <DashError />
  </Shell>
  if (theorist.pending) return <Shell className="Dash" title="Dash">
    <DashPending name={theorist.name} />
  </Shell>
  return <Shell
    className="Dash"
    title="Dash"
    renderDrawer={() => <DashDrawer path={pathname} />}
    drawerPosition="left">
    <Switch>
      <Route exact path={path}>
        <DashHome theorist={theorist} />
      </Route>
      <Route path={`${path}/orders`}>
        <DashOrders user={user} theorist={theorist} />
      </Route>
      <Route path={`${path}/products`}>
        <DashProducts theorist={theorist} />
      </Route>
      <Route path={`${path}/settings`}>
        <DashSettings />
      </Route>
      <Route path={`${path}/discord`} component={() => {
        window.open(
          'https://discord.gg/EPthZY6ByF',
          '_blank',
        )
        return <Redirect to={url} />
      }}/>
      <Route path={`${path}/groupme`} component={() => {
        window.open(
          'https://groupme.com/join_group/85728500/0R1MOO6s',
          '_blank',
        )
        return <Redirect to={url} />
      }}/>
      <Route path={`${path}/docs`} component={() => {
        window.open(
          'https://docs.google.com/document/d/1aRoaFJfKy8P_rubcjShkL4WuFW0R5bmXujB0Cwapyks/edit?usp=sharing',
          '_blank',
        )
        return <Redirect to={url} />
      }}/>
      <Route path="*">
        <Redirect to={url} />
      </Route>
    </Switch>
  </Shell>
}

export function DashDrawer({className, path}) {
  const dashLinks = [
    {icon: 'home', text: 'Dashboard', link: '/dash', active: path === '/dash'},
    {icon: 'inbox', text: 'Orders', link: '/dash/orders', active: path.includes('/dash/orders')},
    {icon: 'tag', text: 'Products', link: '/dash/products', active: path.includes('/dash/products')},
    {icon: 'gear', text: 'Settings', link: '/dash/settings', active: path.includes('/dash/settings')},
  ]
  const {signOut} = useFirebase()
  return <nav className={classList(['DashDrawer', className])}>
    {dashLinks.map(props => <DashDrawerLink
      key={props.link}
      {...props} />)}
    <Button
      className="DashDrawer__logout"
      styleVariant="tertiary"
      onClick={() => signOut()}
      slim>
      Log out
    </Button>
  </nav>
}

const DashDrawerLink = ({icon, text, link, active, ...props}) => <Link
  className={classList([
    'DashDrawerLink',
    !active ? null : 'DashDrawerLink--active',
  ])}
  to={link}
  {...props}>
  <div className="DashDrawerLink__indicator" />
  <AppIcon className="DashDrawerLink__icon" icon={icon} />
  <Text className="DashDrawerLink__text" styleVariant="heading4">{text}</Text>
</Link>
