import React from 'react'
import Loader from 'react-loader-spinner'

export default function LoadingIndicator({height, width}) {
  return <Loader
    type="ThreeDots"
    color="#2228"
    height={height || 48}
    width={width || 48} />
}
