import React from 'react'
import {classList} from '../../services/util'
import './styles.scss'

export default function Tab({className, children, selected, ...props}) {
  return <div
    className={classList([
      'Tab',
      !selected ? null : 'Tab--selected',
      className,
    ])}
    {...props}>
    <button className="Tab__button">{children}</button>
    <div className="Tab__underline" />
  </div>
}

export const TabGroup = ({className, children, styleVariant}) =>  <div
  className={classList([
    'TabGroup',
    styleVariant !== 'pill' ? null : 'TabGroup--pill',
    className,
  ])}>
  <div className="TabGroup__content">{children}</div>
</div>
