import React from 'react'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import LineItemGroup from '../../components/LineItemGroup'
import OrderPipelinePackage from '../../components/OrderPipelinePackage'
import OrderPipelineStep from '../../components/OrderPipelineStep'
import OrderPipelineStepCreator from '../../components/OrderPipelineStepCreator'
import './styles.scss'

export default function OrderPipeline({
  className, assignees, closedAt, createdAt, customerAddress,
  isAdmin, isManager, lineItemGroups, parcel, steps, userId,
  onAddPackage, onAddSteps, onBuyShippingLabel, onUpdateStep,
}) {
  return <div className={classList(['OrderPipeline', className])}>
    {(lineItemGroups || []).map(lineItemGroup => <LineItemGroup
      key={lineItemGroup[0].variant.id}
      lineItems={lineItemGroup}
      quantity={lineItemGroup[0].currentQuantity} />
    )}
    {!parcel && !isAdmin ? null : <OrderPipelinePackage
      className="OrderPipeline__package"
      createdAt={createdAt}
      delivered={!!closedAt}
      parcel={parcel}
      steps={steps}
      onAddPackage={(pkg) => {if (onAddPackage) onAddPackage(pkg)}} />}
    {(steps || []).length > 0 ? <>
      <Text
        className="OrderPipeline__heading"
        styleVariant="heading4">
        Shipments
      </Text>
      <ol>
        {steps.map((step, index) => <li key={step.from}>
          <OrderPipelineStep
            assignees={assignees}
            customerAddress={customerAddress}
            isAdmin={isAdmin}
            isManager={isManager}
            isAssigned={step.from === userId}
            step={step}
            onBuyShippingLabel={(rateId) => onBuyShippingLabel(index, rateId)}
            onUpdateStep={(step) => onUpdateStep(index, step)} />
        </li>)}
      </ol>
    </> : (!isAdmin ? null : <>
      <Text
        className="OrderPipeline__heading"
        styleVariant="heading4">
        Shipments
      </Text>
      <OrderPipelineStepCreator
        className="OrderPipeline__stepCreator"
        createSteps={(assignees, steps) => onAddSteps(assignees, steps)}
        customerAddress={customerAddress} />
    </>)}
  </div>
}
