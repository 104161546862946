import {Crisp} from 'crisp-sdk-web'
import {useEffect} from 'react'

export function initChat() {
  Crisp.configure("192d57ef-695e-4406-a85e-928b4d5aeff6")
  Crisp.setZIndex(500)
  Crisp.chat.hide()
}

export const useChat = (visible) => useEffect(() => {
  if (visible) Crisp.chat.show()
  else {
    Crisp.chat.close()
    Crisp.chat.hide()
  }
}, [visible])

export const openChat = () => {
  Crisp.chat.show()
  Crisp.chat.open()
}
