import React, {useEffect, useState} from 'react'
import {useQuery} from '@apollo/client'
import {ContentModel, useContentfulWithPagination} from '../../services/contentful'
import {FETCH_MODULE, flattenData} from '../../services/shopify'
import {classList} from '../../services/util'
import Collection from './collection'
import Item from './item'
import './styles.scss'

export default function ShopModule({
  className, title, subtitle, source, model, query, tags, queryOperation, orderBy, pageSize = 100,
  collectionAdapter, itemAdapter, pageLink, itemExtras, onFilter, onLoad
}) {
  let {
    loading: contentfulLoading,
    error: contentfulError,
    data: contentfulData,
    fetchMore: contentfulFetchMore,
  } = useContentfulWithPagination({
    skip: source !== 'contentful',
    contentType: model,
    query: query || {
      ...(tags && tags.length > 0 ? {[`fields.tags[${queryOperation || 'all'}]`]: tags.join(',')} : {}),
      'fields.tags[nin]': 'hidden',
      ...(orderBy ? {order: orderBy} : {}),
      limit: pageSize,
    },
    keyArgs: [`fields.tags[${queryOperation || 'all'}]`, 'fields.tags[nin]', 'order'],
  })
  const {
    loading: shopifyLoading,
    error: shopifyError,
    data: shopifyData,
    fetchMore: shopifyFetchMore,
  } = useQuery(FETCH_MODULE, {
    skip: source !== 'shopify',
    variables: {
      query: query || tags.map(tag => `tag:${tag}`).join(` ${queryOperation || 'AND'} `),
      sortBy: !orderBy ? 'UPDATED_AT' : (orderBy.charAt(0) === '-' ? orderBy.substring(1) : orderBy),
      reverse: !orderBy ? true : orderBy.charAt(0) === '-',
      first: pageSize,
    },
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    fetchNextPage: () => {},
  })
  useEffect(() => setLoading(contentfulLoading || shopifyLoading), [contentfulLoading, shopifyLoading])
  useEffect(() => setError(contentfulError || shopifyError), [contentfulError, shopifyError])
  useEffect(() => setData(source !== 'shopify' ? contentfulData : shopifyData), [source, contentfulData, shopifyData])
  useEffect(() => {
    if (error) console.log(error)
    if (error || !data) return
    let items = []
    switch (source) {
      case 'contentful':
        items = data.items.map(item => ContentModel(item))
        setPagination({
          hasNextPage: data.total > items.length,
          fetchNextPage: () => contentfulFetchMore(items.length),
        })
        break
      case 'shopify':
        items = flattenData(data).products
        setPagination({
          hasNextPage: data.products.pageInfo.hasNextPage,
          fetchNextPage: () => shopifyFetchMore({
            variables: {
              after: data.products.pageInfo.endCursor,
            },
          }),
        })
        break
      default: break
    }
    setItems(items)
    if (onLoad) onLoad((items || []).length)
  }, [error, data, source, onLoad, contentfulFetchMore, shopifyFetchMore])
  if (!items || items.length === 0) return null
  return <div className={classList(['ShopModule', className])}>
    <Collection
      className="ShopModule__collection"
      {...collectionAdapter}
      extras={{
        title: title,
        subtitle: subtitle,
        link: pageLink,
      }}
      hasPagination={pagination.hasNextPage}
      onPagination={() => {
        if (loading) return
        pagination.fetchNextPage()
      }}>
      {items.filter(item => !onFilter ? true : onFilter(item))
        .map(item => <Item
          key={item.id}
          data={item}
          extras={itemExtras}
          {...itemAdapter} />)}
    </Collection>
  </div>
}
