import React from 'react'
import moment from 'moment'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import Text from '../Text'
import './styles.scss'

export default function ReviewItem({className, name, location, rating, date, body}) {
  return <div className={classList(['ReviewItem', className])}>
    <div className="ReviewItem__customer">
      <div className="ReviewItem__customerAvatar">
        <Text styleVariant="heading4">{name[0]}</Text>
      </div>
      <div className="ReviewItem__customerInfo">
        <Text styleVariant="heading3">{name.split(/\s+/)[0]}</Text>
        {location && <Text styleVariant="body6">{location}</Text>}
      </div>
    </div>
    <div className="ReviewItem__rating">
      {[1, 2, 3, 4, 5].map(ratingValue => <AppIcon
        key={`ReviewItem ${ratingValue}`}
        className={classList([
          'ReviewItem__star',
          ratingValue > rating && 'ReviewItem__star--unearned',
        ])}
        icon="star"
      />)}
      <Text styleVariant="heading7">· {moment(date.toDate()).fromNow()}</Text>
    </div>
    <Text styleVariant="body3">{body}</Text>
  </div>
}
