import React, {createRef, useEffect, useState} from 'react'
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {Link} from 'react-router-dom'
import {DashDrawer} from '../../scenes/Dash'
import {openChat} from '../../services/crisp'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import AppImage from '../AppImage'
import Drawer from '../Drawer'
import IconButton from '../IconButton'
import LinkButton from '../LinkButton'
import Text from '../Text'
import './styles.scss'

const links = [{
  type: 'link',
  title: 'Shop',
  to: '/',
  icon: 'shopping-bag',
}, {
  type: 'link',
  title: 'Team',
  to: '/shop/team',
  icon: 'users',
}, {
  type: 'chat',
  title: 'Support',
  icon: 'life-buoy'
}]

export default function SiteLinks({className, path, theorist, orientation}) {
  return <div className={classList([
    'SiteLinks',
    orientation === 'vertical' ? null : 'SiteLinks--horizontal',
    className,
  ])}>
    {links.map(link => <Link
      className="SiteLinks__link"
      key={`SiteLinks ${link.to}`}
      to={link.to || path}
      onClick={() => {if (link.type === 'chat') openChat()}}>
      <Text className="SiteLinks__linkTitle">{link.title}</Text>
      <div className="SiteLinks__linkUnderline"/>
    </Link>)}
    <TheoristButton
      className="SiteLinks__theoristButton"
      theorist={theorist}
      linkOverride="/dash"
      hideIcon={!theorist} />
  </div>
}

const TheoristButton = ({className, theorist, profilePic, linkOverride, hideIcon}) => <LinkButton
  className={classList(['TheoristButton', className])}
  to={!theorist ? '/signup'
    : (!linkOverride && theorist.theoristHandle ? `/${theorist.theoristHandle}` : linkOverride)}>
  {!theorist || !theorist.profilePicture
    ? (!hideIcon ? <AppIcon className="TheoristButton__icon" icon="user" /> : null)
    : <AppImage
      className="TheoristButton__image"
      src={`${theorist.profilePicture.file.url}?w=128&h=128`}
      size={32}
      circular bordered />}
  <Text className="TheoristButton__text" styleVariant="heading4">
    {!theorist ? 'Become a Theorist' : theorist.name}
  </Text>
</LinkButton>

export function SiteDrawer({path, theorist, open, closeDrawer}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => closeDrawer(), [path])
  return <Drawer
    className="SiteDrawer"
    position="right"
    open={open}
    onOverlayClick={() => closeDrawer()}>
    <div className="SiteDrawer__menu">
      <Text styleVariant="heading2">Menu</Text>
      <IconButton
        icon="clear"
        onClick={() => closeDrawer()} />
    </div>
    <nav className="SiteDrawer__section">
      {links.map(link => <Link
        className="SiteDrawer__link"
        key={`SiteDrawer ${link.to}`}
        to={link.to || path}
        onClick={() => {
          if (link.type === 'chat') {
            closeDrawer()
            openChat()
          }
        }}>
        {!link.icon ? null : <AppIcon className="SiteDrawer__linkIcon" icon={link.icon} />}
        <Text styleVariant="heading3">{link.title}</Text>
      </Link>)}
    </nav>
    <TheoristButton
      className="SiteDrawer__theoristButton"
      theorist={theorist} />
    {!theorist ? null : <DashDrawer path={path} />}
  </Drawer>
}

const complexSiteMap = [{
  id: 'shop',
  title: 'Shop',
  link: '/design',
  links: [{
    title: 'Designed By You',
    description: 'Mix and match any head, pocket, or dye.',
    icon: 'edit',
    to: '/design',
    color: '#fda50f',
  }, {
    title: 'Dyed & Strung',
    description: 'Heads listed by our team and ready to ship today.',
    icon: 'head',
    to: '/shop/heads',
    color: '#ff7417',
  }, {
    title: 'Apparel',
    description: 'Designed for fit, comfort, and style.',
    icon: 'shirt',
    to: '/shop/apparel',
    color: '#0f52ba',
  }]
}, {
  id: 'theorists',
  title: 'Theorists',
  link: '/shop/theorists',
  links: [{
    title: 'Our Team',
    description: 'Discover theorists from all over the globe.',
    icon: 'users',
    to: '/shop/theorists',
    color: '#0f52ba',
  }, {
    title: 'Dashboard',
    description: 'Manage your theorist account.',
    icon: 'layout',
    to: '/dash',
    color: '#fda50f',
  }, {
    title: 'Become a Theorist',
    description: 'If you\'re an expert stringer, we want to work with you.',
    icon: 'briefcase',
    to: '/signup',
    color: '#ff7417',
  }]
}, {
  id: 'company',
  title: 'Company',
  link: '/',
  links: [{
    title: 'Contact Us',
    icon: 'mail',
    to: 'mailto:hey@stringtheory.us',
    color: '#fda50f',
  }, {
    title: 'Support',
    icon: 'life-buoy',
    to: 'mailto:support@stringtheory.us',
    color: '#b22222',
  }, {
    title: 'Privacy & Legal',
    icon: 'lock',
    to: '/terms',
    color: '#0f52ba',
  }]
}]

export function ComplexSiteBar({path}) {
  const [open, setOpen] = useState(false)
  const [activeSection, setActiveSection] = useState('shop')
  const [barFocus, setBarFocus] = useState(false)
  const [menuFocus, setMenuFocus] = useState(false)
  const barRef = createRef()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setOpen(false), [path])
  useEffect(() => {
    if (open) disableBodyScroll(barRef.current)
    else enableBodyScroll(barRef.current)
    return () => clearAllBodyScrollLocks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  useEffect(() => {
    let timeout
    if (barFocus || menuFocus) setOpen(true)
    else timeout = setTimeout(() => setOpen(false), 300)
    return () => clearTimeout(timeout)
  }, [barFocus, menuFocus])
  return <div
    ref={barRef}
    className="ComplexSiteBar">
    <div
      className="ComplexSiteBar__bar"
      onClick={() => setBarFocus(true)}
      onMouseEnter={() => setBarFocus(true)}
      onMouseLeave={() => setBarFocus(false)}>
      {complexSiteMap.map(section => <Link
        className={classList([
          'ComplexSiteBar__section',
          !open || section.id !== activeSection ? null : 'ComplexSiteBar__section--active',
        ])}
        key={section.id}
        to={path}
        onMouseEnter={() => setActiveSection(section.id)}>
        <Text className="ComplexSiteBar__sectionTitle">{section.title}</Text>
        <div className="ComplexSiteBar__sectionUnderline"/>
      </Link>)}
    </div>
    <div
      className={classList([
        'ComplexSiteBar__menu',
        open ? null : 'ComplexSiteBar__menu--closed',
      ])}
      onMouseEnter={() => setMenuFocus(true)}
      onMouseLeave={() => setMenuFocus(false)}>
      {((complexSiteMap.find(section => section.id === activeSection) || {}).links || [])
        .map(link => <ComplexSiteLink key={link.to} {...link} onClick={() => setOpen(false)} />)}
    </div>
    <div className={classList([
      'ComplexSiteBar__overlay',
      open ? null : 'ComplexSiteBar__overlay--closed',
    ])} />
  </div>
}

export function ComplexSiteDrawer({path, open, closeDrawer}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => closeDrawer(), [path])
  return <Drawer
    className="ComplexSiteDrawer"
    position="right"
    open={open}
    onOverlayClick={() => closeDrawer()}>
    <div className="ComplexSiteDrawer__menu">
      <Text styleVariant="heading2">Menu</Text>
      <IconButton
        icon="clear"
        onClick={() => closeDrawer()} />
    </div>
    {complexSiteMap.map(section => <div key={section.title} className="ComplexSiteDrawer__section">
      <Text className="ComplexSiteDrawer__sectionTitle" styleVariant="label6">{section.title}</Text>
      {section.links.map(link => <ComplexSiteLink key={link.to} {...link} onClick={() => closeDrawer()} slim />)}
    </div>)}
  </Drawer>
}

function ComplexSiteLink({className, title, description, icon, to, color, slim, onClick}) {
  const [hover, setHover] = useState(false)
  return <LinkComponent
    className={classList([
      'ComplexSiteLink',
      !slim ? null : 'ComplexSiteLink--slim', className
    ])}
    to={to}
    onClick={() => {if (onClick) onClick()}}
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}>
    <AppIcon
      className="ComplexSiteLink__icon"
      icon={icon}
      style={{
        color: !hover ? '#2228' : (color || '#222')
      }} />
    <div className="ComplexSiteLink__text">
      <Text className="ComplexSiteLink__title" styleVariant="heading3">
        {title}
        {!slim ? <AppIcon className="ComplexSiteLink__arrow" icon="chevron-right"/> : null}
      </Text>
      {!slim ? <Text className="ComplexSiteLink__description" styleVariant="body2">{description}</Text> : null}
    </div>
  </LinkComponent>
}

const LinkComponent = ({to, ...props}) => to[0] === '/'
  ? <Link to={to} {...props} />
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  : <a href={to} {...props} />
