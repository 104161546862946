import React from 'react'
import Checkbox from '../../../../../../components/Checkbox'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function ProductFilters({className, categories, tags, onChange, isAdmin}) {
  const categoryTag = tags.find(tag => tag.includes(categories.tag)) || ''
  const category = categories.children.find(filter => `${categories.tag}-${filter.tag}` === categoryTag) || {}
  return <div className={classList(['ProductFilters', className])}>
    {!isAdmin ? null : <div className="ProductFilters__adminContainer">
      <Text className="ProductFilters__adminTitle" styleVariant="heading3">Admin settings</Text>
      <ProductFiltersSelection
        category={category}
        tags={tags}
        onChange={onChange}
        adminOnly />
    </div>}
    <Text styleVariant="heading3">Filters</Text>
    <Text
      className="ProductFilters__message"
      styleVariant="body2">
      Make it easier for customers to find your listing.
    </Text>
    <ProductFiltersSelection
      category={category}
      tags={tags}
      onChange={onChange} />
  </div>
}

const ProductFiltersSelection = ({category, tags, onChange, adminOnly}) => {
  const addTag = (tag) => {
    if (onChange) onChange([...tags, tag].sort())
  }
  const removeTag = (tag) => {
    if (onChange) onChange(tags.filter(t => t !== tag))
  }
  return <div className="ProductFilters__selection">
    {!category.children
      ? <Text className="ProductFilters__subSelection">None available.</Text>
      : category.children
      .filter(filter => !filter.required && (adminOnly ? filter.adminOnly : !filter.adminOnly))
      .map(filter => {
        if (!filter.children) return <Checkbox
          className="ProductFilters__checkbox"
          key={`filter-${filter.tag}`}
          label={filter.title}
          checked={tags.includes(filter.tag)}
          onToggle={(checked) => {
            if (!checked) addTag(filter.tag)
            else removeTag(filter.tag)
          }} />
        return <div
          className="ProductFilters__subSelection"
          key={`${category.tag}-${filter.tag}`}>
          <Text
            className="ProductFilters__subSelectionTitle"
            styleVariant="label3">
            {filter.title}
          </Text>
          {filter.children.map(child => <Checkbox
            className="ProductFilters__checkbox"
            key={`${filter.tag}-${child.tag}`}
            label={child.title}
            checked={tags.includes(`${filter.tag}-${child.tag}`)}
            onToggle={(checked) => {
              if (!checked) addTag(`${filter.tag}-${child.tag}`)
              else removeTag(`${filter.tag}-${child.tag}`)
            }} />)}
        </div>
      })}
  </div>
}
