import React from 'react'
import {computeHighlight} from '../../services/product'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import Text from '../Text'
import './styles.scss'

export default function ProductHighlight({className, isSoldOut, price, comparePrice, tags}) {
  const highlight = computeHighlight(isSoldOut, price, comparePrice, tags)
  if (!highlight) return null
  return <div
    className={classList(['ProductHighlight', className])}
    style={{color: highlight.color}}>
    <AppIcon className="ProductHighlight__icon" icon={highlight.icon} />
    <Text styleVariant="heading3">{highlight.text}</Text>
  </div>
}
