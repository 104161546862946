import React, {useEffect, useState} from 'react'
import {nanoid} from 'nanoid'
import {useSessionStorage} from 'usehooks-ts'
import AppImage from '../../../../components/AppImage'
import CheckoutButton from '../../../../components/CheckoutButton'
import FormattedPrice from '../../../../components/FormattedPrice'
import ProductDelivery from '../../../../components/ProductDelivery'
import Text from '../../../../components/Text'
import {fetchDoc} from '../../../../services/firebase'
import {computeIntegerId, findTagValue} from '../../../../services/product'
import {createLineItem} from '../../../../services/shopify'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function DesignOrder({className, customizations}) {
  const [customProducts, setCustomProducts] = useState(null)
  const [customOrder, setCustomOrder] = useSessionStorage('custom', {})
  useEffect(() => {
    const promises = (customizations || [])
      .map(it => (customOrder || {})[it])
      .filter(it => !!it)
      .map(it => fetchDoc('products', computeIntegerId((it || {}).productId)))
    Promise.all(promises).then(snaps => setCustomProducts(snaps
      .filter(snap => !!snap.exists())
      .map(snap => {
        const product = snap.data().shopify
        return {[findTagValue(product.tags, 'custom')]: product}
      })
      .reduce((acc, curr) => {
        return {...acc, ...curr}
      }, {})))
  }, [customOrder, customizations])
  if (!customOrder.head || !customProducts || !customProducts.head) return null
  const {head: selectedHead, ...selectedExtras} = customProducts
  const customVariants = Object.keys(customProducts).map(key => {
    return {
      [key]: customProducts[key].variants.find(variant => variant.id === (customOrder[key] || {}).variantId) || {},
    }
  }).reduce((acc, curr) => {
    return {...acc, ...curr}
  }, {})
  const {price, comparePrice} = Object.values(customVariants).map(variant => {
    return {
      price: variant.price,
      comparePrice: variant.compareAtPrice || variant.price,
    }
  }).reduce((acc, curr) => {
    return {
      price: acc.price + parseInt(curr.price),
      comparePrice: acc.comparePrice + parseInt(curr.comparePrice),
    }
  }, {price: 0, comparePrice: 0})
  const designId = nanoid(7)
  const lineItems = Object.keys(customOrder)
    .filter(k => !!(customOrder[k] || {}).variantId)
    .map(k => {
      const item = customOrder[k]
      return createLineItem(item.variantId, 1, {
        'Design ID': designId,
        ...(!item.config || !item.config.options ? {} : item.config.options),
        ...(!item.config?.theorist ? {} : {'Theorist': item.config.theorist}),
        ...(k !== 'head' || !customOrder.notes ? {} : {'Notes': customOrder.notes}),
      })
    })
  // TODO: Use this instead when theorists are added to services
  // const theoristCount = new Set(Object.keys(selectedExtras)
  //   .map(k => (selectedExtras[k].config || {})['Theorist'])
  //   .filter(it => !!it))
  //   .size
  const theoristCount = Object.keys(selectedExtras).length
  return <div className={classList(['DesignOrder', className])}>
    <div className="DesignOrder__header">
      <AppImage
        className="DesignOrder__image"
        src={!customVariants.head ? null : customVariants.head.image.url}
        radius={12}
        bordered={true}
        imageFit={'cover'} />
      <div className="DesignOrder__headerText">
        <Text
          className="DesignOrder__title"
          styleVariant="heading1">
          Your new {selectedHead.title}
        </Text>
        <Text styleVariant="body4">Just the way you want it.</Text>
      </div>
    </div>
    <div className="DesignOrder__lineItems">
      {Object.keys(selectedExtras || {}).map(key => {
        const {title, vendor} = customProducts[key]
        const {config} = customOrder[key] || {}
        return <div
          className="DesignOrder__lineItem"
          key={key}>
          <Text>
            <b>{title}</b> by {config?.theorist ?? vendor}
          </Text>
          {!config ? null : <ul>
            {Object.keys(config.options).sort().map(k => <li key={k}>{k}: {config.options[k]}</li>)}
          </ul>}
        </div>
      })}
    </div>
    <Text styleVariant="heading3">
      <FormattedPrice compareAt={comparePrice}>{price}</FormattedPrice>
    </Text>
    <CheckoutButton
      className="DesignOrder__button"
      lineItems={lineItems}
      onSuccess={() => setCustomOrder({})}>
      Add to cart
    </CheckoutButton>
    <ProductDelivery tags={['design']} theoristCount={theoristCount} />
  </div>
}
