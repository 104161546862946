import React from 'react'
import Text from '../Text'
import {classList} from '../../services/util'
import './styles.scss'

export default function Input({
  className, type, placeholder, value, label, disabled, error, labelVariant, inputClass, inputRef,
  onChange, onFocus, onBlur, onSubmit
}) {
  return <div className={classList(['Input', className])}>
    <div className={[
      'Input__container',
      !label ? 'Input__container--collapse' : '',
      !disabled ? '' : 'Input__container--disabled',
      !error ? '' : 'Input__container--error',
    ].filter(it => !!it).join(' ')}>
      <Text
        className="Input__label"
        styleVariant={labelVariant || 'label3'}>
        {label}
      </Text>
      <input
        className={classList(['Input__input', inputClass])}
        ref={inputRef}
        type={type || 'text'}
        placeholder={placeholder}
        value={value ?? ''}
        disabled={disabled}
        onChange={(e) => {if (onChange) onChange(e.target.value)}}
        onFocus={() => {if (onFocus) onFocus()}}
        onBlur={() => {if (onBlur) onBlur()}}
        onKeyPress={(e) => {if (e.key === 'Enter' && onSubmit) onSubmit()}} />
    </div>
    <Text
      className={[
        'Input__error',
        !error ? 'Input__error--gone' : '',
      ].filter(it => !!it).join(' ')}
      styleVariant="body3">
      {error}
    </Text>
  </div>
}
