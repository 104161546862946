import React from 'react'
import CategoryItem from '../../components/CategoryItem'
import ProductItem from '../../components/ProductItem'
import ProfileCard from '../../components/ProfileCard'

export default function Item({data, component, map={}, extras={}}) {
  let ItemComponent;
  switch (component) {
    case 'category':
      ItemComponent = CategoryItem
      break
    case 'product':
      ItemComponent = ProductItem
      break
    case 'profile':
      ItemComponent = ProfileCard
      break
    default:
      ItemComponent = DefaultItem
  }
  return <ItemComponent {...Object.keys(map).map(key => {
    const template = map[key]
    const variables = template.match(/[^${]+(?=})/g)
    const computed = (variables || []).reduce((prev, curr) => {
      const keys = curr.split('.')
      const value = keys.reduce((prev, curr) => !prev ? null : prev[curr], {...data, ...extras})
      return prev.replace(`$\{${curr}}`, value)
    }, template)
    return {[key]: computed}
  }).reduce((prev, curr) => {
    return {...prev, ...curr}
  }, {})} />
}

const DefaultItem = () => <div />
