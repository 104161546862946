import React, {useEffect, useState} from 'react'
import {useMediaQuery} from 'react-responsive/src'
import {useHistory} from 'react-router'
import {useSessionStorage} from 'usehooks-ts'
import Button from '../../../../components/Button'
import ColorOption, {ColorGroup} from '../../../../components/ColorOption'
import LoadingIndicator from '../../../../components/LoadingIndicator'
import Text from '../../../../components/Text'
import {findTagValue} from '../../../../services/product'
import {encodeParams} from '../../../../services/url'
import {classList} from '../../../../services/util'
import ShopModule from '../../../ShopModule'
import './styles.scss'

export default function DesignModule({module, ...props}) {
  switch (findTagValue(module.tags, 'custom')) {
    case 'head': return <DesignProductList module={module} {...props} />
    case 'dye': return <DesignDye module={module} {...props} />
    case 'pocket': return <DesignProductList module={module} {...props} />
    default: return null
  }
}

const DesignProductList = ({className, module, selectedFilter}) => {
  const [resultSize, setResultSize] = useState(null)
  useEffect(() => setResultSize(null), [selectedFilter])
  const isMobile = useMediaQuery({maxWidth: 720})
  const history = useHistory()
  const pageLink = `${module.pageLink}${encodeParams({filter: selectedFilter})}`
  return <div className={classList(['DesignProductList', className])}>
    {resultSize === null ? <LoadingIndicator /> : null}
    <ShopModule
      className="DesignProductList__module"
      {...module}
      title={null}
      subtitle={null}
      pageLink={isMobile ? null : pageLink}
      tags={[...module.tags, selectedFilter]}
      onLoad={(size) => setResultSize(size)} />
    {!isMobile || !pageLink || resultSize < 5 ? null : <Button
      className="DesignModule__button"
      styleVariant="secondary"
      onClick={() => history.push(pageLink)}>
      Show all {resultSize} {(module.title || 'product').toLowerCase()}s
    </Button>}
  </div>
}

const DesignDye = ({className, module, selectedHead}) => {
  const history = useHistory()
  const [customOrder, setCustomOrder] = useSessionStorage('custom', {})
  const headSelection = (customOrder || {}).head
  if (!selectedHead || !headSelection) return null
  const colorOptions = selectedHead.options.find(option => option.name === 'Color').values
  const selectedColor = selectedHead.variants.find(variant => variant.id === headSelection.variantId)
    .selectedOptions.find(option => option.name === 'Color').value
  return <div className={classList(['DesignDye', className])}>
    <ColorGroup
      className="DesignDye__options"
      label={selectedColor}>
      {!colorOptions || !headSelection ? null : colorOptions.map(option => <ColorOption
        key={`ColorOption ${option}`}
        className="DesignDye__colorOption"
        color={option}
        selected={option === selectedColor}
        onClick={() => setCustomOrder({
          ...customOrder,
          head: {
            ...headSelection,
            variantId: selectedHead.variants.find(variant => !!variant.selectedOptions.find(o => o.value === option)).id,
          },
        })} />)}
      {!module.pageLink ? null : <div className="DesignDye__buttonWrapper">
        <Button
          className="DesignDye__button"
          styleVariant="tertiary"
          onClick={() => history.push(module.pageLink)}>
          Dye
        </Button>
      </div>}
    </ColorGroup>
    <Text className="DesignDye__price" styleVariant="body3">Included</Text>
  </div>
}
