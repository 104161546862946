import React from 'react'
import Carousel from '../../components/Carousel'
import Grid from '../../components/Grid'

export default function Collection({component, map, extras, ...props}) {
  switch (component) {
    case 'carousel': return <Carousel {...map} {...props} {...extras} />
    case 'grid': return <Grid {...map} {...props} {...extras} />
    default: return null
  }
}
