import React, {useEffect, useState} from 'react'
import {useSessionStorage} from 'usehooks-ts'
import LoadingIndicator from '../../../../components/LoadingIndicator'
import Tab, {TabGroup} from '../../../../components/Tab'
import Text from '../../../../components/Text'
import {useDoc} from '../../../../services/firebase'
import {computeIntegerId, findTagValue} from '../../../../services/product'
import {classList} from '../../../../services/util'
import SelectedProduct from '../../components/SelectedProduct'
import DesignModule from '../DesignModule'
import './styles.scss'

export default function DesignSection({
  className, module, filters, selectedHead, selectedFilter, onSelectFilter, onSelectImage,
}) {
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [customOrder, setCustomOrder] = useSessionStorage('custom', {})
  const customKey = findTagValue(module.tags, 'custom')
  const customSelection = (customOrder || {})[customKey]
  const isHead = customKey === 'head'
  const {loading, error, data} = useDoc(
    'products',
    computeIntegerId((customSelection || {}).productId),
    !customSelection || isHead,
  )
  useEffect(() => {
    if (!customSelection) setSelectedVariant(null)
    else if (isHead) setSelectedVariant(selectedHead.variants.find(variant => variant.id === customSelection.variantId))
    else if (!data) setSelectedVariant(null)
    else setSelectedVariant(data.shopify.variants.find(variant => variant.id === customSelection.variantId))
  }, [customSelection, isHead, selectedHead, data])
  const product = isHead ? selectedHead : (data || {}).shopify
  useEffect(() => {
    if (!onSelectImage) return
    onSelectImage(!selectedVariant ? null : (selectedVariant.image || product.images[0] || {}).url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant, product])
  if (error) console.log(`Failed to fetch product: ${error}`)
  if (!isHead && !selectedHead && !selectedVariant) return null
  return <div className={classList(['DesignSection', className])}>
    <Text
      className="DesignSection__heading"
      styleVariant="heading2">
      {module.title}
    </Text>
    {loading ? <LoadingIndicator /> : (!selectedVariant ? <>
      <Text styleVariant="body4">{module.subtitle}</Text>
      {!isHead ? null : <TabGroup
        className="DesignSection__component"
        styleVariant="pill">
        {filters.map(filter => <Tab
          key={filter.id}
          onClick={() => onSelectFilter(filter.tag)}
          selected={filter.tag === selectedFilter}>
          {filter.title}
        </Tab>)}
      </TabGroup>}
      {!isHead && !selectedHead ? null : <DesignModule
        className="DesignSection__component"
        module={module}
        selectedHead={selectedHead}
        selectedFilter={selectedFilter} />}
    </> : <SelectedProduct
      className="DesignSection__selectedProduct"
      handle={product.handle}
      image={(selectedVariant.image || product.images[0] || {}).url}
      title={product.title}
      vendor={product.vendor}
      tags={product.tags}
      price={selectedVariant.price}
      comparePrice={selectedVariant.compareAtPrice}
      variantId={selectedVariant.id}
      config={(customSelection || {}).config}
      onConfigChange={(config) => setCustomOrder({
        ...customOrder,
        [customKey]: {
          ...customSelection,
          config: config,
        }
      })}
      onRemove={() => setCustomOrder({
        ...customOrder,
        [customKey]: null,
      })} />)}
  </div>
}
