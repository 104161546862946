import React, {useEffect, useRef, useState} from 'react'
import {useContentful} from 'react-contentful'
import {Link} from 'react-router-dom'
import AppIcon from '../AppIcon'
import AppImage from '../AppImage'
import ProfileCard from '../ProfileCard'
import Text from '../Text'
import TextArea from '../TextArea'
import {ContentModel} from '../../services/contentful'
import {classList} from '../../services/util'
import './styles.scss'

export default function ReviewEditor({
  className, image, product, productLink, variantTitle, customAttributes, vendor, theoristName, onUpdate, disabled,
}) {
  const ratingTimeout = useRef(null)
  const [ratingPreview, setRatingPreview] = useState(0)
  const [rating, setRating] = useState(0)
  const [body, setBody] = useState('')
  const [theorist, setTheorist] = useState(null)
  const {data} = useContentful({
    contentType: 'theorist',
    query: {'fields.name[match]': theoristName, limit: 1},
    skip: !theoristName
  })
  useEffect(() => {
    if (!data || !data.items || data.items.length === 0) return
    setTheorist(ContentModel(data.items[0]))
  }, [data])
  useEffect(() => {
    if (!onUpdate) return
    onUpdate({
      rating,
      body,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating, body])
  return <div className={classList(['ReviewEditor', className])}>
    <div className="ReviewEditor__info">
      {image && <AppImage
        className="ReviewEditor__image"
        src={image}
        size={128}
        radius={12}
        bordered={true}
        imageFit={'cover'}/>}
      <div className="ReviewEditor__text">
        {vendor && <Text styleVariant="label7">{vendor}</Text>}
        {product && <Text className="ReviewEditor__title" styleVariant="heading3">
          {!productLink ? product : <Link
            className="ReviewEditor__link"
            to={productLink}>
            {product}
          </Link>}
        </Text>}
        {variantTitle && <Text
          className="ReviewEditor__attr"
          styleVariant="body3">
          {variantTitle}
        </Text>}
        {(customAttributes || []).map(attr => <Text
          key={attr.key}
          className="ReviewEditor__attr"
          styleVariant="body2">
          {attr.key}: {attr.value}
        </Text>)}
      </div>
    </div>
    <div className="ReviewEditor__review">
      {theorist && <ProfileCard
        className="ReviewEditor__theorist"
        image={theorist.profilePicture.file.url}
        imageSize={48}
        title={theorist.name}
        subtitle={theorist.locationText}
        link={`/${theorist.theoristHandle}`}
        elite={theorist?.metrics?.overall?.elite}
        orderCount={theorist?.metrics?.overall?.orderCount}
        reviewCount={theorist?.metrics?.overall?.reviewCount}
        reviewTotal={theorist?.metrics?.overall?.reviewTotal}
        stringingSince={theorist.stringingSince} />}
      <div className="ReviewEditor__ratings">
        <div className="ReviewEditor__ratingStars">
          {[1, 2, 3, 4, 5].map(ratingValue => <AppIcon
            key={ratingValue}
            className={classList([
              'ReviewEditor__star',
              ratingValue <= ratingPreview && 'ReviewEditor__star--active',
            ])}
            onClick={() => {
              if (!disabled) setRating(ratingValue)
            }}
            onMouseEnter={() => {
              if (disabled) return
              if (ratingTimeout.current) clearTimeout(ratingTimeout.current)
              ratingTimeout.current = setTimeout(() => setRatingPreview(ratingValue), 50)
            }}
            onMouseLeave={() => {
              if (disabled) return
              if (ratingTimeout.current) clearTimeout(ratingTimeout.current)
              ratingTimeout.current = setTimeout(() => setRatingPreview(rating), 50)
            }}
            icon="star" />)}
        </div>
        <Text className="ReviewEditor__ratingText">{mapRatingText(ratingPreview)}</Text>
      </div>
      <TextArea
        className="ReviewEditor__body"
        value={body}
        onChange={(value) => {
          if (!disabled) setBody(value)
        }}
        placeholder={computePlaceholder(product, theorist, vendor)} />
    </div>
  </div>
}

const computePlaceholder = (product, theorist, vendor) => {
  const preamble = 'What did you think of'
  if (product && (theorist || vendor))
    return `${preamble} ${product} designed by ${!theorist ? vendor : theorist.name}?`
  if (product || theorist || vendor)
    return `${preamble} ${product || (!theorist ? vendor : theorist.name)}?`
  return `${preamble} this?`
}

const mapRatingText = (ratingValue) => {
  switch (ratingValue) {
    case 5: return 'Great'
    case 4: return 'Good'
    case 3: return 'Okay'
    case 2: return 'Bad'
    case 1: return 'Terrible'
    default: return ''
  }
}
