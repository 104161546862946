import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {classList} from '../../services/util'
import AppIcon from '../AppIcon'
import IconButton from '../IconButton'
import Logo from '../Logo'
import Text from '../Text'
import './styles.scss'

export default function AppBar({
  className, children, sticky, fullWidth, borderBottom, menuPosition, cartSize,
  onMenuClick, onLogoClick, onCartClick
}) {
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    const onScroll = e => setScrollTop(e.target.documentElement.scrollTop)
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  })
  return <header className={classList([
    'AppBar',
    !sticky ? null : 'AppBar--sticky',
    !fullWidth ? 'AppBar--padded' : null,
    borderBottom || scrollTop > 0 ? 'AppBar--scrolling' : null,
    className,
  ])}>
    <div className={classList([
      'AppBar__content',
      !fullWidth ? 'AppBar__content--maxWidth' : null,
    ])}>
      <div className="AppBar__contentLeft">
        {!onMenuClick || menuPosition === 'right' ? null : <IconButton
          className="AppBar__menuButton--left"
          icon="menu"
          onClick={() => onMenuClick()} />}
        <Link to="/" onClick={() => {if (onLogoClick) onLogoClick(scrollTop)}}>
          <Logo className="AppBar__logo" />
        </Link>
      </div>
      <div className="AppBar__contentMiddle">
        {children}
      </div>
      <div className="AppBar__contentRight">
        <button
          className={classList([
            'AppBar__cartLink',
            cartSize > 0 ? 'AppBar__cartLink--filled' : null,
          ])}
          onClick={() => {if (onCartClick) onCartClick()}}>
          <AppIcon className="AppBar__cartIcon" icon="cart" />
          <Text styleVariant="heading3">{cartSize}</Text>
        </button>
        {!onMenuClick || menuPosition !== 'right' ? null : <IconButton
          className="AppBar__menuButton--right"
          icon="menu"
          onClick={() => onMenuClick()} />}
      </div>
    </div>
  </header>
}
