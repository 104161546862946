import React from 'react'
import {classList} from '../../services/util'
import './styles.scss'

export default function Pill({className, children, active, ...props}) {
  return <button
    className={classList([
      'Pill',
      !active ? null : 'Pill--active',
      className,
    ])}
    {...props}>
    {children}
  </button>
}
