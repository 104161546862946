import React from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import DashProductEdit from './scenes/DashProductEdit'
import DashProductList from './scenes/DashProductList'
import './styles.scss'

export default function DashProducts({theorist}) {
  const {path, url} = useRouteMatch()
  return <Switch>
    <Route exact path={path}>
      <DashProductList theorist={theorist} />
    </Route>
    <Route path={`${path}/:id`}>
      <DashProductEdit theorist={theorist} />
    </Route>
    <Route path="*">
      <Redirect to={url} />
    </Route>
  </Switch>
}
