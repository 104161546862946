import React from 'react'
import * as Scroll from 'react-scroll'
import AppIcon from '../../../../components/AppIcon'
import FormattedPrice from '../../../../components/FormattedPrice'
import ProductHighlight from '../../../../components/ProductHighlight'
import ProductVendor from '../../../../components/ProductVendor'
import Text from '../../../../components/Text'
import {computeSubtitle} from '../../../../services/product'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function ProductInfo({
  className, title, tags, vendor, theorist, price, comparePrice, orderCount, reviewCount, reviewTotal,
}) {
  const subtitle = computeSubtitle(tags)
  const overallRating = ((reviewTotal ?? 0) / (reviewCount || 1)).toFixed(1)
  const boughtCount = formatOrderCount(orderCount ?? 0)
  return <div className={classList(['ProductInfo', className])}>
    <ProductVendor theorist={theorist} vendor={vendor} />
    <Text
      className="ProductInfo__title"
      styleVariant="heading1">
      {title}
    </Text>
    <div className="ProductInfo__subtitle">
      <Text styleVariant="body3">{subtitle}</Text>
      {overallRating > 0 && <>
        {subtitle && <Text className="ProductInfo__divider" styleVariant="heading3">•</Text>}
        <Text styleVariant="heading4">{overallRating}</Text>
        <AppIcon className="ProductInfo__star" icon="star" />
        <Scroll.Link to="reviews" duration={300} smooth>
          <Text className="ProductInfo__link" styleVariant="heading7">
            {`${reviewCount} review${reviewCount === 1 ? '' : 's'}`}
          </Text>
        </Scroll.Link>
      </>}
      {boughtCount && <>
        {(subtitle || overallRating > 0) && <Text
          className="ProductInfo__divider" styleVariant="heading3">•</Text>}
        <Text styleVariant="body2">Bought {boughtCount} times</Text>
      </>}
    </div>
    <div className="ProductInfo__extras">
      <ProductHighlight
        className="ProductInfo__highlight"
        price={price}
        comparePrice={comparePrice}
        tags={tags} />
      <Text styleVariant="heading2">
        <FormattedPrice compareAt={comparePrice}>{price}</FormattedPrice>
      </Text>
    </div>
  </div>
}

const formatOrderCount = (count) => {
  const mostSignificant = `${count}`[0];
  const precisionTokens = count.toPrecision(1).split('+');
  if (precisionTokens.length < 2) return null;
  const numberZeros = parseInt(precisionTokens[1]);
  const generatedZeros = '0'.repeat(numberZeros > 2 ? numberZeros - 3 : numberZeros);
  return `${mostSignificant}${generatedZeros}${numberZeros > 2 ? 'K' : ''}+`;
}
