import React, {useEffect, useState} from 'react'
import {useContentful} from 'react-contentful'
import {ContentModel} from '../../services/contentful'
import {useDoc} from '../../services/firebase'
import {useProductOptions} from '../../services/product'
import {flattenData} from '../../services/shopify'
import {classList} from '../../services/util'
import ColorOption, {ColorGroup} from '../ColorOption'
import ProductOptions from '../ProductOptions'
import Text from '../Text'
import './styles.scss'

export default function ProductConfigurator({className, tag, config, onChange}) {
  const [configurator, setConfigurator] = useState(null)
  const {error, data} = useContentful({
    contentType: 'configurator',
    query: {'fields.tag': tag},
  })
  useEffect(() => {
    if (!data || data.items.length === 0) return
    setConfigurator(ContentModel(data.items[0]))
  }, [data])
  if (error) console.log(error)
  if (!configurator || !configurator.type) return null
  switch (configurator.type) {
    case 'parent':
      return <>
        {(configurator.values || []).map(value => <ProductConfigurator
          className={className}
          key={value}
          tag={value}
          config={config}
          onChange={(childConfig) => onChange({
            tag: tag,
            options: {
              ...(config || {}).options,
              ...childConfig.options,
            },
            images: {
              ...(config || {}).images,
              ...childConfig.images,
            },
            imageOrder: configurator.values,
            lastClicked: childConfig.lastClicked,
          })} />)}
      </>
    case 'product':
      return <LinkedProduct
        label={configurator.label}
        productId={(configurator.values || [])[0]}
        options={(config || {}).options || {}}
        onSelect={(variant, clicked) => {
          if (onChange) {
            onChange({
              tag: tag,
              options: {[configurator.label]: variant.title},
              images: {[tag]: variant.image},
              imageOrder: [tag],
              lastClicked: clicked ? tag : null,
            })
          }
        }} />
    default:
      return <div className={classList(['ProductConfigurator', className])}>
        <Text
          className="ProductConfigurator__label"
          styleVariant="body2">
          {configurator.label}
        </Text>
        <ConfiguratorOptions
          {...configurator}
          options={(config || {}).options || {}}
          onSelect={(selectedOptions) => {
            if (onChange) {
              onChange({
                tag: tag,
                options: selectedOptions,
              })
            }
          }} />
      </div>
  }
}

const ConfiguratorOptions = ({type, label, values, options, onSelect}) => {
  useEffect(() => {
    if (!options[label] && (values || []).length > 0) onSelect({[label]: values[0]})
  }, [type, label, values, options, onSelect])
  switch (type) {
    case 'color':
      return <ColorGroup label={options[label]}>
        {values.map(value => <ColorOption
          key={`${label}: ${value}`}
          color={value}
          selected={options[label] === value}
          onClick={() => onSelect({[label]: value})} />)}
      </ColorGroup>
    default:
      console.log(`Unknown configurator type: ${type}`)
      return null
  }
}

const LinkedProduct = ({label, productId, options, onSelect}) => {
  const [product, setProduct] = useState(null)
  const [selectedVariant, setSelectedVariant] = useState(null)
  const {data} = useDoc('products', productId, !productId)
  useEffect(() => {
    if (!data) return
    const product = flattenData(data.shopify)
    setProduct(product)
  }, [data])
  const productOptions = useProductOptions(product)
  useEffect(() => {
    if (!product) return
    let searchVariant = product.variants.find(variant => variant.title === options[product.title])
    if (!searchVariant) {
      const availableVariant = product.variants.find(variant => variant.availableForSale === true)
      onSelect(availableVariant || product.variants[0], false)
    } else {
      setSelectedVariant(searchVariant)
    }
  }, [options, onSelect, product])
  if (!product || !selectedVariant) return null
  return <ProductOptions
    label={label}
    options={productOptions}
    variants={product.variants}
    selected={selectedVariant}
    onSelect={(variant) => onSelect(variant, true)} />
}
