import React from 'react'
import {useMediaQuery} from 'react-responsive/src'
import {classList} from '../../services/util'
import PaginationButton from '../PaginationButton'
import Text from '../Text'
import './styles.scss'

export default function Grid({
  className, children, title, subtitle, columns, spacing,
  hasPagination, onPagination,
}) {
  const sm = useMediaQuery({maxWidth: 720})
  const md = useMediaQuery({maxWidth: 1080})
  const size = sm ? 'sm' : (md ? 'md' : 'lg')
  const defaultColumns = sm ? 1 : (md ? 2 : 3)
  const defaultSpacing = sm ? 16 : 24
  return <section className={classList(['Grid', className])}>
    {!title ? null : <Text
      className={classList([
        'Grid__title',
        !subtitle ? null : 'Grid__title--withSubtitle'
      ])}
      styleVariant="heading2">
      {title}
    </Text>}
    {!subtitle ? null : <Text className="Grid__title">{subtitle}</Text>}
    <div className="Grid__items">
      {(children || []).map((child, index) => <GridItem
        {...child.props}
        key={child.key}
        index={index}
        total={children.length}
        columns={(columns || {})[size] || defaultColumns}
        spacing={(spacing || {})[size] || defaultSpacing}>
        {child}
      </GridItem>)}
    </div>
    <PaginationButton
      className="Grid__pagination"
      hasPagination={hasPagination}
      onPagination={onPagination} />
  </section>
}

const GridItem = ({children, index, total, columns, spacing, ...props}) => <div
  className="Grid__item"
  style={{
    width: `calc(${100 / columns}% - ${spacing * (columns - 1) / columns}px)`,
    marginTop: `${index < columns ? 0 : spacing / 2}px`,
    marginBottom: `${index >= total - columns ? 0 : spacing / 2}px`,
    marginLeft: `${index % columns === 0 ? 0 : spacing / 2 }px`,
    marginRight: `${index % columns === columns - 1 ? 0 : spacing / 2 }px`,
  }}
  {...props}>
  {children}
</div>
