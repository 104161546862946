import React from 'react'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import './styles.scss'

export default function ProductStatus({className, status, pending, fulfilled}) {
  if (!!fulfilled) return <Text
    className={className}
    styleVariant="body2">
    Fulfilled by String Theory.
  </Text>
  const _status = !pending && !!status ? status.toLowerCase() : 'pending'
  return <Text
    className={classList([
      'ProductStatus',
      `ProductStatus--${_status}`,
      className,
    ])}
    styleVariant="body3">
    {_status}
  </Text>
}
