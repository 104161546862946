import React from 'react'
import {Link} from 'react-router-dom'
import AppImage from '../../../../components/AppImage'
import FormattedPrice from '../../../../components/FormattedPrice'
import LinkButton from '../../../../components/LinkButton'
import ProductConfigurator from '../../../../components/ProductConfigurator'
import Text from '../../../../components/Text'
import TheoristSelector from '../../../../components/TheoristSelector'
import {findTag, isDesignOnly, mapTag} from '../../../../services/product'
import {resizeImage} from '../../../../services/shopify'
import {classList, joinWords} from '../../../../services/util'
import './styles.scss'

export default function SelectedProduct({
  className, handle, image, title, vendor, tags, variantId,
  price, comparePrice, config, onConfigChange, onRemove,
}) {
  const subtitle = computeSubtitle(vendor, tags)
  return <div className={classList(['SelectedProduct', className])}>
    <div  className="SelectedProduct__detail">
      <AppImage
        className="SelectedProduct__image"
        src={resizeImage(image, 1080)}
        radius={12}
        bordered={true}
        imageFit={'cover'} />
      <div className="SelectedProduct__text">
        <div className="SelectedProduct__header">
          <Text styleVariant="heading3">
            <Link
              className="SelectedProduct__link"
              to={`/products/${handle}?d=1&v=${variantId.split('/').pop()}`}>
              {title}
            </Link>
          </Text>
          <LinkButton
            className="SelectedProduct__removeButton"
            onClick={() => {if (onRemove) onRemove()}}>
            Remove
          </LinkButton>
        </div>
        {!subtitle ? null : <Text
          className="SelectedProduct__subtitle"
          styleVariant="body2">
          {subtitle}
        </Text>}
        <Text
          className="SelectedProduct__subtitle"
          styleVariant="body3">
          <FormattedPrice compareAt={comparePrice}>{price}</FormattedPrice>
        </Text>
      </div>
    </div>
    {!config || !config.tag ? null : <div className="SelectedProduct__configurator">
      <ProductConfigurator
        tag={config.tag}
        config={config}
        onChange={(newConfig) => onConfigChange({
          ...config,
          ...newConfig,
        })} />
    </div>}
    {isDesignOnly(tags) && vendor === 'String Theory' && <div className="SelectedProduct__theorist">
      <Text styleVariant="body2">Theorist</Text>
      <TheoristSelector
        initialTheorist={config?.theorist}
        onSelect={(theoristName) => onConfigChange({
          ...config,
          theorist: theoristName,
        })} />
    </div>}
    {!tags.includes('custom-pocket') ? null : <Text className="SelectedProduct__pocketDisclaimer" styleVariant="body3">
      {'Our standard pocket and shooter placement is a mid pocket with 2 shooters across since it works best ' +
        'for most players. If you’d like something different, please use the notes below to specify.'}
    </Text>}
  </div>
}

const computeSubtitle = (vendor, tags) => {
  const filterTags = tags.filter(tag => ['goalie', 'men', 'women'].includes(tag))
  const customTag = findTag(tags, 'custom')
  return joinWords([
    vendor,
    filterTags.length === 1 ? mapTag(filterTags[0], {
      'goalie': 'Goalie',
      'men': "Men's",
      'women': "Women's",
    }) : null,
    mapTag(customTag, {
      'dye': 'Dye',
      'head': 'Head',
      'pocket': 'Pocket',
    }),
  ])
}
