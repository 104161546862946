import React, {createContext, createRef, useContext, useEffect, useState} from 'react'
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import LoadingIndicator from '../LoadingIndicator'
import './styles.scss'

const LoadingContext = createContext({
  addLoad: () => {},
  removeLoad: () => {},
})

export const useLoading = () => useContext(LoadingContext)

export const LoadingConsumer = LoadingContext.Consumer

export default function LoadingWrapper({children}) {
  let pending = {}
  const [loading, setLoading] = useState(false)
  const loadingRef = createRef()
  const refresh = () => setLoading(Object.values(pending).length > 0)
  useEffect(() => {
    if (loading) disableBodyScroll(loadingRef.current)
    else enableBodyScroll(loadingRef.current)
    return () => clearAllBodyScrollLocks()
  }, [loading, loadingRef])
  return <LoadingContext.Provider value={{
    addLoad: (id) => {
      pending = Object.assign(pending, {[id]: true})
      refresh()
    },
    removeLoad: (id) => {
      if (pending[id]) delete pending[id]
      refresh()
    },
  }}>
    {children}
    <div ref={loadingRef}
      className={[
        'LoadingWrapper',
        !loading ? null : 'LoadingWrapper--loading',
      ].filter(it => !!it).join(' ')}>
      <LoadingIndicator />
    </div>
  </LoadingContext.Provider>
}
