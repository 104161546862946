/* eslint-disable react-hooks/exhaustive-deps */
// noinspection ES6CheckImport

import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {initializeApp} from 'firebase/app'
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import {getAnalytics} from 'firebase/analytics'
import {Timestamp} from 'firebase/firestore'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy as _orderBy,
  query,
  startAfter,
  where as _where
} from 'firebase/firestore'
export const orderBy = _orderBy
export const where = _where

const FirebaseContext = createContext({
  loading: true,
  sendPasswordResetEmail: () => {},
  signIn: () => {},
  signOut: () => {},
  syncTheorist: () => {},
  theorist: null,
  user: null,
})

export const useFirebase = () => useContext(FirebaseContext)

export const FirebaseConsumer = FirebaseContext.Consumer

export default function FirebaseProvider({children}) {
  const [user, setUser] = useState(undefined)
  const [theorist, setTheorist] = useState(undefined)
  // TODO: Set up staging environment
  // const config = window && window.location && window.location.hostname === 'stringtheory.us' ? {
  const config = {
    apiKey: "AIzaSyAE_Lhs6zeOKGiV7mUH9rhR4zFJouPvnf8",
    authDomain: "stringtheory-us.firebaseapp.com",
    databaseURL: "https://stringtheory-us.firebaseio.com",
    projectId: "stringtheory-us",
    storageBucket: "stringtheory-us.appspot.com",
    messagingSenderId: "462351149872",
    appId: "1:462351149872:web:f427946a1c94ce9e03b6f5",
    measurementId: "G-LK71HL36Y7"
  }
  // } : {
  //   apiKey: "AIzaSyBlIdZfszJBtKnXj8fmJm4anQB_QlsuJtU",
  //   authDomain: "stringtheory-staging.firebaseapp.com",
  //   databaseURL: "https://stringtheory-staging.firebaseio.com",
  //   projectId: "stringtheory-staging",
  //   storageBucket: "stringtheory-staging.appspot.com",
  //   messagingSenderId: "3089715866",
  //   appId: "1:3089715866:web:9b90fbc3c5e6de32398523",
  //   measurementId: "G-P0QDW0KGKS"
  // }
  const app = initializeApp(config)
  getAnalytics()
  getFunctions(app)
  const auth = getAuth()
  useEffect(() => {
    return onAuthStateChanged(auth, user => {
      setUser(user)
      if (user) fetchDoc('theorists', user.uid)
        .then(snap => setTheorist(!snap.exists() ? null : {...snap.data()}))
      else setTheorist(null)
    })
  }, [])
  // noinspection JSValidateTypes
  return <FirebaseContext.Provider value={{
    loading: user === undefined || theorist === undefined,
    sendPasswordResetEmail: (email) => sendPasswordResetEmail(auth, email),
    signIn: (email, password) => {
      setTheorist(undefined)
      return signInWithEmailAndPassword(auth, email, password)
    },
    signOut: () => {
      setTheorist(undefined)
      return signOut(auth)
    },
    syncTheorist: (data) => setTheorist(data),
    theorist: theorist,
    user: user,
  }}>{children}</FirebaseContext.Provider>
}

function usePromise(promise, deps = []) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    let cancelled = false
    setLoading(true)
    promise().then(dat => {
      if (cancelled) return
      setData(dat)
      setLoading(false)
    }).catch(err => {
      if (cancelled) return
      setError(err)
      setLoading(false)
    })
    return () => {cancelled = true}
  }, deps)

  return {loading, error, data}
}

export const createUser = async (email, password) => {
  const result = {
    data: null,
    error: null,
  }
  try {
    result.data = await createUserWithEmailAndPassword(getAuth(), email, password)
    return result
  } catch (e) {
    result.error = {
      email: null,
      password: null,
    }
    switch (e.code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        result.error.email = 'An account already exists for this email address.'
        break
      case AuthErrorCodes.INVALID_EMAIL:
        result.error.email = 'Please enter a valid email address.'
        break
      case AuthErrorCodes.WEAK_PASSWORD:
        result.error.password = 'Please choose a stronger password.'
        break
      default:
        console.log(e.code)
        result.error = 'Something went wrong.'
    }
    return result
  }
}

export const callable = (name) => httpsCallable(getFunctions(), name)

export const useCallable = (name, params, skip) => usePromise(!skip
  ? () => callable(name)(params).then(res => res.data)
  : () => Promise.resolve(null), [params, skip])

export const fetchLocation = async (lat, lon) => {
  const key = 'AIzaSyAE_Lhs6zeOKGiV7mUH9rhR4zFJouPvnf8'
  const req = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${key}`)
  const res = await req.json()
  const place = res.results[0]
  const findComponent = type => place.address_components.find(it => it.types.includes(type))
    || {long_name: '', short_name: ''}
  return {
    city: findComponent('locality').long_name,
    region: findComponent('administrative_area_level_1').short_name,
    postalCode: findComponent('postal_code').long_name,
    country: findComponent('country').short_name,
  }
}

export const fetchDoc = (collectionPath, docPath) => getDoc(doc(getFirestore(), collectionPath, docPath))

export const executeQuery = (collectionPath, queryConstraints) =>
  getDocs(query(collection(getFirestore(), collectionPath), ...queryConstraints))

export const timestamp = (seconds, nanoseconds) => new Timestamp(seconds, nanoseconds)

export function useDoc(collectionPath, docPath, skip) {
  return usePromise(!skip ? () => fetchDoc(collectionPath, docPath).then(snap => {
    if (!snap.exists()) return null
    return snap.data()
  }) : () => Promise.resolve(null), [docPath, skip])
}

export function useRealtimeDoc(collectionPath, docPath, skip) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    setLoading(true)
    if (skip) {
      setLoading(false)
      return
    }
    return onSnapshot(doc(getFirestore(), collectionPath, docPath), snap => {
      if (!snap.exists()) setData(null)
      else setData(snap.data())
      setLoading(false)
    }, err => {
      setError(err)
      setLoading(false)
    })
  }, [docPath, skip])

  return {loading, error, data}
}

export function useQuery(collectionPath, queryConstraints, deps = [], skip) {
  const state = usePromise(!skip ? () => executeQuery(collectionPath, queryConstraints).then(snap => {
    const docs = []
    snap.forEach(doc => {
      docs.push({id: doc.id, data: doc.data(), doc})
    })
    return docs
  }) : () => Promise.resolve(null), deps)
  return {docs: state.data, ...state}
}

export function useQueryWithPagination(
  collectionPath, queryConstraints, deps = [], pageSize = 50,
) {
  const [lastDoc, setLastDoc] = useState(null)
  const [docs, setDocs] = useState(null)
  const [hasMore, setHasMore] = useState(false)
  useEffect(() => {
    setDocs(null)
    setHasMore(false)
    setLastDoc(null)
  }, deps)
  let {loading, error, data} = useQuery(
    collectionPath,
    [
      ...(queryConstraints || []),
      limit(pageSize),
      !lastDoc ? null : startAfter(lastDoc.doc)
    ].filter(it => !!it),
    [lastDoc, ...deps],
  )
  useEffect(() => {
    if (!data) return
    setDocs(prev => !prev ? data : [...prev, ...data])
    setHasMore(data.length >= pageSize)
  }, [data])
  const fetchMore = useCallback(() => setLastDoc(docs[docs.length - 1]), [docs])
  return {
    loading,
    error,
    docs,
    hasMore,
    fetchMore,
  }
}
