import React from 'react'
import AppIcon from '../../../../components/AppIcon'
import Pill from '../../../../components/Pill'
import Text from '../../../../components/Text'
import {classList} from '../../../../services/util'
import './styles.scss'

export default function ShopFilterToggle({className, count, onClick}) {
  return <Pill
    className={classList(['ShopFilterToggle', className])}
    onClick={() => {if (onClick) onClick()}}>
    <AppIcon
      className="ShopFilterToggle__icon"
      icon="sliders" />
    Filters
    {count > 0 ? <Text
      className="ShopFilterToggle__count"
      styleVariant="label3">
      {count}
    </Text> : null}
  </Pill>
}
