import React from 'react'
import Text from '../../../../../../components/Text'
import {classList} from '../../../../../../services/util'
import LineItemGroup from '../LineItemGroup'
import './styles.scss'

export default function LineItemSection({className, title, lineItemGroups, mapQuantity, wrapItem}) {
  if (!lineItemGroups || lineItemGroups.length === 0) return null
  const items = lineItemGroups.map(group => <LineItemGroup
    className="LineItemSection__group"
    key={group.map(item => (item.variant || {}).id || item.title).reduce((acc, curr) => acc + curr, '')}
    lineItems={group}
    quantity={!mapQuantity ? group[0].currentQuantity : mapQuantity(group)} />)
  return <div className={classList(['LineItemSection', className])}>
    <Text
      className="LineItemGroup__title"
      styleVariant="heading4">
      {title} ({lineItemGroups
        .map(group => !mapQuantity ? group[0].currentQuantity : mapQuantity(group))
        .reduce((acc, curr) => acc + curr, 0)})
    </Text>
    {!wrapItem ? items : items.map(item => wrapItem(item))}
  </div>
}
