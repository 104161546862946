import React from 'react'
import {useMutation} from '@apollo/client'
import {CHECKOUT_LINE_ITEMS_ADD, CheckoutConsumer, CREATE_CHECKOUT} from '../../services/shopify'
import {classList} from '../../services/util'
import Button from '../Button'
import {LoadingConsumer} from '../LoadingWrapper'
import './styles.scss'

export default function CheckoutButton({className, children, lineItems, onSuccess}) {
  const [createCheckout] = useMutation(CREATE_CHECKOUT)
  const [checkoutLineItemsAdd] = useMutation(CHECKOUT_LINE_ITEMS_ADD)
  return <LoadingConsumer>
    {({addLoad, removeLoad}) => <CheckoutConsumer>
      {({checkout, toggleCheckout, updateCheckout}) => <Button
        className={classList(['CheckoutButton', className])}
        disabled={!lineItems || lineItems.length < 1}
        onClick={() => {
          addLoad('checkoutButton')
          const promise = !checkout ? createCheckout({
            variables: {
              input: {
                lineItems: lineItems,
              },
            }
          }).then(res => res.data.checkoutCreate) : checkoutLineItemsAdd({
            variables: {
              checkoutId: checkout.id,
              lineItems: lineItems,
            }
          }).then(res => res.data.checkoutLineItemsAdd)
          promise.then(res => {
            updateCheckout(res.checkout)
            removeLoad('checkoutButton')
            if (onSuccess) onSuccess()
            toggleCheckout(true)
          })
        }}>{children}</Button>}
    </CheckoutConsumer>}
  </LoadingConsumer>
}
