import React from 'react'
import {useMediaQuery} from 'react-responsive/src'
import {classList} from '../../services/util'
import './styles.scss'

export default function Gallery({className, children = []}) {
  const sm = useMediaQuery({maxWidth: 720})
  const md = useMediaQuery({maxWidth: 1080})
  const size = sm ? 4 : 5
  const items = children.slice(0, Math.min(children.length, size))
  let configuration;
  switch (items.length) {
    case 5:
      configuration = md ? <>
        <Double>{items[0]}</Double>
        <Single>
          <Single>{items[1]}</Single>
          <Single>{items[2]}</Single>
        </Single>
        <Single>
          <Single>{items[3]}</Single>
          <Single>{items[4]}</Single>
        </Single>
      </> : <>
        <Double>{items[0]}</Double>
        <Double>{items[1]}</Double>
        <Single>
          <Single>{items[2]}</Single>
          <Single>{items[3]}</Single>
        </Single>
        <Double>{items[4]}</Double>
      </>
      break
    case 4:
      configuration = sm ? <>
        <Single>
          <Single>{items[0]}</Single>
          <Single>{items[1]}</Single>
        </Single>
        <Single>
          <Single>{items[2]}</Single>
          <Single>{items[3]}</Single>
        </Single>
      </> : <>
        <Double>{items[0]}</Double>
        <Single>
          <Single>{items[1]}</Single>
          <Single>{items[2]}</Single>
        </Single>
        <Double>{items[3]}</Double>
      </>
      break
    case 3:
      configuration = sm ? <>
        <Single>
          <Single>{items[0]}</Single>
        </Single>
        <Single>
          <Single>{items[1]}</Single>
          <Single>{items[2]}</Single>
        </Single>
      </> : <>
        <Double>{items[0]}</Double>
        <Single>{items[1]}</Single>
        <Double>{items[2]}</Double>
      </>
      break
    case 2:
      configuration = <>
        <Single>{items[0]}</Single>
        <Single>{items[1]}</Single>
      </>
      break
    case 1:
      configuration = <Single>{items[0]}</Single>
      break
    default: configuration = null
  }
  return <div className={classList([
    'Gallery',
    !configuration ? 'Gallery--empty' : null,
    className,
  ])}>{configuration}</div>
}

const Double = ({children}) => <div className="Gallery__box Gallery__box--double">{children}</div>

const Single = ({children}) => <div className="Gallery__box Gallery__box--single">{children}</div>
