import React, {useEffect, useState} from 'react'
import {classList} from '../../services/util'
import './styles.scss'

export default function Accessibility({className, children}) {
  const [usingKeyboard, setUsingKeyboard] = useState(false);

  const handleTab = (e) => {
    if (e.keyCode === 9) {
      setUsingKeyboard(true);
      window.removeEventListener('keydown', handleTab);
      window.addEventListener('mousedown', handleMouse);
    }
  };

  const handleMouse = () => {
    setUsingKeyboard(false);
    window.removeEventListener('mousedown', handleMouse);
    window.addEventListener('keydown', handleTab);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleTab);
    return () => {
      window.removeEventListener('keydown', handleTab);
      window.removeEventListener('mousedown', handleMouse);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={classList([
    'Accessibility',
    usingKeyboard && 'Accessibility--keyboard',
    className,
  ])}>{children}</div>
}
